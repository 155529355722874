<template>
    <div v-if="show"
         id="matterTitles"
         :class="{
             collapse: !isMatterTitlesListVisible,
             'is-shared-link-view': isSharedLinkView,
             'title-organiser': titleOrganiserEnabled,
             'top-nav-visible': isTopNavVisible,
         }"
         @click="onListClick">
        <template v-if="loading || loadingState.loadingCurrentMatter">
            <matter-titles-loading-skeleton />
        </template>
        <div v-else>
            <ow-sidebar-header :title="currentMatter.name"
                               header-data-test="matter-titles-title"
                               button-data-test="btn-hide-titles-list-panel"
                               button-data-track="MAT-TITLE-LIST - Show or hide the panel button"

                               :collapsed="!isMatterTitlesListVisible"
                               :hide-collapse-button="!showToggleTitlesNavButton"
                               @toggle="dismissTitlesNav">
                <matter-share-button v-if="showSharingButton"
                                     :matter="currentMatter"
                                     data-test="matter-titles-share-button"
                                     data-track="MAT-TITLE-LIST - Share matter"
                                     @on-click="openShareDialog" />
                <ow-button v-if="showDismissAddingTitlesButton"
                           id="dismissAddingTitlesButton"
                           data-track="MAT-TITLE-LIST - Clicked the close add titles button"
                           icon
                           small
                           is-primary
                           is-borderless
                           @click="dismissAddingTitles">
                    <v-icon>$close</v-icon>
                </ow-button>
            </ow-sidebar-header>
            <matter-side-panel-titles @clear-titles="clearTitles"
                                      @title-click="titleClick"
                                      @show-bulk-actions="showBulkActions" />
        </div>

        <ow-dialog v-model="isDialogOpened"
                   :reset-form-on-close="true"
                   icon="$matter-shared-nav"
                   :title="$t('matter.share.title')">
            <matter-share :initial-active-tab="initialMatterShareTab"
                          :reset-form-on-modal-close="true"
                          @log-heap-event="logHeapEventForPreview" />
        </ow-dialog>
    </div>
</template>

<script lang="ts">
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwDialog from '@/components/core/ow-dialog.vue'
    import OwSidebarHeader from '@/components/core/ow-sidebar-header.vue'
    import MatterTitlesLoadingSkeleton from '@/components/loading-skeletons/matter-titles-loading-skeleton.vue'
    import MatterShareButton from '@/components/matter/link-share/matter-share-button.vue'
    import MatterShare from '@/components/matter/matter-share.vue'
    import { useMapTopNav } from '@/composables/use-map-top-nav'
    import { inject as titlePanelProvider } from '@/composables/use-title-panel'
    import { Route } from '@/enums/route.enum'
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'
    import {
        MATTER_IS_LINK_SHARE_DIRTY,
        MATTER_MUTATE_RESET_LINK_SHARE,
        MATTER_MUTATE_TITLE_ORGANISER_ENABLED,
    } from '@/store/modules/matter/types'
    import { TitlePanels } from '@/store/modules/title-panels'
    import { TITLE_MUTATE_ACTIVE_TITLE_PANEL } from '@/store/modules/titles/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        USER_MUTATE_SHOW_MAP_TITLES_NAV,
    } from '@/store/mutation-types'

    import MatterTitles from './matter-side-panel-titles.vue'

    export default {
        name: 'MatterTitles',

        components: {
            MatterSidePanelTitles: MatterTitles,
            OwDialog,
            MatterShare,
            MatterShareButton,
            MatterTitlesLoadingSkeleton,
            OwButton,
            OwSidebarHeader,
        },

        mixins: [flagsMixin],

        props: {
            collapsed: {
                required: false,
                type: Boolean,
                default: false,
            },
        },

        emits: [
            'clear-titles',
            'show-bulk-actions',
        ],

        setup() {
            const { isTopNavVisible } = useMapTopNav()
            const { isTitlePanelVisible, isSummaryTitlePanelVisible } = titlePanelProvider()
            return {
                isSummaryTitlePanelVisible,
                isTopNavVisible,
                isTitlePanelVisible,
            }
        },

        data() {
            return {
                isDialogOpened: false,
                initialMatterShareTab: 0,
            }
        },

        computed: {
            ...mapGetters({
                isLinkShareDirty: MATTER_IS_LINK_SHARE_DIRTY,
            }),

            ...mapGetters('linkShareClient', {
                isSharedLinkView: LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW,
            }),

            ...mapState({
                showMapTitlesNav: (state) => state.user.showMapTitlesNav,
                addingTitles: (state) => state.title.activeTitlePanel === TitlePanels.ADD,
                currentMatter: (state) => state.matter.currentMatter,
                loading: (state) => state.matter.loading,
                loadingState: state => state.matter.loadingState,
                activeTitlePanel: (state) => state.title.activeTitlePanel,
                hasUnsavedSharingSettings: state => state.matter.pendingChangesToLinkShareSettings,
                isPreviewingLinkShare: state => Boolean(state.linkShareClient.isSharedLinkView),
                walkthroughInitialised: state => state.walkthrough.hasZoomedToInitialPageExtent,
                titleOrganiserEnabled: state => state.matter.titleOrganiser.enable,
            }),

            isMatterTitlesListVisible: {
                get() {
                    return this.showMapTitlesNav
                },
                set(newVal) {
                    this.setShowMapTitlesNav(newVal)
                    this.setTitleOrganiserEnabled(false)
                },
            },

            showDismissAddingTitlesButton() {
                return (this.addingTitles && this.isMatterTitlesListVisible) && !this.isWalkthroughRoute
            },

            showToggleTitlesNavButton() {
                return (!this.addingTitles || !this.isMatterTitlesListVisible) && !this.isWalkthroughRoute && !this.isSharedLinkView
            },

            showSharingButton() {
                return !this.isWalkthroughRoute &&
                    !this.isSharedLinkView &&
                    this.isTitlesListTab &&
                    !this.titleOrganiserEnabled &&
                    !this.isTopNavVisible
            },

            isWalkthroughRoute() {
                return this.$route.name === Route.MatterMapWalkthrough
            },

            show() {
                const isViewingSharedWalkthrough = this.isSharedLinkView && this.isWalkthroughRoute
                const isInitialisingWalkthrough = this.isWalkthroughRoute && !this.walkthroughInitialised
                return !isViewingSharedWalkthrough && !isInitialisingWalkthrough
            },

            isTitlesListTab() {
                return Boolean(this.activeTitlePanel === 0)
            },
        },

        watch: {
            collapsed(val) {
                this.isMatterTitlesListVisible = !val
            },

            isPreviewingLinkShare(val) {
                if (val === false) {
                    if (this.hasUnsavedSharingSettings === true) {
                        this.openShareDialog()
                    }
                }
            },

            isDialogOpened(val) {
                if (this.isLinkShareDirty && !val) {
                    this.resetLinkShare()
                }
            },
        },

        methods: {
            ...mapMutations({
                setShowMapTitlesNav: USER_MUTATE_SHOW_MAP_TITLES_NAV,
                setActiveTitlePanel: TITLE_MUTATE_ACTIVE_TITLE_PANEL,
                resetLinkShare: MATTER_MUTATE_RESET_LINK_SHARE,
                setTitleOrganiserEnabled: MATTER_MUTATE_TITLE_ORGANISER_ENABLED,
            }),

            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            dismissTitlesNav() {
                this.isMatterTitlesListVisible = !this.isMatterTitlesListVisible
            },

            clearTitles() {
                this.$emit('clear-titles')
            },

            async titleClick(title: string | Record<string, any>) {
                if (this.isTitlePanelVisible || this.isSummaryTitlePanelVisible) {
                    let titleNumber = title
                    if (typeof title === 'object') {
                        titleNumber = title.titleNumber
                    }
                    await this.$router.push({
                        name: Route.MatterMapTitle,
                        params: {
                            titleNumber,
                            matterId: this.currentMatter.id,
                        },
                    })
                }
            },

            dismissAddingTitles() {
                this.setActiveTitlePanel(TitlePanels.LIST)
            },

            openShareDialog() {
                this.isDialogOpened = true
            },

            showBulkActions(value) {
                this.$emit('show-bulk-actions', value)
            },

            logHeapEventForPreview() {
                this.logHeapEvent({
                    type: 'Map: Enter Preview',
                    metadata: {
                        matterId: this.currentMatter.id,
                        isUserMatterOwner: this.currentMatter.createdByMe,
                    },
                })
            },

            onListClick() {
                if (!this.isMatterTitlesListVisible) {
                    this.isMatterTitlesListVisible = !this.isMatterTitlesListVisible
                }
            },
        },
    }
</script>

<style lang="scss">
    @import 'matter-titles';
</style>
