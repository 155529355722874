<template>
    <base-monitoring-preference-card :card-type="title"
                                     :card-type-description="description"
                                     :class="['preference-details-card', { 'preference-details-card__disabled': disabled }]"
                                     :hide-header="hideHeader">
        <ul class="preference-details-card__options">
            <li v-for="pref in computedItems"
                :class="{'is-selected': pref.option}">
                <ow-toggle-card v-model="pref.option"
                                :data-test="`toggle ${pref.title}`"
                                :data-track="`Asset monitoring: Toggle ${pref.title}`"
                                :description="pref.description"
                                :disabled="disabled"
                                :is-loading="isLoading"
                                :title="pref.title"
                                no-border>
                    <slot />
                </ow-toggle-card>
            </li>
        </ul>
    </base-monitoring-preference-card>
</template>

<script lang="ts" setup>
    import {
        computed,
        onMounted,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { ITitleNotificationSettings } from '@/api/notifications.api'
    import { IUserEmailPreferences } from '@/api/user-email-preferences.api'
    import BaseMonitoringPreferenceCard from '@/components/asset-monitoring/monitoring-preferences/base-monitoring-preference-card.vue'
    import { IPreferenceDetailsModel } from '@/components/asset-monitoring/monitoring-preferences/preference-details-model-interface'
    import OwToggleCard from '@/components/core/ow-toggle-card.vue'

    const { t } = useI18n()

    onMounted(() => {
        setComputedItems()
    })

    const emit = defineEmits<{
        (event: 'update', value: ITitleNotificationSettings | IUserEmailPreferences): void
    }>()

    const props = defineProps<{
        settings: ITitleNotificationSettings | IUserEmailPreferences,
        isLoading: boolean,
        preferenceProperties: Array<IPreferenceDetailsModel>,
        title?: string,
        description?: string,
        disabled?: boolean
        hideHeader?: boolean
    }>()

    const computedItems = ref()

    watch(() => props.preferenceProperties, () => {
        setComputedItems()
    })

    const setComputedItems = () => {
        computedItems.value = props.preferenceProperties.map((property) => {
            const key = property?.getTranslationKey ? property.getTranslationKey(property.value) : property.value
            const title = t(`assetMonitoring.preferences.cards.preferenceDetails.${ key }Title`)
            const description = t(`assetMonitoring.preferences.cards.preferenceDetails.${ key }Description`)
            return {
                option: computed<boolean>({
                    get: () => props.settings[property.value],
                    set: (value: boolean) => {
                        const updated = {
                            ...props.settings,
                            [property.value]: value,
                        }
                        emit('update', updated)
                    },
                }),
                title,
                description,
            }
        })
    }

</script>

<style lang="scss" scoped>
@import './preference-details-card';
</style>
