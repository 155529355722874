<template>
    <div class="caption-regular row-item__date d-flex align-center gc-2">
        <i aria-hidden="true"
           class="material-icons">info_outline
        </i>
        <span class="text-no-wrap"
              :class="{
                  'caption-highlight': !item.isRead,
                  'caption-regular': item.isRead
              }">{{ format(item.createdOn) }}</span>

        <ow-tooltip :position="OwTooltipPosition.Top"
                    activator="parent"
                    nudge-right="4px"
                    nudge-top="5px">
            <span>{{ `${$t('assetMonitoring.text.source')} ${$t(`assetMonitoring.dataSource.${AssetMonitoringDataSource[model.dataSource]}`)} ` }}</span>
            <p class="body-italic-x-small text-white">
                {{ getItemMessageText }}
            </p>
        </ow-tooltip>
    </div>
</template>

<script setup lang="ts">

    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotification } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import {
        AssetMonitoringDataSource,
        BaseRowItemModel,
    } from '@/components/asset-monitoring/base-row-item-model'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { format } from '@/utils/date-utils'

    const props = defineProps<{
        model: BaseRowItemModel,
        item: IAssetMonitoringNotification,
    }>()

    const { t } = useI18n()

    const getItemMessageText = computed(() => {
        const { companyNumbers, titleNumber, message } = props.item
        const companyNumberLabel = t('assetMonitoring.label.companyNumber')
        const titleNumberLabel = t('assetMonitoring.label.titleNumber')

        if (isNullOrEmpty(companyNumbers) && titleNumber) {
            return message
        }

        // ToDo: This is a temporary fix to replace the title number label with company number label, until the API is updated.
        if (!isNullOrEmpty(companyNumbers) && !titleNumber) {
            return `${ message.replace(titleNumberLabel, companyNumberLabel) } ${ companyNumbers[0] }`
        }

        return message
    })
</script>