import GeoJSON from 'ol/format/GeoJSON'

import {
    DocumentOrderStatus,
    getDocumentOrderStatusText,
} from '@/consts/document-order-status'
import { checkFlag } from '@/feature-flags'
import { TITLE_INFORMATION_V2_FF } from '@/feature-flags/keys'
import {
    TITLE_MUTATE_ACTIVE_TITLE_PANEL,
    TITLE_MUTATE_ADDRESSES,
    TITLE_MUTATE_CLEAR_TITLES,
    TITLE_MUTATE_COLLAPSE_PANEL,
    TITLE_MUTATE_DAYLIST,
    TITLE_MUTATE_DAYLIST_ERROR,
    TITLE_MUTATE_DAYLIST_LOADING,
    TITLE_MUTATE_END_LOOKUP_TITLE,
    TITLE_MUTATE_ENTRY_TYPES_SELECTION,
    TITLE_MUTATE_ERROR_MESSAGE,
    TITLE_MUTATE_EXPANDED_TITLE_NUMBER,
    TITLE_MUTATE_INFORMATION,
    TITLE_MUTATE_LOADING,
    TITLE_MUTATE_LOADING_OFFICIAL_COPIES_AVAILABILITY,
    TITLE_MUTATE_LOADING_STATE,
    TITLE_MUTATE_LOADING_SUMMARY_TITLE,
    TITLE_MUTATE_LOOKUP_TITLE_ERROR,
    TITLE_MUTATE_MULTI_TITLE_SELECTION_PANEL,
    TITLE_MUTATE_OC2_DATA_FROM_ORDER_MESSAGE,
    TITLE_MUTATE_OC2_DOCUMENT_STATUS,
    TITLE_MUTATE_OFFICIAL_COPY_PROPERTIES,
    TITLE_MUTATE_OWNER_DATA_SOURCE_ON_SUMMARY,
    TITLE_MUTATE_REGISTER_DATA_FROM_ORDER_MESSAGE,
    TITLE_MUTATE_REGISTER_INDICATOR_SELECTION,
    TITLE_MUTATE_REGISTER_INDICATOR_TEXT_SELECTION_SPAN,
    TITLE_MUTATE_REGISTER_ORDER_LOADING,
    TITLE_MUTATE_SELECTED_SUMMARY_TITLE,
    TITLE_MUTATE_SELECTED_TITLE,
    TITLE_MUTATE_SELECTED_TITLE_LOADING,
    TITLE_MUTATE_SELECTED_TITLE_NUMBER,
    TITLE_MUTATE_SELECTED_TITLE_OFFICIAL_COPIES_AVAILABILITY,
    TITLE_MUTATE_SELECTED_TITLE_RECORD,
    TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_BACKDATED_TITLE_PLAN,
    TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_REGISTER,
    TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_TITLE_PLAN,
    TITLE_MUTATE_SET_TITLES,
    TITLE_MUTATE_START_LOOKUP_DAYLIST,
    TITLE_MUTATE_START_LOOKUP_TITLE,
    TITLE_MUTATE_TITLE_PLAN_DATA_FROM_ORDER_MESSAGE,
    TITLE_MUTATE_TITLE_PLAN_ORDER_LOADING,
    TITLE_MUTATE_UPDATED_TITLE_DOCUMENTS,
    TITLE_SHOW_SELECTION_LAYER,
} from '@/store/modules/titles/types'
import { isNullOrEmpty } from '@/utils/array-utils'
import {
    generateOrderKeyValue,
    isDocumentOrderFailure,
} from '@/utils/document-ordering-utils'
import { mapToCommonTitleInformation } from '@/utils/title-information-utils'

import defaultTitleStatus from './default-title-status'

export default {
    [TITLE_MUTATE_LOADING](state, isLoading) {
        state.loading = isLoading
    },

    [TITLE_MUTATE_UPDATED_TITLE_DOCUMENTS](state, updates) {
        updates.forEach(latestVersion => {
            // Update possible entry in selected title
            if (state.selectedTitle?.record?.bgProprietorshipData?.documentDetails != null) {
                const existingDocument = state.selectedTitle.record.bgProprietorshipData.documentDetails
                    .find(x => x.matterDocumentId === latestVersion.matterDocumentId)
                if (existingDocument) {
                    for (const prop in latestVersion) {
                        existingDocument[prop] = latestVersion[prop]
                    }
                }
            }
        })
    },

    [TITLE_MUTATE_START_LOOKUP_TITLE](state) {
        state.selectedTitle.loading = true
        state.selectedTitle.indicatorNames = []
        state.activeTab = 'summary'
        state.errormessage = null
        state.selectedTitle.showLrOwnership = true
    },

    [TITLE_MUTATE_END_LOOKUP_TITLE](state) {
        state.selectedTitle.daylist = {}
        state.selectedTitle.leaseHierarchy = null
        state.selectedTitle.loading = false
    },

    [TITLE_MUTATE_LOOKUP_TITLE_ERROR](state, message) {
        state.errormessage = message
        state.selectedTitle.loading = false
    },

    [TITLE_MUTATE_START_LOOKUP_DAYLIST](state) {
        state.errormessage = null
        state.selectedTitle.daylist = {}
        state.selectedTitle.loadingDaylist = true
        state.selectedTitle.daylistError = null
    },

    [TITLE_MUTATE_DAYLIST_ERROR](state, message) {
        state.selectedTitle.daylistError = message
    },

    [TITLE_MUTATE_DAYLIST](state, daylist) {
        state.selectedTitle.daylist = daylist
    },

    [TITLE_MUTATE_DAYLIST_LOADING](state, isLoading) {
        state.selectedTitle.loadingDaylist = isLoading
    },

    [TITLE_MUTATE_MULTI_TITLE_SELECTION_PANEL](state, isShown) {
        state.showMultiSelectTitlePanel = isShown
    },

    [TITLE_MUTATE_CLEAR_TITLES](state) {
        state.titles = []
        state.setSelectedTitleNumber = null
        state.selectedTitle = defaultTitleStatus
    },

    [TITLE_MUTATE_SELECTED_TITLE](state, payload) {
        // NOTE: Deep copy otherwise state won't update everywhere
        state.selectedTitle = Object.assign({}, payload)
    },

    [TITLE_MUTATE_SET_TITLES](state, val) {
        state.titles = val
        state.loading = false
    },

    [TITLE_MUTATE_INFORMATION](state, val) {
        val.forEach(result => {
            const title = state.titles.find(t => {
                return t.titleNumber === result.titleNumber
            })
            if (title) {
                title.isRegisterPurchased = result.isRegisterPurchased
                title.isRegisterUploaded = result.isRegisterUploaded
                title.responseDate = result.responseDate
            }
        })
    },

    [TITLE_MUTATE_ADDRESSES](state, addresses) {
        addresses.forEach(result => {
            const title = state.titles.find(t => {
                return t.titleNumber === result.titleNumber
            })
            if (title) {
                title.addresses = result.addresses
            }
        })
        state.loading = false
    },

    [TITLE_MUTATE_SELECTED_TITLE_NUMBER](state, val) {
        state.selectedTitleNumber = val
    },

    [TITLE_MUTATE_EXPANDED_TITLE_NUMBER](state, val) {
        state.expandedTitleNumber = val
        if (val === null) {
            state.selectionLayer?.getSource().clear()
            state.selectionLayer?.getSource().changed()
        }
    },

    [TITLE_MUTATE_SELECTED_TITLE_RECORD](state, val) {
        state.selectedTitle.record = mapToCommonTitleInformation(val, checkFlag(TITLE_INFORMATION_V2_FF, false))
        if (val !== null) {
            state.selectedTitle.bboxGeom = val.titleInformation.bbox ? (new GeoJSON()).readFeature(JSON.parse(val.titleInformation.bbox)) : null
            state.titleNumber = state.selectedTitleNumber
            if (!isNullOrEmpty(val.bgProprietorshipData?.linkedIndicators)) {
                state.selectedTitle.indicatorNames = val.titleInformation.bgProprietorshipData.linkedIndicators
                    .map(indicator => indicator.label)
            } else {
                state.selectedTitle.indicatorNames = []
            }
        } else {
            state.selectedTitle.loading = false
            state.selectedTitle.loadingDaylist = false
            state.selectedTitle.loadingLeaseHierarchy = false
            state.selectedTitle.daylist = {}
            state.selectedTitle.leaseHierarchy = null
            state.selectedTitle.bboxGeom = null
            state.selectedTitle.indicatorNames = []
            state.selectedTitle.titleNumber = null
            state.selectedTitle.record = null
        }
    },

    [TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_TITLE_PLAN](state, update) {
        const record = state.selectedTitle.record

        record.messages.push(update.message)
        record.titleMetadata.showTitlePlanButton = false
        record.titleMetadata.showBackDatedRegisterButton = update.showAlternative
        record.titleMetadata.showRegisterButton = !update.showAlternative
        record.titleMetadata.showBackDatedTitlePlanButton = update.showAlternative
    },

    [TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_BACKDATED_TITLE_PLAN](state, update) {
        const titlePlan = state.selectedTitle.officialCopiesAvailability.results.titlePlan
        titlePlan.currentVersionIsBackdated = update
    },

    [TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_REGISTER](state, update) {
        const record = state.selectedTitle.record

        if (update.updateMessage) {
            record.messages.push(update.message)
        }
        record.titleMetadata.showTitlePlanButton = !update.showAlternative
        record.titleMetadata.showBackDatedRegisterButton = update.showAlternative
        record.titleMetadata.showRegisterButton = !update.showAlternative
    },

    [TITLE_MUTATE_SELECTED_TITLE_OFFICIAL_COPIES_AVAILABILITY](state, update) {
        update.referredToDocuments = update.referredToDocuments.map(doc => ({
            ...doc,
        }))
        state.selectedTitle.officialCopiesAvailability.results = update
    },

    [TITLE_MUTATE_SELECTED_TITLE_LOADING](state, isLoading) {
        state.selectedTitle.loading = isLoading
    },

    [TITLE_MUTATE_COLLAPSE_PANEL](state, val) {
        state.collapsePanel = val
    },

    [TITLE_MUTATE_OWNER_DATA_SOURCE_ON_SUMMARY](state, isShown) {
        state.selectedTitle.showLrOwnership = isShown
    },

    [TITLE_MUTATE_ACTIVE_TITLE_PANEL](state, newPanel) {
        state.activeTitlePanel = newPanel
    },

    [TITLE_MUTATE_REGISTER_INDICATOR_SELECTION](state, indicator) {
        state.pendingRegisterIndicatorSelection = indicator
    },

    [TITLE_MUTATE_REGISTER_INDICATOR_TEXT_SELECTION_SPAN](state, position) {
        state.pendingRegisterIndicatorTextSelectionSpan = position
    },

    [TITLE_MUTATE_ENTRY_TYPES_SELECTION](state, selection) {
        state.selectedEntryTypes = selection
    },

    [TITLE_MUTATE_ERROR_MESSAGE](state, message) {
        state.errormessage = message
    },

    [TITLE_MUTATE_LOADING_STATE](state, update) {
        state.loadingState = {
            ...state.loadingState,
            ...update,
        }
    },

    [TITLE_MUTATE_SELECTED_SUMMARY_TITLE](state, title) {
        state.selectedSummaryTitle = title
    },

    [TITLE_MUTATE_LOADING_SUMMARY_TITLE](state, isLoading) {
        state.loadingSummaryTitle = isLoading
    },

    [TITLE_MUTATE_LOADING_OFFICIAL_COPIES_AVAILABILITY](state, isLoading) {
        state.selectedTitle.officialCopiesAvailability.loading = isLoading
    },

    [TITLE_MUTATE_OFFICIAL_COPY_PROPERTIES](state, { properties, order }) {
        // If there's an update for a particular title's documents, see if it's selected so that the entry will update without requiring a refresh.
        const selectedTitleNumber = state.selectedTitleNumber
        const doc = state.selectedTitle.officialCopiesAvailability.results?.referredToDocuments
            ?.find(doc => generateOrderKeyValue(selectedTitleNumber, doc.documentType, doc) === order.keyValue)
        if (doc) {
            Object.assign(doc, properties)
        }
    },

    // Used to indicate that an order is in progress for a particular title. Useful for loading icons, disabling buttons, etc.
    [TITLE_MUTATE_REGISTER_ORDER_LOADING](state, isLoading) {
        state.selectedTitle.officialCopiesAvailability.results.titleRegister.loadingOrder = isLoading
    },

    // Used to indicate that an order is in progress for a particular title. Useful for loading icons, disabling buttons, etc.
    [TITLE_MUTATE_TITLE_PLAN_ORDER_LOADING](state, isLoading) {
        state.selectedTitle.officialCopiesAvailability.results.titlePlan.loadingOrder = isLoading
    },

    [TITLE_MUTATE_REGISTER_DATA_FROM_ORDER_MESSAGE](state, orderMessage) {
        // Update these properties regardless of order status.
        state.selectedTitle.officialCopiesAvailability.results.titleRegister.orderStatus = orderMessage.status
        if (orderMessage?.notificationData?.documentId) {
            state.selectedTitle.officialCopiesAvailability.results.titleRegister.documentId = orderMessage.notificationData.documentId
        }
        if (orderMessage?.notificationData?.orderId) {
            state.selectedTitle.officialCopiesAvailability.results.titleRegister.orderId = orderMessage.notificationData.orderId
        }
        state.selectedTitle.officialCopiesAvailability.results.titleRegister.failedOrder = isDocumentOrderFailure(orderMessage.status)
    },

    [TITLE_MUTATE_TITLE_PLAN_DATA_FROM_ORDER_MESSAGE](state, orderMessage) {
        // Update these properties regardless of order status.
        state.selectedTitle.officialCopiesAvailability.results.titlePlan.orderStatus = orderMessage.status
        if (orderMessage?.notificationData?.documentId) {
            state.selectedTitle.officialCopiesAvailability.results.titlePlan.documentId = orderMessage.notificationData.documentId
        }
        if (orderMessage?.notificationData?.orderId) {
            state.selectedTitle.officialCopiesAvailability.results.titlePlan.orderId = orderMessage.notificationData.orderId
        }
        state.selectedTitle.officialCopiesAvailability.results.titlePlan.failedOrder = isDocumentOrderFailure(orderMessage.status)
        state.selectedTitle.officialCopiesAvailability.results.titlePlan.currentVersionHasBeenSentByPost = orderMessage.status === DocumentOrderStatus.SENT_IN_POST
        if (orderMessage.status === DocumentOrderStatus.ORDERED) {
            state.selectedTitle.officialCopiesAvailability.results.titlePlan.responseDateValue = new Date().toString()
        }
    },

    [TITLE_MUTATE_OC2_DOCUMENT_STATUS](state, { keyValue, status }) {
        const document = state.selectedTitle.officialCopiesAvailability.results.referredToDocuments.find(x => x.keyValue === keyValue)
        if (document) {
            document.orderStatus = status
        }
    },

    [TITLE_MUTATE_OC2_DATA_FROM_ORDER_MESSAGE](state, orderMessage) {
        // Update these properties after receiving an order update notification message.
        const document = state.selectedTitle.officialCopiesAvailability.results.referredToDocuments.find(x => x.keyValue === orderMessage.keyValue)
        if (document) {
            document.orderStatus = orderMessage.status
            if (orderMessage?.notificationData?.documentId) {
                document.documentId = orderMessage.notificationData.documentId
            }
            if (orderMessage?.notificationData?.orderId) {
                document.orderId = orderMessage.notificationData.orderId
            }
            document.failedOrder = isDocumentOrderFailure(orderMessage.status)
            document.orderStatusText = document.currentVersionHasBeenSentByPost
                ? getDocumentOrderStatusText(DocumentOrderStatus.SENT_IN_POST)
                : getDocumentOrderStatusText(document.orderStatus)
            switch (orderMessage.status) {
                case DocumentOrderStatus.ORDERING:
                    document.orderAvailable = false
                    break
                case DocumentOrderStatus.ORDERED:
                    document.downloadAvailable = true
                    document.orderAvailable = false
                    break
                case DocumentOrderStatus.AUGMENTED:
                    document.viewAvailable = true
                    document.downloadAvailable = true
                    break
            }
        }
    },

    [TITLE_SHOW_SELECTION_LAYER](state, show) {
        if (state.selectionLayer) {
            state.selectionLayer.setVisible(show)
        }
    },
}
