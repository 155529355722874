<template>
    <base-sub-type-table v-model:update-count="updateCount"
                         :detail-columns="headings"
                         :notification="item"
                         data-track="Planning Applications Updated Item"
                         data-test="planning-applications-updated-item" />
</template>

<script setup lang="ts">
    import {
        computed,
    } from "vue"
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotificationDetail } from "@/components/asset-monitoring/asset-monitoring-notification.interface"
    import {
        EpcDatapoint,
        PlanningApplicationsDatapoint,
    } from '@/components/asset-monitoring/datapoint-interfaces'
    import BaseSubTypeTable
        from "@/components/asset-monitoring/grid/sub-type-items/base-sub-type-table/base-sub-type-table.vue"
    import { IBaseSubTypeTableColumn } from "@/components/asset-monitoring/grid/sub-type-items/types"
    import { EnergyBand } from "@/enums/epc.enum"
    import { getThemeForEnergyBand } from "@/models/epc.model"
    import { format } from "@/utils/date-utils"

    const { t } = useI18n()
    const updateCount = defineModel<number>('update-count')

    const DECISION_LABELS: Record<number, string> = {
        1: 'colored-label is-success is-extra-small',
        2: 'colored-label is-danger is-extra-small',
        3: 'colored-label is-warning is-extra-small',
    }

    const renderDecisionLabel = (decision: number, value: string): string => {
        const labelClass = DECISION_LABELS[decision]
        return labelClass ? `<span class="${ labelClass }">${ value }</span>` : value
    }

    const headings = computed<IBaseSubTypeTableColumn<PlanningApplicationsDatapoint>[]>(() => ([
        // {
        //     field: 'identifier',
        //     title: t('label.identifier'),
        //     dataTest: 'identifier',
        // },
        {
            field: 'address',
            title: t('label.address'),
            dataTest: 'address',
        },
        // {
        //     field: 'postcode',
        //     title: t('label.postcode'),
        //     dataTest: 'postcode',
        // },
        // {
        //     field: 'authority',
        //     title: t('label.authority'),
        //     dataTest: 'authority',
        // },
        {
            field: 'description',
            title: t('label.description'),
            dataTest: 'description',
        },
        {
            field: 'decision',
            title: t('label.decision'),
            dataTest: 'decision',
            hidden: true,
        },
        {
            field: 'decisionText',
            title: t('label.status'),
            dataTest: 'decisionText',
            customRender: (row: PlanningApplicationsDatapoint, value: string) => {
                return renderDecisionLabel(row.decision, value)
            },
        },
    ]))

    defineProps<{
        item: IAssetMonitoringNotificationDetail,
    }>()

    defineExpose({
        updateCount,
    })
</script>