<template>
    <section class="base-sub-type-item oc2-document-updated"
             data-test="boundary-updated"
             data-track="Boundary Updated">
        <div class="boundary-updated__items">
            <h3 class="boundary-updated__items--title label-caps-small">
                {{ t('assetMonitoring.subType.boundaryUpdated.boundary') }}
            </h3>
            <div class="boundary-updated__details">
                <div class="boundary-updated__map">
                    <ow-boundary-map ref="owBoundaryMap"
                                     :key="mapKey"
                                     :boundary-width="3"
                                     :geoserver-nps-layer-name="npsLayer"
                                     :show-layer-attribution="false"
                                     :layer-collection="layers"
                                     enable-map-interactions
                                     :padding="[-12,-12,-12,-12]" />
                </div>

                <div class="boundary-updated__details">
                    <div class="boundary-updated__key">
                        <p class="label-caps-small">
                            {{ t('assetMonitoring.subType.boundaryUpdated.key') }}
                            <ow-checkbox v-if="reducedBoundaryLayer"
                                         :id="`boundary-change-reduced-${props.item.notificationId}`"
                                         v-model="isReducedBoundaryVisible"
                                         data-test="reduced-boundary-checkbox"
                                         data-track="Reduced Boundary Checkbox"
                                         :label="t('assetMonitoring.subType.boundaryUpdated.boundaryChangeReduced')">
                                <template #pre-label>
                                    <ow-colored-label theme="boundary-red"
                                                      is-notification-dot
                                                      is-small />
                                </template>
                            </ow-checkbox>
                            <ow-checkbox v-if="addedBoundaryLayer"
                                         :id="`boundary-change-added-${props.item.notificationId}`"
                                         v-model="isAddedBoundaryVisible"
                                         data-test="added-boundary-checkbox"
                                         data-track="Added Boundary Checkbox"
                                         :label="t('assetMonitoring.subType.boundaryUpdated.boundaryChangeAdded')">
                                <template #pre-label>
                                    <ow-colored-label theme="boundary-green"
                                                      is-notification-dot
                                                      is-small />
                                </template>
                            </ow-checkbox>
                            <ow-checkbox :id="`boundary-change-new-${props.item.notificationId}`"
                                         v-model="isNewBoundaryVisible"
                                         data-test="new-boundary-checkbox"
                                         data-track="New Boundary Checkbox"
                                         :label="t('assetMonitoring.subType.boundaryUpdated.newBoundary')">
                                <template #pre-label>
                                    <ow-colored-label theme="boundary-blue"
                                                      is-notification-dot
                                                      is-small />
                                </template>
                            </ow-checkbox>
                            <ow-checkbox :id="`boundary-change-previous-${props.item.notificationId}`"
                                         v-model="isPreviousBoundaryVisible"
                                         data-test="previous-boundary-checkbox"
                                         data-track="Previous Boundary Checkbox"
                                         :label="t('assetMonitoring.subType.boundaryUpdated.previousBoundary')">
                                <template #pre-label>
                                    <ow-colored-label theme="boundary-grey"
                                                      is-notification-dot
                                                      is-small />
                                </template>
                            </ow-checkbox>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import difference from '@turf/difference'
    import {
        featureCollection,
        multiPolygon,
        polygon,
    } from '@turf/helpers'
    import GeoJSON from "ol/format/GeoJSON"
    import {getArea} from "ol/sphere"
    import {
        computed,
        PropType,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import { IAssetMonitoringNotificationDetail } from "@/components/asset-monitoring/asset-monitoring-notification.interface"
    import {
        Difference,
        UpdatedProperty,
    } from '@/components/asset-monitoring/difference-generic'
    import { BoundaryMapLayer } from "@/components/core/maps/boundary-map-layer"
    import OwBoundaryMap from '@/components/core/maps/ow-boundary-map.vue'
    import OwCheckbox from "@/components/core/ow-checkbox.vue"
    import OwColoredLabel from "@/components/core/ow-colored-label.vue"
    import { Colors } from '@/enums/colors.enum'
    import { isNullOrEmpty } from '@/utils/array-utils'

    const props = defineProps({
        item: {
            type: Object as PropType<IAssetMonitoringNotificationDetail>,
            required: true,
        },
        showingDetailsPanel: {
            type: Boolean,
            required: true,
        },
    })

    const emit = defineEmits<{
        (e: 'area-difference', value: number),
    }>()

    const { t } = useI18n()
    const store = useStore()
    const diff = computed<Difference<string>>(() => {
        if (props.item) {
            return JSON.parse(props.item?.difference)
        }

        return null
    })
    const mapKey = ref<string | null>('map-key')

    // Get the added, removed, and updated items
    const updatedItems = ref<Array<UpdatedProperty<string>>>([])
    const hasUpdatedItems = ref<boolean>(false)

    const layers = computed<Array<BoundaryMapLayer>>(() => {
        let tempLayers: Array<BoundaryMapLayer> = []

        if(isPreviousBoundaryVisible.value){
            tempLayers.push(previousBoundaryLayer.value)
        }

        if(isNewBoundaryVisible.value){
            tempLayers.push(newBoundaryLayer.value)
        }

        if(reducedBoundaryLayer.value != null && isReducedBoundaryVisible.value){
            tempLayers.push(reducedBoundaryLayer.value)
        }

        if(addedBoundaryLayer.value != null && isAddedBoundaryVisible.value){
            tempLayers.push(addedBoundaryLayer.value)
        }

        return tempLayers
    })

    const isReducedBoundaryVisible = ref<boolean>(true)
    const isAddedBoundaryVisible = ref<boolean>(true)
    const isNewBoundaryVisible = ref<boolean>(true)
    //Default previous value to false. All others will be true
    const isPreviousBoundaryVisible = ref<boolean>(false)

    const reducedBoundaryLayer = ref<BoundaryMapLayer | null>(null)
    const addedBoundaryLayer = ref<BoundaryMapLayer | null>(null)
    const previousBoundaryLayer = ref<BoundaryMapLayer | null>(null)
    const newBoundaryLayer = ref<BoundaryMapLayer | null>(null)

    watch(() => props.showingDetailsPanel, (val) => {
        if (val) {
            // generate new key to force map to re-render
            mapKey.value = Math.random().toString(36).substring(7)
        }
    })

    watch(diff, (newVal: Difference<string>) => {
        updatedItems.value = newVal.updated
        hasUpdatedItems.value = !isNullOrEmpty(newVal?.updated)

        newBoundaryLayer.value = BoundaryMapLayerInstance(
            updatedItems.value?.[0]?.after,
            Colors.BlueBoundary.toString(),
            15)

        previousBoundaryLayer.value = BoundaryMapLayerInstance(
            updatedItems.value?.[0]?.before,
            Colors.GreyBoundary.toString(),
            15)

        if(hasUpdatedItems.value) {
            let previousBoundary: any = JSON.parse(updatedItems.value?.[0]?.before)
            let currentBoundary: any = JSON.parse(updatedItems.value?.[0]?.after)

            if (previousBoundary == null || currentBoundary == null) {
                return
            }

            previousBoundary = getTurfPolygon(previousBoundary)
            currentBoundary = getTurfPolygon(currentBoundary)

            let addedAreas = difference(featureCollection([currentBoundary, previousBoundary]))
            let removedAreas = difference(featureCollection([previousBoundary, currentBoundary]))

            let addedAreaGeoJson = addedAreas ? JSON.stringify(addedAreas) : null
            let reducedAreaGeoJson = removedAreas ? JSON.stringify(removedAreas) : null

            if(addedAreaGeoJson != null){
                addedBoundaryLayer.value = BoundaryMapLayerInstance(
                    addedAreaGeoJson,
                    Colors.GreenBoundary.toString(),
                    1)
            }

            if(reducedAreaGeoJson != null) {
                reducedBoundaryLayer.value = BoundaryMapLayerInstance(
                    reducedAreaGeoJson,
                    Colors.RedBoundary.toString(),
                    1)
            }

            const previousAreaFeatures = new GeoJSON().readFeatures(updatedItems.value?.[0]?.before)
            const newAreaFeatures = new GeoJSON().readFeatures(updatedItems.value?.[0]?.before)

            // I'm fairly confident that there should never be more than 1 feature in each
            // However I'm leaving these exceptions in so that we can prove the above during user testing
            if(previousAreaFeatures.length > 1){
                throw new Error('Previous area had more than 1 feature!')
            }

            if(newAreaFeatures.length > 1){
                throw new Error('Previous area had more than 1 feature!')
            }

            const previousAreaMetres = getArea(previousAreaFeatures[0].getGeometry())
            const newAreaMetres = getArea(newAreaFeatures[0].getGeometry())
            const areaDifference = newAreaMetres - previousAreaMetres
            emit('area-difference', areaDifference)
        }
    }, { immediate: true })

    const npsLayer = computed(() => store.state.config?.settings?.npsLayer)

    function BoundaryMapLayerInstance(geoJson: string, colour: string, zIndex: number) : BoundaryMapLayer  {
        return {
            geoJson: geoJson,
            fill: true,
            colour: colour,
            fillOpacity: 0.2,
            hatch: false,
            show: true,
            strokeWidth: 2,
            dashed: false,
            zIndex: zIndex,
            label: null,
            showTitleNumber: false,
            titleNumber: null,
        }
    }

    function getTurfPolygon(boundary: any){
        switch(boundary.type){
            case 'MultiPolygon':
                return multiPolygon(boundary.coordinates)
            case 'Polygon':
                return polygon(boundary.coordinates)
            default:
                console.error('Unhandled type: ', boundary.type)
                return null
        }
    }
</script>

<style lang="scss" scoped>
    @import './boundary-updated';
</style>
