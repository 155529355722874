<template>
    <li v-if="content"
        :class="{readonly: isSharedLinkView }"
        class="d-flex flex flex-column shrink walkthrough-page-content-item"
        @mouseleave="mouseOverBoundaryHighlightElement = false"
        @mouseover="mouseOverBoundaryHighlightElement = true">
        <div class="d-flex flex flex-row align-center justify-space-between walkthrough-page-content-item__header">
            <div class="d-flex shrink"
                 style="padding-right: 10px">
                <div v-if="showLegend"
                     :style="legendStyle"
                     class="walkthrough-page-content-item__legend"
                     data-test="walkthrough-page-content-item-legend-graphic" />
            </div>
            <div v-if="contentLabel"
                 :data-test="`walkthrough-page-content-item-label-${ content.titleNumber }`"
                 class="d-flex flex body-copy walkthrough-page-content-item__label">
                <span>{{ contentLabel }}</span>
            </div>

            <v-btn v-if="showMoreDetailsButton"
                   :data-test="`walkthrough-page-content-item-more-details-${ content.titleNumber }`"
                   color="primary"
                   data-track="WALKTHROUGH - Open title summary details panel"
                   variant="text"
                   @click="getSummaryDetails(content.titleNumber)">
                More details
            </v-btn>

            <v-btn v-if="showEditButton"
                   :data-test="`walkthrough-page-content-item-edit-${ content.titleNumber }`"
                   color="black"
                   data-track="WALKTHROUGH - Open title styling popup"
                   icon
                   variant="text"
                   @click="onEditClick">
                <v-icon>$edit</v-icon>
            </v-btn>
            <v-btn v-if="showDeleteButton"
                   :data-test="`walkthrough-page-content-item-delete-${ content.titleNumber }`"
                   color="black"
                   data-track="WALKTHROUGH - Delete title from page"
                   icon
                   variant="text"
                   @click="updateWalkthroughPage">
                <v-icon>$delete</v-icon>
            </v-btn>
            <v-btn v-if="showVisibilityButton"
                   :color="content.show ? 'primary' : 'black'"
                   data-track="WALKTHROUGH - Toggle title visibility"
                   icon
                   variant="text"
                   @click="toggleContentItemVisibility(content)">
                <v-icon>$visibility</v-icon>
            </v-btn>
        </div>
        <ow-rich-text-editor v-if="commentText || !isSharedLinkView"
                             v-model="commentText"
                             :is-read-only="showReadOnlyComment"
                             :placeholder="contentCommentHint"
                             class="walkthrough-page-content-item__rich-text-field-comment"
                             data-track="WALKTHROUGH - Add title description" />
    </li>
</template>

<script lang="ts">
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwRichTextEditor from '@/components/core/ow-rich-text-editor.vue'
    import { MATTER_MUTATE_STYLE_PROMPT } from '@/store/modules/matter/types'
    import { TITLE_FETCH_TITLE_SUMMARY_BY_NUMBER } from '@/store/modules/titles/types'
    import {
        WALKTHROUGH_MUTATE_HIGHLIGHT_BOUNDARIES_FOR_TITLE_NUMBER,
        WALKTHROUGH_MUTATE_PAGE_CONTENT_ITEM_VISIBILITY,
        WALKTHROUGH_MUTATE_REMOVE_ITEM_FROM_SELECTED_PAGE,
        WALKTHROUGH_UPDATE_SELECTED_PAGE_LAYER,
    } from '@/store/modules/walkthrough/types'
    import { isNullOrWhitespace } from '@/utils/string-utils'
    import { getCSSStyleForOWStyleDefinition } from '@/utils/style-utils'

    const EVENTS = {
        updated: 'updated',
    }

    export default {
        name: 'WalkthroughPageContentItem',

        components: {
            OwRichTextEditor,
        },

        props: {
            content: {
                type: Object,
                required: true,
            },
            isSharedLinkView: {
                type: Boolean,
                required: false,
            },
        },

        emits: Object.values(EVENTS),

        data() {
            return {
                mouseOverBoundaryHighlightElement: false,
            }
        },

        computed: {

            ...mapState({
                matterId: state => state.matter.currentMatter.id,
                selectedPage: state => state.walkthrough.selectedPage,
            }),

            contentCommentHint() {
                return `Add a comment about this ${ this.isTitle ? 'title' : 'sketch' }`
            },

            contentLabel() {
                if (this.isTitle) {
                    return this.content.label ?? this.content.titleNumber
                } else {
                    return this.content.label ?? null
                }
            },

            isTitle() {
                return this.content.titleNumber !== null
            },

            // Returns a suitable CSS style based on the title/sketch style configuration.
            legendStyle() {
                return getCSSStyleForOWStyleDefinition(this.content)
            },

            showEditButton() {
                return !this.isSharedLinkView
            },

            showDeleteButton() {
                return !this.isSharedLinkView
            },

            showVisibilityButton() {
                return this.isSharedLinkView
            },

            showReadOnlyComment() {
                return this.isSharedLinkView && !isNullOrWhitespace(this.content.comment)
            },

            showMoreDetailsButton() {
                return this.isTitle
            },

            showLegend() {
                return this.content.boundaryAvailable
            },

            commentText: {
                get() {
                    const comment = this.content?.comment
                    if (isNullOrWhitespace(comment)) {
                        return ''
                    }

                    if (comment.startsWith('<p>')) {
                        return comment
                    }

                    return `<p>${ comment }</p>`
                },
                set(val) {
                    this.$emit(EVENTS.updated, {
                        comment: val,
                        titleNumber: this.content?.titleNumber,
                    })
                },
            },
        },

        watch: {

            mouseOverBoundaryHighlightElement(val) {
                if (val == true) {
                    if (this.isTitle) {
                        this.highlightTitleNumber(this.content.titleNumber)
                    }
                } else {
                    this.highlightTitleNumber(null)
                }
            },

            // Property changes that require the map feature be redrawn.
            legendStyle() {
                this.updateFeatureOnStyleChanges()
            },

            contentLabel() {
                this.updateFeatureOnStyleChanges()
            },

            'content.showTitleNumber'() {
                this.updateFeatureOnStyleChanges()
            },
        },

        methods: {

            ...mapActions({
                getSummaryTitleDetails: TITLE_FETCH_TITLE_SUMMARY_BY_NUMBER,
                updateWalkthrough: WALKTHROUGH_UPDATE_SELECTED_PAGE_LAYER,
            }),

            ...mapMutations({
                remove: WALKTHROUGH_MUTATE_REMOVE_ITEM_FROM_SELECTED_PAGE,
                showStylePrompt: MATTER_MUTATE_STYLE_PROMPT, // styling is currently done via the existing style dialog. This can be revisited when sketches and styling is revisited.
                highlightTitleNumber: WALKTHROUGH_MUTATE_HIGHLIGHT_BOUNDARIES_FOR_TITLE_NUMBER,
                toggleContentItemVisibility: WALKTHROUGH_MUTATE_PAGE_CONTENT_ITEM_VISIBILITY,
            }),

            onEditClick() {
                this.showStylePrompt({
                    titles: [this.content],
                    updateMainMap: false,
                })
            },

            updateWalkthroughPage(content) {
                this.remove(content)
                this.updateWalkthrough()
            },

            // Trigger a redraw of the feature(s) with changed styles.
            updateFeatureOnStyleChanges() {
                // This isn't an action/mutation since we don't care about affects on application state,
                // the styling is only done in this component, and there are likely to be
                // variations on updating the layer / feature based on the context so this code may not be that reusable either.
                this.selectedPage.layer
                    .getSource()
                    .getFeatures()
                    .filter(feature => {
                        if (this.content.titleNumber) {
                            return feature.get('title_no') === this.content.titleNumber
                        }

                        // revise when considering sketches e.g. check the sketch ID.
                        return false
                    })
                    .forEach(feature => feature.changed())
            },

            getSummaryDetails() {
                this.getSummaryTitleDetails({
                    matterId: this.matterId,
                    titleNumber: this.content.titleNumber,
                    titleLabel: this.contentLabel,
                })
            },
        },
    }
</script>
<style lang="scss">
    @import 'walkthrough-page-content-item.scss'
</style>
