<template>
    <ow-lock v-model="showLock"
             :class="{
                 disabled,
                 selected: isSelected,
                 toggle: toggle,
                 'wrap-text': wrapText,
                 'is-matter-related': isMatterRelated,
             }"
             :feature-id="featureId"
             align="center"
             hide-tooltip
             class="global-nav-item"
             @click="onClick">
        <template #label>
            <div class="global-nav-item__label small-card-subheading"
                 data-test="global-nav-item-label">
                {{ label }}
            </div>
        </template>
    </ow-lock>

    <div v-if="!showLock"
         :class="{
             disabled,
             selected: isSelected,
             toggle: toggle,
             'wrap-text': wrapText,
             'is-matter-related': isMatterRelated,
             'has-charges-text': chargesText,
         }"
         :data-global-nav-item="globalNavName"
         :data-test="dataTestAttribute"
         :data-track="dataTrackAttribute"
         class="global-nav-item"
         justify-center
         v-bind="$attrs"
         @click="onClick">
        <v-badge v-if="hasNotification"
                 :color="Colors.Success250"
                 floating
                 dot>
            <v-icon v-if="icon"
                    data-test="global-nav-item-icon">
                {{ icon }}
            </v-icon>
        </v-badge>
        <v-icon v-else-if="icon"
                data-test="global-nav-item-icon">
            {{ icon }}
        </v-icon>
        <div v-if="customIcon"
             data-test="global-nav-item-custom-icon">
            <ow-icon :icon="customIcon"
                     data-test="global-nav-item-custom-icon" />
        </div>
        <div v-if="chargesText"
             class="global-nav-item__charges-text"
             data-test="global-nav-item-charges">
            {{ chargesText }}
        </div>
        <div class="global-nav-item__label small-card-subheading"
             data-test="global-nav-item-label">
            {{ label }}
        </div>
        <ow-tooltip activator="parent"
                    max-width="300"
                    :position="OwTooltipPosition.Right"
                    :disabled="!disabled"
                    :data-test="`global-nav-item-tooltip-text-${ tooltipText }`">
            <span>{{ tooltipText }}</span>
        </ow-tooltip>
    </div>
</template>
<script lang="ts">
    import {
        onBeforeMount,
        ref,
    } from "vue"

    import OwIcon from '@/components/core/ow-icon.vue'
    import OwLock from "@/components/core/ow-lock.vue"
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { inject as userProvider } from "@/composables/use-user"
    import { Colors } from "@/enums/colors.enum"
    import {OwTooltipPosition} from '@/enums/ow-tooltip-position'

    export default {
        name: 'GlobalNavItem',

        components: {
            OwIcon,
            OwTooltip,
            OwLock,
        },

        props: {
            icon: {
                type: String,
                required: false,
                default: null,
            },
            customIcon: {
                type: String,
                required: false,
                default: null,
            },
            chargesText: {
                type: String,
                required: false,
                default: null,
            },
            tooltipText: {
                type: String,
                required: false,
            },
            label: {
                type: String,
                required: true,
            },
            isMatterRelated: {
                type: Boolean,
                required: false,
            },
            routerPath: [Object, String],
            globalNavName: [String, Array],
            selected: {
                type: Boolean,
                required: false,
                default: null,
            },
            toggle: {
                type: Boolean,
                required: false,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
            },
            wrapText: {
                type: Boolean,
                required: false,
                default: false,
            },
            dataTestAttribute: {
                type: String,
                required: false,
            },
            dataTrackAttribute: {
                type: String,
                required: false,
            },
            hasNotification: {
                type: Boolean,
                required: false,
                default: false,
            },
            featureId: {
                type: String,
                required: false,
            },
        },

        emits: ['click'],

        setup(props) {
            const showLock = ref()
            const {hasAccessToFeature} = userProvider()
            onBeforeMount(async () => {
                if (props.featureId) {
                    const hasAccess = await hasAccessToFeature(props.featureId)
                    showLock.value = !hasAccess
                }
            })

            return {
                showLock,
            }
        },

        data() {
            return {
                OwTooltipPosition,
            }
        },

        computed: {
            Colors() {
                return Colors
            },
            isSelected() {
                if (this.selected !== null) {
                    return this.selected
                } else {
                    return this.globalNavName === this.$route.meta.globalNavName || this.globalNavName.includes(this.$route.meta.globalNavName)
                }
            },
        },

        methods: {
            onClick() {
                if (!this.disabled) {
                    if (this.routerPath && !this.selected) {
                        this.$router.push(this.routerPath)
                    }
                    this.$emit('click')
                }
            },
        },
    }
</script>

<style lang="scss">
@import 'global-nav-item.scss';
</style>
