<template>
    <section ref="rootRef"
             :data-group="item.titleNumber"
             class="row-item"
             tabindex="0"
             @click="onExpandCollapse">
        <template v-if="isVisible">
            <div :data-test="`row-item-${item.notificationId}-${isShowingDetailsPanel ? 'expanded' : 'collapsed'}`"
                 class="grid-row"
                 data-track="Asset Monitoring: alert grid item">
                <div class="d-flex gc-4 gr-2 justify-space-between">
                    <div class="d-flex gc-4 align-center">
                        <base-row-item-expand-collapse :is-showing-details-panel="isShowingDetailsPanel"
                                                       :is-notification-read="isNotificationRead"
                                                       :item="item"
                                                       @expand-collapse="onExpandCollapse" />
                        <div class="row-item__content">
                            <base-row-item-title :model="model"
                                                 :item="item" />
                            <base-row-item-description :model="model"
                                                       @title-number-click="handleTitleNumberClick" />
                        </div>
                    </div>
                    <base-row-item-date :model="model"
                                        :item="item" />
                </div>
                <div class="d-flex flex-column justify-end">
                    <div class="row-item--actions">
                        <slot name="actions" />
                    </div>
                </div>
            </div>
            <div v-if="hasExtraInfoSlotContent"
                 v-show="isShowingDetailsPanel"
                 @click.stop>
                <div class="row-item__extra-info d-flex justify-space-between gc-4">
                    <div class="flex-grow-1 w-100 overflow-x-auto">
                        <slot name="extra-info" />
                    </div>
                    <ow-button v-if="isNotificationRead"
                               class="row-item__mark-as-unread"
                               is-secondary
                               is-small
                               data-track="Asset Monitoring: mark as unread"
                               data-test="mark-as-unread"
                               @click.stop="handleMarkAsUnreadToggleClick">
                        {{ t('action.markAsUnread') }}
                    </ow-button>
                </div>
            </div>
        </template>
    </section>
</template>

<script lang="ts"
        setup>
    import {
        computed,
        nextTick,
        onMounted,
        ref,
        useSlots,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotification } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import {
        BaseRowItemModel,
    } from '@/components/asset-monitoring/base-row-item-model'
    import BaseRowItemDate from '@/components/asset-monitoring/grid/row-items/base-row-item-date.vue'
    import BaseRowItemDescription from '@/components/asset-monitoring/grid/row-items/base-row-item-description.vue'
    import BaseRowItemExpandCollapse
        from '@/components/asset-monitoring/grid/row-items/base-row-item-expand-collapse.vue'
    import BaseRowItemTitle from '@/components/asset-monitoring/grid/row-items/base-row-item-title.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import { useAssetMonitoringStore } from '@/stores/asset-monitoring'

    const props = defineProps<{
        item: IAssetMonitoringNotification,
        model: BaseRowItemModel
    }>()

    const emit = defineEmits<{
        (e: 'view-title', titleNumber: string),
        (e: 'mark-as-read', payload: { notificationId: string, isRead: boolean }),
    }>()

    const isShowingDetailsPanel = defineModel<boolean>({
        default: false,
    })
    const slots = useSlots()
    const assetMonitoringStore = useAssetMonitoringStore()
    const { t } = useI18n()
    const hasExtraInfoSlotContent = computed(() => Boolean(slots['extra-info']))

    const rootRef = ref(null)
    let isVisible = ref<boolean>(true)

    const isNotificationRead = computed(() => {
        return assetMonitoringStore.isNotificationRead(props.item.notificationId)
    })

    const handleMarkAsRead = async () => {
        emit('mark-as-read', {
            notificationId: props.item.notificationId,
            isRead: true,
        })
    }

    const handleMarkAsUnreadToggleClick = async () => {
        emit('mark-as-read', {
            notificationId: props.item.notificationId,
            isRead: !isNotificationRead.value,
        })
    }

    const handleTitleNumberClick = () => {
        nextTick(() => {
            emit('view-title', props.item.titleNumber)
        })
    }

    const onExpandCollapse = () => {
        isShowingDetailsPanel.value = !isShowingDetailsPanel.value
    }

    watch(() => isShowingDetailsPanel.value, (val) => {
        if (val && !props.item.isRead) {
            handleMarkAsRead()
        }
    })

</script>

<style lang="scss"
       scoped>
    @import './base-row-item.scss';
</style>
