<template>
    <header class="uploaded-documents-summary">
        <div v-if="!isLoading"
             class="body-copy uploaded-documents-summary__label">
            <span class="body-regular uploaded-documents-summary__label--text">
                <span>{{ $t('label.showing') }}</span>
                <!-- Filtered document count-->
                <span v-if="hasFilter"
                      data-test="uploaded-documents--summary-filtered-count">{{ filteredDocumentsCount }} of </span>
                <!-- Total document count -->
                <span data-test="uploaded-documents-summary-total-count">
                    {{ $t('documents.library.documentsCount', totalDocumentsCount) }},
                </span>
                <span data-test="uploaded-documents-summary-successful-count">
                    {{ $t('documents.library.successfullyUploadedDocumentsCount', successfullyUploadedDocumentsCount) }},
                </span>
                <span data-test="uploaded-documents-summary-failed-count">
                    {{ $t('documents.library.failedToUploadDocumentsCount', failedToUploadDocumentsCount) }}
                </span>
            </span>
        </div>
        <div class="uploaded-documents-summary__actions">
            <ow-button v-t="'action.refresh'"
                       is-secondary
                       data-test="uploaded-documents-summary-refresh-uploads-btn"
                       data-track="DOCUMENTS - Refresh uploaded documents"
                       @click="refreshDocuments" />

            <ow-button is-secondary
                       :disabled="!hasSelectedDocuments"
                       data-test="uploaded-documents-summary-delete-uploads-btn"
                       data-track="DOCUMENTS - Delete uploaded documents"
                       @click="deleteDocuments">
                <div class="d-flex align-center">
                    <v-icon>$delete</v-icon>{{ hasSelectedDocuments ? $t('action.deleteWithCount', {count: selectedDocumentsCount}) : $t('action.delete') }}
                </div>
            </ow-button>

            <ow-button is-primary
                       :disabled="!hasSelectedDocuments"
                       data-test="upload-documents-summary-see-reporting-options-btn"
                       data-track="DOCUMENTS - Go to reporting options from uploaded tab"
                       @click="seeReportingOptions">
                {{ $t('report.action.seeReportingOptions') }}
                <v-icon>$chevron-right</v-icon>
            </ow-button>
        </div>
    </header>
</template>

<script setup lang="ts">
    import { computed,
             PropType } from 'vue'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import {
        uploadDocumentFailureResponses,
        uploadDocumentSuccessResponses,
    } from '@/enums/uploaded-document-status.enum'
    import { IUploadedDocument } from '@/interfaces/uploaded-document.interface'

    const props = defineProps<{
        filteredDocumentsCount: number
        documents: Array<IUploadedDocument>
        selectedDocumentsCount: number
        isLoading?: boolean
    }>()

    const emit = defineEmits<{
        (e: 'refresh-documents'): void
        (e: 'see-reporting-options-clicked'): void
        (e: 'delete-documents'): void
    }>()

    const hasFilter = computed(() => props.filteredDocumentsCount > 0)
    const hasSelectedDocuments = computed(() => props.selectedDocumentsCount > 0)
    const totalDocumentsCount = computed(() => props.documents.length)
    const successfullyUploadedDocumentsCount = computed(() => props.documents.filter(document => uploadDocumentSuccessResponses.includes(document.status)).length)
    const failedToUploadDocumentsCount = computed(() => props.documents.filter(document => uploadDocumentFailureResponses.includes(document.status)).length)

    const refreshDocuments = () => {
        emit('refresh-documents')
    }

    const seeReportingOptions = () => {
        emit('see-reporting-options-clicked')
    }

    const deleteDocuments = () => {
        emit('delete-documents')
    }
</script>

<style lang="scss">
    @import './uploaded-documents-summary';
</style>
