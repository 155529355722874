import { MatterState } from '@/consts/matter.js'
import { isNullOrEmpty } from '@/utils/array-utils'
import { getAppUri } from '@/utils/environment.utils'

import {
    MATTER_GET_ASSET_MONITORING_TITLES,
    MATTER_GET_AVAILABLE_SERVICES,
    MATTER_GET_BY_TITLE_NUMBER,
    MATTER_GET_CURRENT_MATTER_ID,
    MATTER_GET_IS_CURRENT_MATTER_CLOSED,
    MATTER_GET_IS_SKETCHES_VISIBLE_MAP,
    MATTER_GET_LINK_SHARE_GUID,
    MATTER_GET_LINK_SHARE_URL,
    MATTER_GET_MATTER_CONTENTS,
    MATTER_GET_REMOVE_DOCUMENTS_PROMPT_STATE,
    MATTER_GET_SKETCHES_UNIT,
    MATTER_GET_TYPES,
    MATTER_HAS_TITLE_NUMBER,
    MATTER_IS_LINK_SHARE_DIRTY,
    MATTER_IS_SHARED_LINK_VIEW,
} from './types.js'

export default {
    [MATTER_HAS_TITLE_NUMBER](state) {
        return function(val) {
            return state.currentMatter.selectedTitles.some(n => n.titleNumber === val)
        }
    },

    [MATTER_GET_TYPES](state) {
        return state.matterTypes.sort()
    },

    [MATTER_IS_LINK_SHARE_DIRTY](state) {
        return JSON.stringify(state.currentMatter.linkShare) !== JSON.stringify(state.currentMatter.linkShareDirty)
    },

    [MATTER_GET_LINK_SHARE_URL](state) {
        return `${ getAppUri() }/share/${ state.currentMatter.linkShare?.linkGuid }`
    },

    [MATTER_GET_LINK_SHARE_GUID](state) {
        return state.currentMatter.linkShare?.linkGuid
    },

    [MATTER_IS_SHARED_LINK_VIEW](state) {
        return state.currentMatter?.linkShare?.isIspShare || state.currentMatter?.linkShare?.isWalkthroughShare
    },

    [MATTER_GET_IS_CURRENT_MATTER_CLOSED](state) {
        return state.currentMatter.state === MatterState.Closed
    },

    [MATTER_GET_CURRENT_MATTER_ID](state) {
        return state.currentMatter.id
    },

    [MATTER_GET_MATTER_CONTENTS](state) {
        if (!state.titleOrganiser.enable || !state.titleOrganiser.titles.length) {
            return {
                titles: state.currentMatter.selectedTitles,
                groups: state.currentMatter.groups,
            }
        } else {
            return {
                titles: state.titleOrganiser.titles,
                groups: state.titleOrganiser.groups,
            }
        }
    },

    [MATTER_GET_AVAILABLE_SERVICES](state, getters, rootState) {
        return state.titleOrganiser.availableServices.filter(service => service.isAvailable(rootState.config.featureFlags))
    },

    [MATTER_GET_IS_SKETCHES_VISIBLE_MAP](state) {
        return state.currentMatter.sketchesVisibleMap
    },

    [MATTER_GET_SKETCHES_UNIT](state) {
        return state.currentMatter.sketchesUnit
    },

    [MATTER_GET_BY_TITLE_NUMBER](state) {
        return function(titleNumber) {
            return state.currentMatter.selectedTitles.find(title => title.titleNumber === titleNumber)
        }
    },

    [MATTER_GET_REMOVE_DOCUMENTS_PROMPT_STATE](state) {
        return state.prompts.removeDocumentsFromMatter
    },

    /*
     * Get the asset monitoring title data
     * @returns {Array<ITitleGroup>}
     */
    [MATTER_GET_ASSET_MONITORING_TITLES](state) {
        return state.currentMatter.selectedTitles.map(title => {
            const groupName = state.currentMatter.groups.find(group => group.id === title.matterGroupId)?.name
            const label = title.label ? `${ title.label } ` : ''
            const address = !isNullOrEmpty(title.addresses) ? `${ title.addresses[0] } ` : ''
            const groupNameSearchTxt = groupName ? `${ groupName } ` : ''

            return {
                titleNumber: title.titleNumber,
                address: !isNullOrEmpty(title.addresses) ? title.addresses[0] : '',
                label: title.label,
                groupId: title.matterGroupId,
                groupName,
                isWhitelisted: false,
                searchString: `${ title.titleNumber } ${ label }${ address }${ groupNameSearchTxt }`.trim().toLowerCase(),
            }
        })
    },
}
