<template>
    <am-summary :matter-id="matterId" />
</template>

<script lang="ts" setup>
    import { computed } from 'vue'
    import { useRoute } from 'vue-router'

    import AmSummary from '@/components/asset-monitoring/am-summary.vue'

    const route = useRoute()
    const matterId = computed<number>(() => parseInt(route.params?.matterId?.toString()))

</script>
