<template>
    <title-panel-card :title="$t('titlePanel.registerEntries')"
                      class="register-card">
        <template v-if="isTitleRegisterPurchased"
                  #titleSuffix>
            <div class="register-card__title-suffix">
                <label class="mr-2"
                       for="titlePanelRegisterShowDocument">
                    {{ $t('label.showDocument') }}
                </label>
                <v-switch id="titlePanelRegisterShowDocument"
                          v-model="showDocument"
                          class="mr-3"
                          color="primary"
                          data-test="title-details-panel-register-show-document"
                          data-track="TITLE-DETAILS-PANEL - show document"
                          hide-details />
            </div>
        </template>
        <span v-if="!isTitleRegisterPurchased"
              v-t="'documents.noRegisterEntriesPurchase'"
              class="accents-italic"
              data-test="no-register-entries" />
        <span v-else-if="!registers.length"
              v-t="'documents.noRegisterEntries'"
              class="accents-italic"
              data-test="no-register-entries" />
        <template v-else>
            <register-card-actions v-model:highlight-dates="highlightDates"
                                   v-model:search-text="searchTextInput"
                                   v-model:show-button-menu="showButtonMenu"
                                   :entry-types="simplifiedEntryTypes"
                                   :hide-flags="hideFlags"
                                   :selected-entry-types="selectedEntryTypes"
                                   @search-clear="onSearchClear"
                                   @toggle-entry-type="toggleEntryType" />

            <register-card-flags v-if="!hideFlags"
                                 :selected-entry-types="selectedEntryTypes"
                                 @toggle-entry-type="toggleEntryType" />

            <div class="register-card__entries">
                <register-card-entries v-model="openedPanels"
                                       :highlight-dates="highlightDates"
                                       :registers="registers"
                                       :schedule-of-leases="scheduleOfLeases"
                                       :search-string="searchTextInput"
                                       :selected-entry-types="selectedEntryTypes"
                                       :tab-controller="tabController"
                                       @match-count-changed="$emit('match-count-changed', $event)"
                                       @scroll-to-entry="$emit('scroll-to-entry', $event)"
                                       @entry-clicked="$emit('entry-clicked', $event)"
                                       @view-plan-clicked="$emit('view-plan-clicked')"
                                       @view-link-clicked="$emit('view-link-clicked', $event)" />
            </div>
        </template>
    </title-panel-card>
</template>

<script lang="ts" setup>
    import {
        computed,
        nextTick,
        onBeforeUnmount,
        ref,
        watch,
    } from 'vue'
    import { useStore } from 'vuex'

    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import OwLoadingSlot from "@/components/core/ow-loading-slot.vue"
    import TitlePanelCard from '@/components/title-panel/v2/cards/title-panel-card.vue'
    import { useNlpSearch } from '@/composables/use-nlp-search'
    import { useSelectedTitle } from '@/composables/use-selected-title'
    import { ITitlePanelTabController } from '@/composables/use-title-panel'
    import { ICopiesFiledDocument } from '@/interfaces/copies-filed-document.interface'
    import {
        IRegisterLinkedIndicator,
        ISimplifiedRegisterLinkedIndicator,
    } from '@/interfaces/documents/entry-type.interface'
    import { IReferencedDocument } from '@/interfaces/documents/referenced-document.interface'
    import {
        IEnrichedRegister,
        IRegister,
    } from '@/interfaces/documents/register.interface'
    import { IRegisterEntry } from '@/interfaces/documents/register-entry.interface'
    import { IScheduleOfLeaseEntry } from '@/interfaces/documents/schedule-of-lease-entry.interface'
    import {
        TITLE_MUTATE_ENTRY_TYPES_SELECTION,
        TITLE_MUTATE_REGISTER_INDICATOR_SELECTION,
    } from '@/store/modules/titles/types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'

    import RegisterCardActions from './register-card-actions.vue'
    import RegisterCardEntries from './register-card-entries.vue'
    import RegisterCardFlags from './register-card-flags.vue'

    const store = useStore()

    const props = defineProps<{
        hideFlags?: boolean
        registers: IEnrichedRegister[],
        scheduleOfLeases?: IScheduleOfLeaseEntry[] | null,
        tabController: ITitlePanelTabController
    }>()

    defineEmits<{
        (e: 'scroll-to-entry', text: string): void
        (e: 'view-plan-clicked'): void
        (e: 'on-search-clear'): void
        (e: 'title-number-selected', titleNumber: string): void
        (e: 'view-link-clicked', document: ICopiesFiledDocument): void
        (e: 'entry-clicked', text: string): void
        (e: 'match-count-changed', count: number): void
    }>()

    const showDocument = defineModel<boolean>('showDocument')

    const searchTextInput = defineModel<string>('searchText')
    const highlightDates = ref<boolean>(false)
    const showButtonMenu = ref(false)
    const openedPanels = ref<string[]>([])
    const entryTypes = ref<IRegisterLinkedIndicator[]>([])

    const loading = ref(true)
    const availableRegisterContentHeight = ref(0)
    const searchTextInputLoggingDebounce = ref<number | null>(null)
    const selectedTitle = computed(() => store.state.title.selectedTitle)
    const selectedTitleNumber = computed(() => store.state.title.selectedTitleNumber)
    const bgDataLoaded = computed(() => selectedTitle.value?.record?.titleMetadata?.bgDataLoaded)
    const pendingRegisterIndicatorSelection = computed(() => store.state.title.pendingRegisterIndicatorSelection)
    const selectedEntryTypes = computed<ISimplifiedRegisterLinkedIndicator[]>(() => store.state?.title?.selectedEntryTypes)
    const bgProprietorshipData = computed(() => selectedTitle.value?.record?.bgProprietorshipData)
    const isTitleRegisterPurchased = computed(() => selectedTitle?.value?.officialCopiesAvailability?.results?.titleRegister?.documentId)


    const {
        searchText,
        setTerms,
    } = useNlpSearch({
        highlightDates,
        inputValue: searchTextInput,
    })

    const { registerEntryDocuments, registerEntries } = useSelectedTitle()

    onBeforeUnmount(() => {
        if (searchTextInputLoggingDebounce.value != null) {
            window.clearTimeout(searchTextInputLoggingDebounce.value)
        }
    })

    const toggleEntryType = (item: ISimplifiedRegisterLinkedIndicator) => {
        const selected = selectedEntryTypes.value.find(t => {
            return t.label === item.label
        })

        if (selected) {
            const index = selectedEntryTypes.value.indexOf(selected)
            // selectedEntryTypes without the selected type
            const newEntries = [ ...selectedEntryTypes.value.slice(0, index), ...selectedEntryTypes.value.slice(index + 1) ]
            store.commit(TITLE_MUTATE_ENTRY_TYPES_SELECTION, newEntries)
        } else {
            store.commit(TITLE_MUTATE_ENTRY_TYPES_SELECTION, [ ...selectedEntryTypes.value, item ])
        }
    }

    const populateRegisterInformation = (register: IRegister) => {
        // Register structures are the same, this function avoids code duplication when populating data.registers derived values.
        if (selectedTitle?.value.record != null) {
            let textMatch = false
            let filterMatch = false
            const registerTextMatches = []

            register.highlightText = []
            register.highlightEntryNumbers = []
            register.searchCount = 0
            register.showEntries = []
            register.show = true
            register.showAllEntries = false
            register.filterCounts = []
            register.expandPanel = true

            // Check if the register matches the search text
            if (!isNullOrEmpty(searchText.value) || !isNullOrEmpty(selectedEntryTypes.value)) {
                const processEntry = (entry: IRegisterEntry) => {
                    const entryTextMatches = []

                    // Determine text matches and subsequently whether or not to show an entry
                    if (!isNullOrEmpty(searchText.value)) {
                        searchText.value.forEach(text => {
                            const regExMatches = entry.displayString.match(new RegExp(text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) || []
                            entryTextMatches.push(...regExMatches)
                        })

                        // Determine entry number matches (and increment match counts)
                        searchText.value.forEach(text => {
                            if (text.toUpperCase() === entry.code.toUpperCase()) {
                                register.highlightEntryNumbers.push(text.toLowerCase())
                                textMatch = true
                                entryTextMatches.push(text.toLowerCase())
                            }
                        })
                    }

                    registerTextMatches.push(...entryTextMatches)
                    register.searchCount += entryTextMatches.length

                    // Determine filter matches
                    if (!isNullOrEmpty(selectedEntryTypes.value)) {
                        const selected = selectedEntryTypes.value.find((t: IRegisterLinkedIndicator) => {
                            // Get matching codes
                            const codes = t.codes.map(code => code.code)
                            const subcodes = t.codes.map(code => code.subCodes).flat()

                            return codes.includes(entry.code) || subcodes.includes(entry.code)
                        })

                        if (selected) {
                            filterMatch = true
                            register.showEntries.push(entry.code)
                            const filterCount = register.filterCounts.find(f => {
                                return f.type === selected
                            })
                            if (filterCount) {
                                filterCount.count++
                            } else {
                                register.filterCounts.push({
                                    type: selected,
                                    count: 1,
                                })
                            }
                        }
                    }

                    // Determine document matches
                    if (highlightDates.value && !isNullOrEmpty(registerEntryDocuments.value)) {
                        const documentsWithDates = registerEntryDocuments.value?.filter((document: IReferencedDocument) => {
                            return document.entryNumberField?.indexOf(entry.code) !== -1
                        }).length
                        register.searchCount += documentsWithDates
                    }

                    if (entryTextMatches.length > 0) {
                        register.showEntries.push(entry.code)
                    }
                }

                register.entries.forEach(entry => {
                    processEntry(entry)
                })

                textMatch = registerTextMatches.length > 0
                register.show = (textMatch || filterMatch) || register.searchCount > 0
            } else {
                register.showAllEntries = true
                register.show = true
            }

            // this.registers[registerName].searchCount = registerTextMatches.length;
            const uniqueTextMatches = [ ...new Set(registerTextMatches) ]
            register.highlightText.push(...uniqueTextMatches)
        }
    }


    const updateData = () => {
        props.registers.forEach(register => {
            populateRegisterInformation(register)
        })

        openedPanels.value = (props.registers || [])
            .map(register => !isNullOrEmpty(register.showEntries) ? register.prefix : null)
            .filter(register => register !== null)
    }

    const onSearchClear = () => {
        searchTextInput.value = null
        updateData()
    }

    const createRegisterData = () => {
        let registers = []
        if (!isNullOrEmpty(registerEntries.value)) {
            registers = registerEntries.value.map(register => {
                register.show = true
                register.searchCount = 0
                register.highlightText = []
                register.highlightEntryNumbers = []
                register.showEntries = []
                register.showAllEntries = false
                register.filterCounts = []
                return register
            })
        }
        return []
    }

    const detectTermsOfInterest = () => {
        let combinedText = ''
        props.registers.forEach(register => {
            const combineText = text => {
                combinedText = combinedText + ' ' + text
            }

            // Combine text from the main registers
            register.entries.forEach(entry => {
                combineText(entry.displayString)
            })
        })

        setTerms(combinedText)
    }

    const recalculateAvailableContentHeight = () => {
        if (document.getElementById('titlePanelContent')) {
            const parentAvailableHeight = Number(document.getElementById('titlePanelContent')
                .style
                .maxHeight
                .replace('px', ''))
            let availableHeight = parentAvailableHeight - 135

            const filterElement = document.getElementById('registerSelectedEntryTypes')
            if (filterElement != null) {
                availableHeight = availableHeight - filterElement.clientHeight
            }
            availableRegisterContentHeight.value = availableHeight
        }
    }

    const populateEntryTypes = () => {
        entryTypes.value = []
        store.commit(TITLE_MUTATE_ENTRY_TYPES_SELECTION, [])
        if (selectedTitle?.value.record?.bgProprietorshipData?.linkedIndicators != null) {
            entryTypes.value = selectedTitle.value.record.bgProprietorshipData.linkedIndicators.filter(indicator => {
                return indicator.codes.length > 0
            })

            entryTypes.value.sort((a, b) => {
                const textA = a.label.toUpperCase()
                const textB = b.label.toUpperCase()
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
            })
        }
    }

    const simplifiedEntryTypes = computed<ISimplifiedRegisterLinkedIndicator[]>(() => {
        return entryTypes.value.map(({ label, colour, codes }) => ({
            label,
            colour,
            codes: codes.reduce((acc: string[], code) => [ ...acc, code.code, ...code.subCodes ], []),
            selected: false,
        }))
    })

    watch(() => bgDataLoaded.value, (val) => {
        if (!val) {
            return
        }

        // Wait for the register data to be available before processing
        nextTick(() => {
            loading.value = true
            try {
                createRegisterData()
                populateEntryTypes()
                detectTermsOfInterest()
                updateData()
                recalculateAvailableContentHeight()
            } finally {
                loading.value = false
            }
        })
    }, {
        immediate: true,
    })

    watch(() => bgProprietorshipData.value, (val) => {
        loading.value = val?.registers
    }, {
        immediate: true,
        deep: true,
    })

    watch(() => pendingRegisterIndicatorSelection.value, (val) => {
        // a request has been made to select an indicator (as a filter) within the title panel register tab
        if (val != null) {
            // Update existing selection
            store.commit(TITLE_MUTATE_ENTRY_TYPES_SELECTION, [ val ])

            // Done, remove pending selection
            store.commit(TITLE_MUTATE_REGISTER_INDICATOR_SELECTION, null)
        }
    }, {
        immediate: true,
    })

    watch(() => searchTextInput.value, (val) => {
        if (val != null) {
            if (val.trim().length > 1) {
                // Log a search event if required
                if (searchTextInputLoggingDebounce.value != null) {
                    window.clearTimeout(searchTextInputLoggingDebounce.value)
                }
                searchTextInputLoggingDebounce.value = window.setTimeout(() => {
                    store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
                        type: 'Register Search',
                        metadata: {
                            text: searchTextInput.value,
                            titleNumber: selectedTitleNumber.value,
                        },
                    })
                }, 1500)
            }
        }

        updateData()
    })

    watch(() => highlightDates.value, () => {
        updateData()
    })

    watch(() => selectedEntryTypes.value, () => {
        window.setTimeout(() => {
            recalculateAvailableContentHeight()
        }, 10)
        updateData()
    })
</script>

<style lang="scss" scoped>
    @import './register-card';
</style>
