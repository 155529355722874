<template>
    <div :class="{ 'matter-has-titles': matterHasTitles, 'top-nav-visible': isTopNavVisible, }"
         class="matter-side-panel-titles">
        <!-- Title selection options - NEW -->
        <bulk-options-panel v-if="showBulkActions"
                            :anchor-element-selector="'#matterTitles'"
                            :anchor-offset="[96, 0, 0, 0]"
                            :available-export-options="exportOptions"
                            :available-groups="groups"
                            :title-selection="selectedFilteredTitles"
                            @dismiss="onBulkOptionsDismiss"
                            @add-to-new-group="createGroupClicked"
                            @add-to-group="onAddToGroup"
                            @show-boundaries="onShowBoundaries"
                            @hide-boundaries="onHideBoundaries"
                            @change-style="onChangeStyle"
                            @purchase-official-copies="onPurchaseOfficialCopies"
                            @export-pending-applications="onExportPendingApplications"
                            @remove-from-group="onRemoveFromGroup"
                            @remove-from-matter="onRemoveFromMatter"
                            @view-all-templates="onViewAllTemplates"
                            @export-as="onExportAs"
                            @export-title-boundaries="onExportTitleBoundaries"
                            @export-title-analysis-report="onExportTitleAnalysisReport" />

        <title-organiser-panel v-if="showTitleOrganiser"
                               :anchor-element-selector="'#matterTitles'"
                               style="top:0"
                               @dismiss="setTitleOrganiserEnabled(false)" />

        <div v-show="isTitlesListCollapsed"
             class="click-area"
             @click="showTitlesList" />

        <v-tabs v-show="!isTitlesListCollapsed"
                v-model="activePane"
                color="transparent"
                direction="vertical"
                slider-color="primary" />
        <!-- not actually tabs -->
        <v-window v-model="activePane">
            <v-window-item :key="0">
                <template v-if="isLoading">
                    <matter-titles-loading-skeleton />
                </template>
                <template v-else-if="matterHasTitles || isUploadingDocuments">
                    <matter-side-panel-filter-item v-if="!titleOrganiserEnabled"
                                                   v-model="filterTitlesText"
                                                   :selected-title-count="isWalkthrough ? walkthroughIncludedFilteredTitles.length : selectedFilteredTitles.length"
                                                   :show-filter-item-controls="!isUploadingDocuments"
                                                   :title-count="filteredTitles.length"
                                                   @toggle-filter="onFilterToggle"
                                                   @select:all="isWalkthrough ? addAllTitlesToWalkthrough() : selectAll()"
                                                   @select:none="isWalkthrough ? removeAllTitlesFromWalkthrough() : selectNone()"
                                                   @add-titles="addTitlesClicked"
                                                   @create-group="createGroupClicked" />

                    <matter-uploading-docs v-if="isUploadingDocuments && !matterHasTitles" />
                    <div v-else
                         class="layout column">
                        <filter-no-results-prompt v-if="filterTitlesText && filteredTitles.length === 0"
                                                  :show-next-steps="!isWalkthrough"
                                                  :title-number="filterTitlesText"
                                                  @click="handleEmptySearch" />
                        <div :class="{
                                 'is-client-share-view': isSharedLinkView,
                                 'is-client-share-view-filter': isSharedLinkView && filterIsShown,
                                 'is-walkthrough': isWalkthrough,
                                 'is-walkthrough-filter': isWalkthrough && filterIsShown,
                                 'is-title-organiser': titleOrganiserEnabled,
                                 'filter': filterIsShown,
                                 'hint' : filterTitlesText,
                             }"
                             class="layout column matter-side-panel-titles__content">
                            <div>
                                <draggable v-if="visibleGroups"
                                           v-model="visibleGroups"
                                           :component-data="{ name:'fade', type: 'transition-group' }"
                                           :disabled="disableDragging"
                                           group="groups"
                                           item-key="id"
                                           v-bind="dragOptions"
                                           @end="onEndDragGroup">
                                    <template #item="{element}">
                                        <matter-side-panel-group-item :data-groupid="element.id"
                                                                      :data-sortorder="element.sortOrder"
                                                                      :expanded-title-number="expandedTitleNumber"
                                                                      :filtered-titles="getFilteredTitlesForGroup(element)"
                                                                      :group="element"
                                                                      class="matter-side-panel-titles__group-item"
                                                                      @title-click="$emit('title-click', $event)"
                                                                      @clear-titles="clearTitles"
                                                                      @on-expand="onExpand"
                                                                      @on-collapse="onCollapse"
                                                                      @add-title-to-group="addTitleToGroup" />
                                    </template>
                                </draggable>
                            </div>
                            <div class="matter-side-panel-titles__content-wrapper">
                                <draggable v-model="filteredTitlesWithoutGroups"
                                           :class="{'matter-side-panel-titles__content-wrapper__titles': filteredTitlesWithoutGroups.length}"
                                           :component-data="{name:'fade', type: 'transtion-group'}"
                                           :disabled="disableDragging"
                                           class="dragArea"
                                           group="groups"
                                           item-key="id"
                                           style="min-height: 20px"
                                           v-bind="dragOptions"
                                           @change="onDropTitleIntoUnGrouped"
                                           @end="onEndDragTitle">
                                    <template #item="{element, i}">
                                        <matter-side-panel-title-item :key="element.titleNumber"
                                                                      :data-sortorder="element.sortOrder"
                                                                      :data-titlenumber="element.titleNumber"
                                                                      :has-light-background="i % 2 === 1"
                                                                      :is-expanded="expandedTitleNumber === element.titleNumber"
                                                                      :title="element"
                                                                      @clear-titles="clearTitles"
                                                                      @on-expand="onExpand"
                                                                      @on-collapse="onCollapse"
                                                                      @title-click="$emit('title-click', $event)"
                                                                      @add-title-to-group="addTitleToGroup" />
                                    </template>
                                </draggable>
                            </div>
                        </div>

                        <matter-side-panel-title-actions v-if="!titleOrganiserEnabled && !isTitlesListCollapsed" />
                    </div>
                </template>
                <matter-add-titles-prompt v-else
                                          :show-add-titles-button="showAddTitlesButton"
                                          @add-titles="addTitlesClicked" />
            </v-window-item>
            <v-window-item v-if="!isSharedLinkView"
                           :key="1"
                           class="matter-side-panel-titles__adding">
                <template v-if="activePane === 1">
                    <matter-add-titles />
                </template>
            </v-window-item>
        </v-window>

        <exporting-status-modal v-model="isExportStatusDialogVisible"
                                :error="hasError"
                                :exporting="isExportingData"
                                data-test-attribute="export-button-menu-export-status-dialog"
                                @request-retry="onExportAs" />
    </div>
</template>

<script lang="ts">
    import debounce from 'lodash.debounce'
    import {
        provide,
        ref,
    } from 'vue'
    import draggable from 'vuedraggable'
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import MatterTitlesLoadingSkeleton from '@/components/loading-skeletons/matter-titles-loading-skeleton.vue'
    import BulkOptionsPanel from '@/components/matter/bulk-options-panel.vue'
    import MatterAddTitlesPrompt from '@/components/matter/titles/add-titles-prompt.vue'
    import FilterNoResultsPrompt from '@/components/matter/titles/no-results-prompt.vue'
    import ExportingStatusModal from '@/components/reporting/exporting-status-modal-with-teleport.vue'
    import TitleOrganiserPanel from '@/components/title-organiser/title-organiser-panel.vue'
    import { useMapTopNav } from '@/composables/use-map-top-nav'
    import { DATA_PROVIDER } from '@/consts/data-provider'
    import { Route } from '@/enums/route.enum'
    import FlagsMixin from '@/feature-flags/feature-flags-mixin'
    import ExportOptionsMixin from '@/mixins/export-options.mixin'
    import WindowResize from '@/mixins/page-resize.mixin'
    import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'
    import { MAP_UPDATE_SIZE } from '@/store/modules/map/types'
    import {
        applyNewSortOrderToItems,
        sortIntervalIncrement,
    } from '@/store/modules/matter/group.utils'
    import {
        MATTER_ADD_TITLES_TO_GROUP,
        MATTER_EXPORT_TITLE_ANALYSIS_REPORT,
        MATTER_GET_MATTER_CONTENTS,
        MATTER_MUTATE_CREATE_GROUP_PROMPT,
        MATTER_MUTATE_CREATE_GROUP_TITLES,
        MATTER_MUTATE_PENDING_REQUEST_TO_SHOW_TITLE_LIST,
        MATTER_MUTATE_SHOW_EXPORT_DIALOG,
        MATTER_MUTATE_STYLE_PROMPT,
        MATTER_MUTATE_TITLE_ORGANISER_ENABLED,
        MATTER_REMOVE_TITLES_FROM_GROUP,
        MATTER_SHOW_BOUNDARIES,
        MATTER_UPDATE_BOUNDARY_LAYER,
        MATTER_UPDATE_GROUP_SORT,
        MATTER_UPDATE_TITLE_SORT,
    } from '@/store/modules/matter/types'
    import { NPS_EXPORT_TITLE_BOUNDARIES } from '@/store/modules/nps/types'
    import {
        SEARCH_ALL,
        SEARCH_MUTATE_IS_ACTIVE,
        SEARCH_MUTATE_IS_RESULTS_PANEL_OPEN,
        SEARCH_MUTATE_SEARCH_TEXT,
    } from '@/store/modules/search/types'
    import { TitlePanels } from '@/store/modules/title-panels'
    import {
        TITLE_CLEAR,
        TITLE_MUTATE_ACTIVE_TITLE_PANEL,
        TITLE_MUTATE_EXPANDED_TITLE_NUMBER,
        TITLE_MUTATE_SELECTED_SUMMARY_TITLE,
    } from '@/store/modules/titles/types'
    import {
        WALKTHROUGH_GET_TITLE_NUMBER_IS_INCLUDED_IN_SELECTED_PAGE,
        WALKTHROUGH_MUTATE_MATTER_TITLE_INCLUSION_IN_SELECTED_PAGE,
        WALKTHROUGH_UPDATE_SELECTED_PAGE_LAYER,
    } from '@/store/modules/walkthrough/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        LOGGING_LOG_FEATURE_USAGE,
        USER_MUTATE_SHOW_MAP_TITLES_NAV,
    } from '@/store/mutation-types'
    import {
        dynamicSort,
        isNullOrEmpty,
    } from '@/utils/array-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    import MatterAddTitles from './matter-side-panel-add-titles/matter-side-panel-add-titles.vue'
    import MatterSidePanelFilterItem from './matter-side-panel-filter-item.vue'
    import MatterGroupItem from './matter-side-panel-group-item.vue'
    import MatterTitleActions from './matter-side-panel-title-actions.vue'
    import MatterTitleItem from './matter-side-panel-title-item.vue'
    import MatterUploadingDocs from './matter-side-panel-uploading-docs.vue'

    export default {
        name: 'MatterSidePanelTitles',

        components: {
            TitleOrganiserPanel,
            BulkOptionsPanel,
            draggable,
            ExportingStatusModal,
            FilterNoResultsPrompt,
            MatterSidePanelGroupItem: MatterGroupItem,
            MatterSidePanelTitleActions: MatterTitleActions,
            MatterSidePanelTitleItem: MatterTitleItem,
            MatterAddTitles,
            MatterTitlesLoadingSkeleton,
            MatterAddTitlesPrompt,
            MatterSidePanelFilterItem,
            MatterUploadingDocs,
        },
        mixins: [
            ExportOptionsMixin,
            FlagsMixin,
            WindowResize,
        ],

        emits: [
            'clear-titles',
            'show-bulk-actions',
            'title-click',
        ],
        setup() {
            const { isTopNavVisible } = useMapTopNav()
            const filterTitlesText = ref('')
            provide('FILTER_PROVIDER', filterTitlesText)
            return {
                filterTitlesText,
                isTopNavVisible,
            }
        },

        data() {
            return {
                filterInputLabel: 'Filter this matter',
                filterHasFocus: false,
                filterIsShown: false,
                dragOptions: {
                    animation: 100,
                    ghostClass: 'ghost',
                },
                isRenderingGroups: true,
                filteredTitles: [],
                debouncedFilter: debounce(() => {
                    this.filterTitles()
                }, 500),
                loadingFilter: false,
                currentTemplate: null,
            }
        },

        computed: {
            ...mapState({
                activeTitlePanel: state => state.title.activeTitlePanel,
                currentMatter: state => state.matter.currentMatter,
                isWalkthrough: state => state.walkthrough.enabled,
                isTitlesListCollapsed: state => !state.user.showMapTitlesNav,
                pendingRequestToShowTitleList: state => state.matter.pendingRequestToShowTitleList,
                isStylePromptShown: state => Boolean(state.matter.styleTitles?.length),
                removeFromMatterDialog: state => state.matter.prompts.removeFromMatter,
                titleOrganiserEnabled: state => state.matter.titleOrganiser.enable,
                titleOrganiserRevisionCounter: state => state.matter.titleOrganiser.contentRevisionCounter,
                loadingCurrentMatter: state => state.matter.loadingCurrentMatter,
                loadingTitleOrganiser: state => state.matter.titleOrganiser.loading,
                expandedTitleNumber: state => state.title.expandedTitleNumber,
                isUploadingDocuments: state => Boolean(state.documents.library.uploadPoller),
            }),

            ...mapGetters({
                isTitleIncludedInCurrentPage: WALKTHROUGH_GET_TITLE_NUMBER_IS_INCLUDED_IN_SELECTED_PAGE,
                matterContents: MATTER_GET_MATTER_CONTENTS,
            }),

            ...mapGetters('linkShareClient', {
                isSharedLinkView: LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW,
            }),

            activePane: {
                get() {
                    return this.activeTitlePanel
                },
                set(newPanel) {
                    this.mutateActiveTitlePanel(newPanel)
                },
            },

            filteredTitlesWithinGroups() {
                return this.groups.map(this.getFilteredTitlesForGroup).flat()
            },

            filteredTitlesWithoutGroups: {
                get() {
                    this.isRenderingGroups = false
                    return this.filteredTitles
                        .filter(title => title.matterGroupId == null)
                        .sort((a, b) => a.sortOrder - b.sortOrder)
                },
                set() {
                    // Has to be writable as used as the v-model. We don't use this for anything though.
                },
            },

            selectedFilteredTitles() {
                return this.filteredTitles.filter(title => title.selected === true)
            },

            walkthroughIncludedFilteredTitles() {
                return this.filteredTitles.filter(title => this.isTitleIncludedInCurrentPage(title.titleNumber))
            },

            groups() {
                return this.matterContents.groups
                    .sort(dynamicSort('sortOrder'))
            },

            visibleGroups: {
                get() {
                    // Hide empty groups if displaying filter results
                    if (this.filterTitlesText) {
                        const nonEmptyGroupIds = new Set(this.filteredTitles.map(title => title.matterGroupId))
                        return this.groups.filter(group => nonEmptyGroupIds.has(group.id))
                    }
                    return this.groups
                },
                set() {
                    // Has to be writable as used as the v-model. We don't use this for anything though.
                },
            },

            filteredGroups() {
                let result = this.groups

                // Filter visible groups on search text
                if (this.filterTitlesText) {
                    const search = this.filterTitlesText
                        .toLowerCase()
                        .replace(/\W/g, ' ')

                    result = result.filter(group => group.name.toLowerCase().includes(search))
                }

                return result
            },

            showAddTitlesButton() {
                return (!this.isSharedLinkView && !this.filterHasFocus && !this.isWalkthrough)
            },

            matterHasTitles() {
                return !isNullOrEmpty(this.matterContents.titles)
            },

            showBulkActions() {
                return Boolean(!this.isTitlesListCollapsed && // the list is not collapsed
                    !this.isStylePromptShown && // style prompt is not shown
                    this.selectedFilteredTitles.length > 0 && // there are selected titles
                    this.activePane === TitlePanels.LIST && // the title list is visible
                    (this.$route.name === Route.MatterMapTitle || // one of the applicable routes is active
                        this.$route.name === Route.MatterTitles ||
                        this.$route.name === Route.MatterMap)) &&
                    !this.titleOrganiserEnabled // title organiser is not enabled
            },

            disableDragging() {
                return this.isSharedLinkView || this.titleOrganiserEnabled
            },

            showTitleOrganiser() {
                return this.titleOrganiserEnabled &&
                    (this.$route.name === Route.MatterMapTitle || // one of the applicable routes is active
                        this.$route.name === Route.MatterTitles ||
                        this.$route.name === Route.MatterMap) &&
                    !this.isTitlesListCollapsed // don't show title organiser if the title list is collapsed
            },

            isLoading() {
                return this.isRenderingGroups || this.loadingCurrentMatter || this.loadingTitleOrganiser
            },
        },

        watch: {
            pendingRequestToShowTitleList(val) {
                if (val === true) {
                    this.activePane = TitlePanels.LIST
                    this.mutatePendingRequestToShowTitleList(false)
                }
            },

            'currentMatter.selectedTitles.length'() {
                this.debouncedFilter()
            },

            'currentMatter.id'() {
                this.debouncedFilter()
                this.setTitleOrganiserEnabled(false)
            },

            filterTitlesText(val) {
                this.loadingFilter = true
                this.debouncedFilter()
            },

            filterHasFocus(val) {
                if (val) {
                    this.filterInputLabel = 'e.g. Title number, tenure, address or name'
                } else {
                    this.filterInputLabel = 'Filter this matter'
                }
            },

            showBulkActions(value) {
                this.$emit('show-bulk-actions', value)
            },

            async titleOrganiserRevisionCounter() {
                this.filterTitles()
            },

            async titleOrganiserEnabled(val) {
                if (val === false) {
                    await this.updateBoundaries()
                } else {
                    this.filterTitlesText = null
                    this.filterIsShown = false
                }
            },

            filteredTitles() {
                // needed for 1st load as expanded title number will be set from url, but the titles may not yet be loaded.
                this.scrollIntoView()
            },

            expandedTitleNumber() {
                this.scrollIntoView()
            },
        },

        activated() {
            this.scrollIntoView()
        },

        mounted() {
            this.debouncedFilter()
        },

        unmounted() {
            this.setSummaryTitleDetails(null)
        },

        methods: {
            ...mapMutations({
                setShowMapTitlesNav: USER_MUTATE_SHOW_MAP_TITLES_NAV,
                mutateActiveTitlePanel: TITLE_MUTATE_ACTIVE_TITLE_PANEL,
                mutatePendingRequestToShowTitleList: MATTER_MUTATE_PENDING_REQUEST_TO_SHOW_TITLE_LIST,
                toggleTitleInclusionInCurrentPage: WALKTHROUGH_MUTATE_MATTER_TITLE_INCLUSION_IN_SELECTED_PAGE,
                setSummaryTitleDetails: TITLE_MUTATE_SELECTED_SUMMARY_TITLE,
                setCreateGroupPrompt: MATTER_MUTATE_CREATE_GROUP_PROMPT,
                setCreateGroupTitles: MATTER_MUTATE_CREATE_GROUP_TITLES,
                styleTitles: MATTER_MUTATE_STYLE_PROMPT,
                showExportDialog: MATTER_MUTATE_SHOW_EXPORT_DIALOG,
                setTitleOrganiserEnabled: MATTER_MUTATE_TITLE_ORGANISER_ENABLED,
                setExpandedTitleNumber: TITLE_MUTATE_EXPANDED_TITLE_NUMBER,
            }),

            ...mapMutations('search', {
                mutateSearchVisibility: SEARCH_MUTATE_IS_ACTIVE,
                mutateSearchText: SEARCH_MUTATE_SEARCH_TEXT,
                toggleResultsPanel: SEARCH_MUTATE_IS_RESULTS_PANEL_OPEN,
            }),

            ...mapActions({
                updateMapSize: MAP_UPDATE_SIZE,
                addTitlesToGroup: MATTER_ADD_TITLES_TO_GROUP,
                updateGroupSort: MATTER_UPDATE_GROUP_SORT,
                updateTitleSort: MATTER_UPDATE_TITLE_SORT,
                removeTitlesFromGroup: MATTER_REMOVE_TITLES_FROM_GROUP,
                titleClear: TITLE_CLEAR,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
                showBoundaries: MATTER_SHOW_BOUNDARIES,
                updateBoundaries: MATTER_UPDATE_BOUNDARY_LAYER,
                logFeatureUsage: LOGGING_LOG_FEATURE_USAGE,
                exportTitleBoundaries: NPS_EXPORT_TITLE_BOUNDARIES,
                exportTitleAnalysisReport: MATTER_EXPORT_TITLE_ANALYSIS_REPORT,
                updateWalkthrough: WALKTHROUGH_UPDATE_SELECTED_PAGE_LAYER,
            }),

            ...mapActions('search', {
                search: SEARCH_ALL,
            }),

            async onDropTitleIntoUnGrouped(evt) {
                const evtAdded = evt.added
                if (evtAdded !== undefined) {
                    // Find the corresponding title
                    const title = this.currentMatter.selectedTitles.find(t => t.titleNumber === evtAdded.element.titleNumber)

                    // Remove the title from the group
                    await this.removeTitlesFromGroup({
                        matterId: this.currentMatter.id,
                        titles: [ title ],
                    })

                    const updatedSortItems = applyNewSortOrderToItems(this.filteredTitlesWithoutGroups, title, evtAdded.newIndex)
                    await this.updateTitleSort(updatedSortItems.map(x => {
                        return {
                            titleNumber: x.titleNumber,
                            sortOrder: x.sortOrder,
                        }
                    }))
                }
            },

            clearTitles() {
                this.$emit('clear-titles')
            },

            async onEndDragGroup(evt) {
                const group = this.groups.find(group => group.id === parseInt(evt.item.dataset.groupid))
                const updatedGroups = applyNewSortOrderToItems(this.visibleGroups, group, evt.newIndex)
                await this.updateGroupSort(updatedGroups)
            },

            async onEndDragTitle(evt: any) {
                // Find the corresponding title
                const title = this.currentMatter.selectedTitles.find(t => t.titleNumber == evt.item.dataset.titlenumber)

                // Determine whether or not any action is required (title may be dropped into the same place)
                if (evt.newIndex === evt.oldIndex) {
                    return
                }

                // If the title has been moved to another group, handle the sorting there, not here.
                if (title.matterGroupId !== null) {
                    return
                }
                const updatedSortItems = applyNewSortOrderToItems(this.filteredTitlesWithoutGroups, title, evt.newIndex)
                await this.updateTitleSort(updatedSortItems.map(x => {
                    return {
                        titleNumber: x.titleNumber,
                        sortOrder: x.sortOrder,
                    }
                }))
            },

            async addTitleToGroup(title, groupId) {
                // Add title to the end of the group.
                const titlesInTargetGroup = this.currentMatter.selectedTitles.filter(t => {
                    return t.matterGroupId === groupId
                })
                const startingIndex = titlesInTargetGroup.length > 0
                    ? Math.max(...titlesInTargetGroup.map(x => x.sortOrder))
                    : 0
                await this.updateTitleSort([ {
                    titleNumber: title.titleNumber,
                    sortOrder: (startingIndex + sortIntervalIncrement),
                } ])

                await this.addTitlesToGroup({
                    matterGroupId: groupId,
                    titles: [ title ],
                })
            },

            async onExpand(titleNumber: string) {
                await this.logHeapEvent({
                    type: 'MAT-TITLE-LIST - Expand title list item',
                    metadata: {
                        titleNumber,
                    },
                })
            },

            async onCollapse(titleNumber: string) {
                await this.setExpandedTitleNumber(null)
                await this.logHeapEvent({
                    type: 'MAT-TITLE-LIST - Collapse title list item',
                    metadata: {
                        titleNumber,
                    },
                })
            },

            addAllTitlesToWalkthrough() {
                this.filteredTitles
                    .filter(title => !this.isTitleIncludedInCurrentPage(title.titleNumber))
                    .forEach(title => this.toggleTitleInclusionInCurrentPage(title))
                this.updateWalkthrough()
            },

            removeAllTitlesFromWalkthrough() {
                this.filteredTitles
                    .filter(title => this.isTitleIncludedInCurrentPage(title.titleNumber))
                    .forEach(title => this.toggleTitleInclusionInCurrentPage(title))
            },

            getFilteredTitlesForGroup(group) {
                return this.filteredTitles
                    .filter(title => group.id === title.matterGroupId)
                    .sort((a, b) => a.sortOrder - b.sortOrder)
            },

            async showTitlesList() {
                this.setShowMapTitlesNav(true)
                await this.updateMapSize()
            },

            async addTitlesClicked() {
                await this.titleClear()
                this.setShowMapTitlesNav(true)
                this.mutateActiveTitlePanel(TitlePanels.ADD)
            },

            createGroupClicked() {
                this.setCreateGroupPrompt({ show: true })
                this.setCreateGroupTitles(this.selectedFilteredTitles)
            },

            filterTitles() {
                if (isNullOrWhitespace(this.filterTitlesText)) {
                    this.filteredTitles = this.matterContents.titles.sort((a, b) => a.sortOrder - b.sortOrder)
                } else {
                    const search = this.filterTitlesText
                        .toLowerCase()
                        .replace(/\W/g, ' ')
                    this.filteredTitles = this.matterContents.titles
                        .filter(title => {
                            if (title.titleNumber?.toLowerCase().includes(search)) {
                                return true
                            }
                            if (title.label?.toLowerCase().includes(search)) {
                                return true
                            }
                            if (title.addresses?.join('').toLowerCase().includes(search)) {
                                return true
                            }
                            if (this.filteredGroups.map(group => group.id).includes(title.matterGroupId)) {
                                return true
                            }
                            if (search === 'freehold' && title.tenure?.includes('F')) {
                                return true
                            }
                            if (search === 'leasehold' && title.tenure?.includes('L')) {
                                return true
                            }
                            return false
                        })
                }
                this.loadingFilter = false
                this.isRenderingGroups = false
            },

            selectAll() {
                this.filteredTitles.forEach(title => {
                    title.selected = true
                })
            },

            selectNone() {
                this.filteredTitles.forEach(title => {
                    title.selected = false
                })
            },

            async handleEmptySearch() {
                await this.logHeapEvent({
                    type: 'MAT-TITLE-LIST - Filter no results, search for button clicked',
                })

                this.mutateSearchVisibility(true)
                this.mutateSearchText(this.filterTitlesText)
                await this.search(this.filterTitlesText)
                this.toggleResultsPanel(true)
            },

            onBulkOptionsDismiss() {
                this.selectNone()
            },

            async onShowBoundaries() {
                const request = {
                    titles: this.selectedFilteredTitles,
                    show: true,
                }
                await this.showBoundaries(request)
            },

            async onHideBoundaries() {
                const request = {
                    titles: this.selectedFilteredTitles,
                    show: false,
                }
                await this.showBoundaries(request)
            },

            async onChangeStyle() {
                await this.showBoundaries({
                    titles: this.selectedFilteredTitles,
                    show: true,
                })
                this.styleTitles({
                    titles: this.selectedFilteredTitles,
                    updateMainMap: true,
                })
            },

            onPurchaseOfficialCopies() {
                // Replace with new dialog - this is the approach used in matter-side-panel-title-actions
                this.showExportDialog({
                    titles: this.selectedFilteredTitles,
                    purchase: true,
                })
            },

            onExportPendingApplications() {
                // Replace with new dialog - this is the approach used in matter-side-panel-title-actions
                this.showExportDialog({
                    titles: this.selectedFilteredTitles,
                })
            },

            async onAddToGroup(group) {
                await this.addTitlesToGroup({
                    matterGroupId: group.id,
                    titles: this.selectedFilteredTitles,
                })
            },

            async onRemoveFromGroup() {
                await this.removeTitlesFromGroup({
                    matterId: this.currentMatter.id,
                    titles: this.selectedFilteredTitles,
                })
            },

            onRemoveFromMatter() {
                this.removeFromMatterDialog.titles = this.selectedFilteredTitles
                this.removeFromMatterDialog.show = true
            },

            onFilterToggle(value) {
                this.filterIsShown = value
            },

            async onExportAs(chosenTemplate) {
                // Set the current template so that retry can use it if clicked.
                if (chosenTemplate) {
                    this.currentTemplate = chosenTemplate
                }

                const selectedTitleNumbersWithinGroups = this.filteredTitlesWithinGroups.filter(title => title.selected && [DATA_PROVIDER.HMLR].includes(title.dataProvider)).map(title => title.titleNumber)
                const selectedTitleNumbersWithoutGroups = this.filteredTitlesWithoutGroups.filter(title => title.selected && [DATA_PROVIDER.HMLR].includes(title.dataProvider)).map(title => title.titleNumber)

                const sortedSelectedTitleNumbers = [
                    ...selectedTitleNumbersWithinGroups,
                    ...selectedTitleNumbersWithoutGroups,
                ]

                // Call the handler in the export mixin
                await this.linkClickHandler(
                    sortedSelectedTitleNumbers,
                    this.currentMatter.id,
                    'TITLE_LIST_BULK_ACTIONS',
                    this.currentTemplate,
                )
            },

            async onViewAllTemplates() {
                const selectedTitleNumbers = this.selectedFilteredTitles.map(t => t.titleNumber)
                await this.viewAllTemplatesClickHandler(
                    selectedTitleNumbers,
                    this.currentMatter.id,
                    'TITLE_LIST_BULK_ACTIONS',
                )
            },

            async onExportTitleBoundaries() {
                await this.exportTitleBoundaries(this.selectedFilteredTitles)
                this.selectNone()
                await this.logFeatureUsage({
                    type: 'title-boundary-export',
                    description: this.selectedFilteredTitles.length,
                })
            },

            async onExportTitleAnalysisReport() {
                await this.logHeapEvent({
                    type: 'Title Analysis Report',
                    metadata: {
                        matterId: this.matterId,
                        titleNumberCount: this.selectedFilteredTitles.length,
                        location: "TITLE_LIST_BULK_ACTIONS",
                    },
                })
                await this.exportTitleAnalysisReport(this.selectedFilteredTitles.map(t => t.titleNumber))
                this.selectNone()
            },

            scrollIntoView(): void {
                // NOTE: this is called twice, once to force the render of the expanded card
                // then again to scroll to the component fully into view
                this.doScrollIntoView(250)
                this.doScrollIntoView(1000)
            },

            doScrollIntoView(wait: number): void {
                if (!isNullOrWhitespace(this.expandedTitleNumber)) {
                    setTimeout(() => {
                        const elem = document.querySelector(`[data-titlenumber="${ this.expandedTitleNumber }"]`)
                        if (elem !== null) {
                            elem.scrollIntoView({ behavior: "instant", block: "center" })
                        }
                    }, wait)
                }
            },
        },
    }
</script>
<style lang="scss">
    @import 'matter-side-panel-titles';
</style>
