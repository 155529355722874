<template>
    <div :class="{
        'notification-types-container': isFlexContainer
    }">
        <div v-for="row in rows"
             :key="row.data.notificationSubType">
            <component :is="row.component"
                       v-model:update-count="updateCount"
                       :item="row.data"
                       :title-number="titleNumber"
                       :company-numbers="companyNumbers"
                       :notification-id="notificationId"
                       :showing-details-panel="showingDetailsPanel"
                       @area-difference="handleBoundaryArea" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        markRaw,
        ref,
        watch,
    } from "vue"

    import { IAssetMonitoringNotificationDetail } from "@/components/asset-monitoring/asset-monitoring-notification.interface"
    import CompaniesHouseItem from "@/components/asset-monitoring/grid/notification-type-items/companies-house-item.vue"
    import { NotificationType } from "@/components/asset-monitoring/notification-type.enum"

    const props = defineProps<{
        notificationDetails: Array<IAssetMonitoringNotificationDetail>,
        notificationType: NotificationType,
        titleNumber: string,
        companyNumbers?: string[],
        notificationId: string,
        showingDetailsPanel: boolean,
    }>()

    const emit = defineEmits<{
        (e: 'area-difference', value: number),
    }>()

    const rows = ref([])
    const isFlexContainer = ref(false)
    const updateCount = defineModel('update-count')

    const getNotificationTypeComponent = () => {
        switch (props.notificationType) {
            case NotificationType.CompaniesHouseChanged:
                return markRaw(CompaniesHouseItem)
        }
    }

    watch(() => props.notificationDetails, () => {
        rows.value = props.notificationDetails?.map((item) => {
            return {
                component: getNotificationTypeComponent(),
                data: item,
            }
        })
    }, { immediate: true })

    const handleBoundaryArea = (area: number) => {
        emit('area-difference', area)
    }
</script>
