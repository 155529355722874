<script lang="ts"
        setup>
    import { computed } from 'vue'

    import OwProgress from '@/components/core/ow-progress.vue'
    import OwSnackbar from '@/components/core/ow-snackbar.vue'
    import { useModelWrapper } from '@/composables/use-model-wrapper'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const emit = defineEmits<{
        (e: 'update:modelValue', payload: boolean): void,
    }>()

    const props = defineProps<{
        // eslint-disable-next-line vue/no-unused-properties
        modelValue: boolean,
        isInProgress?: boolean,
        isSuccess?: boolean,
        isError?: boolean,
        isWarning?: boolean,
        heading?: string,
        message: string,
        timeoutInMilliseconds: number
    }>()

    const isSnackbarVisible = useModelWrapper(props, emit, 'modelValue')

    const isMultiLine = computed(() =>
        !isNullOrWhitespace(props.heading) &&
        !isNullOrWhitespace(props.message))
</script>

<template>
    <ow-snackbar v-model="isSnackbarVisible"
                 :timeout="props.timeoutInMilliseconds"
                 :multi-line="isMultiLine">
        <section class="toaster__wrapper--header">
            <ow-progress v-if="isInProgress" />
            <v-icon v-if="isSuccess"
                    class="toaster__icon--success">
                $success-circle
            </v-icon>
            <v-icon v-else-if="isError"
                    class="toaster__icon--error">
                $error
            </v-icon>
            <v-icon v-else-if="isWarning"
                    class="toaster__icon--warning">
                $warning
            </v-icon>

            <h3 v-if="!isNullOrWhitespace(props.heading)">
                {{ heading }}
            </h3>
            <p v-else-if="!isNullOrWhitespace(props.message) && !isMultiLine"
               v-dompurify-html="message"
               class="toaster__wrapper--message body-regular" />
        </section>
        <p v-if="!isNullOrWhitespace(props.message) && isMultiLine"
           v-dompurify-html="message"
           :class="{'is-multi-line': isMultiLine }"
           class="toaster__wrapper--message body-regular" />
    </ow-snackbar>
</template>

<style lang="scss">
    @import './toaster.scss';
</style>
