<template>
    <div class="ow-dropdown-filter">
        <v-menu v-model="modelValue"
                open-on-click
                :open-on-focus="false"
                :close-on-content-click="false"
                :content-class="contentClass"
                :offset="4"
                max-height="500px"
                location="bottom left"
                target="parent"
                :disabled="disabled || isNullOrEmpty(localItems) && isNullOrEmpty(filterItems)">
            <template #activator="{ props: activatorProps }">
                <v-text-field v-bind="activatorProps"
                              ref="inputRef"
                              v-model="filter"
                              class="ow-dropdown-filter__input"
                              :class="{
                                  'ow-dropdown-filter__input--active': modelValue,
                              }"
                              hide-details
                              :disabled="disabled"
                              :placeholder="placeholder ?? t('action.filter')"
                              clearable
                              variant="outlined"
                              @focusin="inputRefFocused = true"
                              @focusout="inputRefFocused = false"
                              @click:clear="onClear">
                    <template #append-inner>
                        <slot class="ow-dropdown-filter__icon"
                              name="input-append-inner" />
                    </template>
                    <template #prepend-inner>
                        <slot class="ow-dropdown-filter__icon"
                              name="input-prepend-inner" />
                    </template>
                </v-text-field>
            </template>
            <template v-if="filter.length">
                <div class="ow-dropdown-filter__filter">
                    <ow-dropdown-filter-item v-for="item in filterItems"
                                             v-if="!isNullOrEmpty(filterItems)"
                                             ref="dropdownFilterItem"
                                             :key="item.value"
                                             :filter-active="!isNullOrWhitespace(filter)"
                                             :item="item"
                                             @check="refresh" />
                    <ow-dropdown-filter-item v-else
                                             :item="{
                                                 title: t('error.noResultsFound'),
                                                 readonly: true,
                                                 disableCheck: true,
                                             }" />
                </div>
            </template>
            <ow-dropdown-filter-item v-for="item in localItems"
                                     v-else
                                     ref="dropdownFilterItem"
                                     :key="item.value"
                                     class="ow-dropdown-filter-parent"
                                     :item="item"
                                     @check="refresh" />
        </v-menu>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        nextTick,
        onMounted,
        reactive,
        ref,
        watch,
    } from "vue"
    import { useI18n } from "vue-i18n"

    import OwDropdownFilterItem from "@/components/core/ow-dropdown-filter-item.vue"
    import {
        IOptionLinkItem,
    } from "@/interfaces/option-item.interface"
    import { isNullOrEmpty } from "@/utils/array-utils"
    import { isNullOrWhitespace } from "@/utils/string-utils"

    const modelValue = defineModel<boolean>()
    const { t } = useI18n()
    const props = withDefaults(defineProps<{
        items: IOptionLinkItem[]
        placeholder?: string,
        disabled?: boolean
    }>(), {
        items: () => [],
        disabled: false,
        filterIcon: true,
    })

    const emit = defineEmits(['check', 'blur'])
    const filter = defineModel('filter', {
        type: String,
        default: '',
    })
    const filterItems = ref<IOptionLinkItem[]>([])

    const localItems = reactive(props.items)

    const contentClass = computed(() => {
        return [
            'caption-regular',
            'ow-dropdown-filter__content',
        ].join(' ')
    })

    const inputRef = ref<HTMLInputElement | null>(null)
    const inputRefFocused = ref(false)

    const refresh = async (clickedItem?: IOptionLinkItem) => {
        await nextTick()

        if (clickedItem) {
            itemChecked(localItems, clickedItem)
            emit('check', clickedItem)
        }

        filterChildItems({
            data: localItems,
            filter: filter.value,
        })
        updateCheckboxStates(localItems)
    }

    watch(() => props.items, (newItems) => {
        localItems.splice(0, localItems.length, ...newItems)
        refresh()
    }, {
        immediate: true,
    })

    const clearing = ref()
    watch(() => filter.value, (val) => {
        if (clearing.value) {
            clearing.value = false
            return
        }
        if (!clearing.value) {
            modelValue.value = !isNullOrWhitespace(val)

            filterItems.value = filterChildItems({
                data: localItems,
                filter: filter.value,
            })
        }
    })

    const filterChildItems = ({
        data,
        filter,
    }: {
        data: IOptionLinkItem[]
        filter: string
    }): IOptionLinkItem[] => {
        const filterResult: IOptionLinkItem[] = []

        const filterItemsRecursively = (items: IOptionLinkItem[]) => {
            items.filter(f => !f.disableFilter).forEach((item) => {
                const lowerCaseFilter = filter.toLowerCase()
                item.items?.forEach((child) => {
                    if (child.title?.toLowerCase().includes(lowerCaseFilter)) {
                        filterResult.push(child)
                    }
                })
                if (item.items) {
                    filterItemsRecursively(item.items)
                }
            })
        }

        filterItemsRecursively(data)
        return filterResult
    }

    const selectAllChildren = (item: IOptionLinkItem) => {
        if (!isNullOrEmpty(item.items)) {
            item.items.forEach(child => {
                if (!child.disableCheck) {
                    child.checked = item.checked
                }
                child.indeterminate = false

                if (!isNullOrEmpty(child?.items)) {
                    selectAllChildren(child)
                }
            })
        }
    }

    const itemChecked = (localItems: IOptionLinkItem[], clickedItem: IOptionLinkItem) => {
        // check / uncheck all children recursively
        const checkChildren = (children: IOptionLinkItem[], item: IOptionLinkItem) => {
            // If child has linked IDs, update the linked parent items
            if (item.linkedIds) {
                const parent = localItems.find(parent => parent.value === item.linkedParentId)
                if (parent) {
                    // Update the linked parent items' selected and indeterminate states
                    parent.items.forEach(parentChild => {
                        if (!isNullOrEmpty(parentChild.linkedIds) && parentChild.linkedIds.includes(item.value.toString())) {

                            if (!parentChild.disableCheck) {
                                parentChild.checked = item.checked
                            }

                            // parentItem is indeterminate if all the linked ids are not selected
                            let selectedCount = 0
                            for (const linkedId of parentChild.linkedIds) {
                                const childParent = localItems.find((parent) => parent.value === clickedItem.parentId)
                                const child = childParent?.items.find((child) => child.value === linkedId)
                                if (child && child.checked) {
                                    selectedCount++
                                }
                            }
                            parentChild.indeterminate = selectedCount > 0 && selectedCount < parentChild.linkedIds.length
                            selectAllChildren(parentChild)
                        }
                    })
                }
            }

            if (!isNullOrEmpty(children)) {
                children.forEach(child => {
                    if (!child.disableCheck) {
                        child.checked = item.checked
                    }
                    checkChildren(child?.items, child)
                })
            }
        }

        checkChildren(clickedItem?.items, clickedItem)
    }

    const updateCheckboxStates = (localItems: IOptionLinkItem[]) => {
        const updateStateRecursively = (item: IOptionLinkItem) => {
            if (!item.items) {
                return
            }
            // Check if all children are selected
            const allChildrenSelected = item.items.every((child) => child.checked)

            // Check if some children are selected or indeterminate
            const partialChildrenSelected = item.items.some((child) => child.checked || child.indeterminate)

            // Check if no children are selected or indeterminate
            const noChildrenSelected = item.items.every((child) => !child.checked && !child.indeterminate)

            // Set indeterminate state if some but not all children are selected
            item.indeterminate = partialChildrenSelected && !allChildrenSelected && !noChildrenSelected

            // Set selected state if all children are selected or some are selected and none are indeterminate
            if (!item.disableCheck) {
                item.checked = allChildrenSelected || (partialChildrenSelected && !noChildrenSelected)
            }

            // Recursively update state for all children
            item.items.forEach(updateStateRecursively)
        }

        localItems.forEach(updateStateRecursively)
    }

    const onClear = () => {
        clearing.value = true
        filter.value = ''
    }

    watch(() => modelValue.value, (val) => {
        // is input focused
        if (inputRefFocused.value) {
            return
        }

        timedBlur()
    })

    watch(() => inputRefFocused.value, (val) => {
        if (val || modelValue.value) {
            return
        }

        timedBlur()
    })

    const timedBlur = () => {
        setTimeout(() => {
            if ( modelValue.value) {
                return
            }

            emit('blur')
        }, 250)
    }

    const show = async () => {
        modelValue.value = true
        await nextTick()

        inputRef.value?.focus()
    }

    defineExpose({
        refresh,
        show,
    })

</script>

<style lang="scss">
@import './ow-dropdown-filter';
</style>