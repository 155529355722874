<template>
    <div class="am-manage-monitors-header">
        <i18n-t keypath="assetMonitoring.text.description"
                tag="p"
                scope="global"
                class="am-manage-monitors-header__description body-regular">
            <template #settingslink>
                <router-link :to="{
                                 name: Route.AssetMonitoringSettings,
                                 params: {
                                     matterId: matterId?.toString(),
                                     tabId: 'titles'
                                 }
                             }"
                             data-test="asset-monitoring-link-to-settings"
                             data-track="Asset Monitoring: Button in text to navigate & manage settings">
                    <ow-button class="d-inline-flex"
                               is-link>
                        <span class=" body-highlight">{{ t('action.settings').toLowerCase() }}</span>
                    </ow-button>
                </router-link>
            </template>

            <template #companyLink>
                <router-link :to="{
                                 name: Route.AssetMonitoringSettingsAddCompany,
                                 params: {
                                     matterId: matterId?.toString(),
                                     tabId: 'titles',
                                 }
                             }"
                             data-test="asset-monitoring-link-to-manage-companies"
                             data-track="Asset Monitoring: Button in text to navigate & manage company monitors">
                    <ow-button class="d-inline-flex"
                               is-link>
                        <span class=" body-highlight">{{ t('action.addCompanyMonitors').toLowerCase() }}</span>
                    </ow-button>
                </router-link>
            </template>
        </i18n-t>
    </div>
</template>

<script setup lang="ts">
    import {computed} from "vue"
    import {useI18n} from "vue-i18n"
    import {useRoute} from "vue-router"

    import OwButton from "@/components/core/ow-button-ds.vue"
    import {Route} from "@/enums/route.enum"

    const { t } = useI18n()
    const route = useRoute()
    const matterId = computed<number>((): number => parseInt(route.params?.matterId?.toString()))
</script>

<style scoped lang="scss">
    @import './am-manage-monitors-header';
</style>
