import {isWithinDateRange} from "@/utils/date-utils";

export const isHmlrOutOfHours = (): boolean => {
    // Use the browser's internationalisation API
    // Using `en-US` so that the date comes out in `MM/dd/yyy` format which new Date() can parse.
    const intlDateObj = new Intl.DateTimeFormat('en-US', {
        dateStyle: 'short',
        timeStyle: 'short',
        timeZone: 'Europe/London',
    })
    const now = new Date()
    const openingTimes = {
        opens: new Date(new Date().setHours(6, 30, 0, 0)),
        closes: new Date(new Date().setHours(23, 0, 0, 0)),
    }
    const londonTimeNow = new Date(intlDateObj.format(now))
    const openingTime = new Date(intlDateObj.format(openingTimes.opens))
    const closingTime = new Date(intlDateObj.format(openingTimes.closes))
    const isOpen = isWithinDateRange(londonTimeNow, openingTime, closingTime)

    return !isOpen
}
