<template>
    <base-sub-type-table v-model:update-count="updateCount"
                         :headings="headings"
                         :notification="item" />
</template>

<script lang="ts" setup generic="T extends IBaseSubTypeTableColumn<T>">
    import {
        computed,
        PropType,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotificationDetail } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import BaseSubTypeTable
        from "@/components/asset-monitoring/grid/sub-type-items/base-sub-type-table/base-sub-type-table.vue"
    import { IBaseSubTypeTableColumn } from "@/components/asset-monitoring/grid/sub-type-items/types"

    defineProps<{
        item: IAssetMonitoringNotificationDetail,
    }>()

    const updateCount = defineModel<number>('update-count')

    const { t } = useI18n()

    const headings = computed<IBaseSubTypeTableColumn<T>[]>(() => ([
        {
            field: 'companyName',
            title: t('assetMonitoring.dataGrid.ownership.name') ,
            dataTest: 'companyName',
        },
        {
            field: 'displayAddress',
            title: t('assetMonitoring.dataGrid.ownership.address'),
            dataTest: 'displayAddress',
        },
        {
            field: 'companyRegistrationNumber',
            title: t('assetMonitoring.dataGrid.ownership.number'),
            dataTest: 'companyRegistrationNumber',
        },
        {
            field: 'companyStatus',
            title: t('assetMonitoring.dataGrid.ownership.status'),
            dataTest: 'companyStatus',
        },
        {
            field: 'displayNatureOfBusiness',
            title: t('assetMonitoring.dataGrid.ownership.natureOfBusiness'),
            dataTest: 'displayNatureOfBusiness',
        },
    ]))

    defineExpose({
        updateCount,
    })
</script>
