// Importing from the index file was causing problems with Jest tests, so moved to own file .
export default {
    loading: false,
    loadingDaylist: false,
    loadingLeaseHierarchy: false,
    record: null,
    officialCopiesAvailability: {
        loading: false,
        results: {
            titleRegister: {
                loadingOrder: false,
                editionDate: undefined,
                currentVersionIsBackdated: undefined,
                hmlrAvailableVersionIsBackdated: undefined,
                responseDate: undefined,
                responseDateValue: undefined,
                hmlrAvailabilityDescription: undefined,
                hmlrAvailabilityCode: undefined,
                currentStatusDescription: undefined,
                matterDocumentId: undefined,
                downloadUrl: undefined,
                documentId: undefined,
                orderId: undefined,
                orderStatus: undefined,
                failedOrder: undefined,
            },
            titlePlan: {
                loadingOrder: false,
                currentStatusDescription: undefined,
                currentVersionHasBeenSentByPost: undefined,
                currentVersionIsBackdated: undefined,
                documentId: undefined,
                hmlrAvailabilityCode: undefined,
                hmlrAvailabilityDescription: undefined,
                hmlrAvailableVersionIsBackdated: undefined,
                orderId: undefined,
                orderStatus: undefined,
                responseDateValue: undefined,
            },
            referredToDocuments: null,
            hmlrDocumentAvailabilityStatusRateLimited: false,
            hmlrDocumentAvailabilityStatusCode: null,
        },
    },
    daylist: {},
    dayListError: null,
    leaseHierarchy: null,
    showLrOwnership: true,
    bboxGeom: null,
    indicatorNames: [],
    titleInformation: null,
}
