<template>
    <template v-if="eager">
        <ow-loading-slot-container v-bind="props"
                                   v-model="modelValue">
            <template #label>
                <slot name="label" />
            </template>
            <div v-show="!loading">
                <slot />
            </div>
        </ow-loading-slot-container>
    </template>
    <template v-else>
        <ow-loading-slot-container v-bind="props"
                                   v-model="modelValue">
            <template #label>
                <slot name="label" />
            </template>
            <div v-if="!loading">
                <slot />
            </div>
        </ow-loading-slot-container>
    </template>
</template>

<script setup lang="ts">
    import OwLoadingSlotContainer from "@/components/core/ow-loading-slot-container.vue"
    const modelValue = defineModel<number>()

    const props = withDefaults(defineProps<{
        loading: boolean
        small?: boolean
        indeterminate?: boolean
        max?: number
        eager?: boolean
        fixed?: boolean
        offsetY?: string
        offsetX?: string
        label?: string
        hideLogo?: boolean
    }>(), {
        small: true,
        indeterminate: true,
        max: 100,
        eager: false,
        fixed: true,
        offsetY: 'calc((100vh / 2) - 50px)',
        hideLogo: false,
    })
</script>