export enum AssetMonitoringDataSource {
    UNKNOWN,
    HMLR,
    COMPANIES_HOUSE
}


export interface BaseRowItemModel{
    title: string,
    dataSource: AssetMonitoringDataSource,
    descriptionPath?: string
    titleNumber?: string,
    companyNumbers?: string[],
    boundaryDifference?: number,
    isBoundaryLarger?: boolean,
}
