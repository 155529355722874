<template>
    <div class="base-sub-type-table"
         :data-track="dataTrack"
         :data-test="dataTest">
        <base-sub-type-item v-model:diff="diff"
                            :notification="notification">
            <div v-if="updateCount === 0"
                 class="base-sub-type-item--no-changes body-italic-x-small d-flex align-center gc-1">
                <v-icon>
                    $info
                </v-icon>
                <span v-t="'label.noChangesFound'" />
            </div>
            <table v-show="updateCount > 0"
                   class="base-sub-type-item__table">
                <base-sub-type-table-headers :detail-columns="detailColumns" />
                <base-sub-type-table-rows v-model:update-count="updateCount"
                                          :diff="diff"
                                          :detail-columns="detailColumns"
                                          :headings="headings" />
            </table>
        </base-sub-type-item>
    </div>
</template>

<script setup lang="ts">
    import { IAssetMonitoringNotificationDetail } from "@/components/asset-monitoring/asset-monitoring-notification.interface"
    import { Difference } from "@/components/asset-monitoring/difference-generic"
    import BaseSubTypeItem from "@/components/asset-monitoring/grid/sub-type-items/base-sub-type-item.vue"
    import BaseSubTypeTableHeaders
        from "@/components/asset-monitoring/grid/sub-type-items/base-sub-type-table/base-sub-type-table-headers.vue"
    import BaseSubTypeTableRows
        from "@/components/asset-monitoring/grid/sub-type-items/base-sub-type-table/base-sub-type-table-rows.vue"
    import {
        IBaseSubTypeTableColumn,
    } from "@/components/asset-monitoring/grid/sub-type-items/types"

    defineProps<{
        notification: IAssetMonitoringNotificationDetail,
        headings?: IBaseSubTypeTableColumn<unknown>[],
        detailColumns?: IBaseSubTypeTableColumn<unknown>[],

        dataTrack?: string,
        dataTest?: string,
    }>()
    const diff = defineModel<Difference<unknown>>('diff')
    const updateCount = defineModel<number>('updateCount')
</script>

<style lang="scss" scoped>
    @import "../base-sub-type-item";
</style>
