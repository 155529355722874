// @/stores/asset-monitoring/getters.ts

import {
    IGetNotificationResponseItem,
    ISummaryItem,
} from '@/api/notifications.api'
import { IAssetMonitoringNotification } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
import {
    IAssetMonitoringState,
    useAssetMonitoringStore,
} from '@/stores/asset-monitoring/index'
import {
    convertToNotificationSubType,
    convertToNotificationType,
} from '@/stores/asset-monitoring/utils'
import { isNullOrEmpty } from '@/utils/array-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

export const mapNotificationResponseToAssetMonitoringItem = (notification: IGetNotificationResponseItem): IAssetMonitoringNotification => {
    return {
        notificationId: notification.notificationId,
        userNotificationId: notification.userNotificationId,
        message: notification.message,
        createdOn: new Date(notification.createdOn),
        isRead: notification.isRead,
        titleNumber: notification.titleNumber,
        organisationId: notification.organisationId,
        notificationType: convertToNotificationType(notification.type),
        companyNumbers: notification.companyNumbers,
        notificationDetails: notification.notificationDetails?.map(x => ({
            difference: x.difference,
            notificationSubType: convertToNotificationSubType(x.notificationSubType),
            priority: x.priority,
        })),
    } as IAssetMonitoringNotification
}

export default {
    userNotifications: (state: IAssetMonitoringState): Array<IAssetMonitoringNotification> => {
        return [ ...state.notifications?.values() ]
            .map(mapNotificationResponseToAssetMonitoringItem)
    },

    /**
     * Checks if the given state has any unread notifications.
     *
     * @param {IAssetMonitoringState} state - The state object containing notifications.
     * @return {boolean} - True if there are unread notifications, false otherwise.
     */
    hasUnreadNotifications: (state: IAssetMonitoringState): boolean => {
        return state?.unreadCompanyNotifications > 0
            || state?.unreadTitleNotifications > 0
            || !isNullOrEmpty(state.pendingNotifications)
    },
    /**
     * Retrieves a notification by its title number.
     *
     * @returns {Function} Function that takes a title number as input and returns the corresponding notification.
     */
    getNotificationByTitleNumber: (): Function => {
        // To use a getter inside a getter we need a reference to the store again
        const assetMonitoringStore = useAssetMonitoringStore()
        return (titleNumber: string) => assetMonitoringStore.userNotifications.filter(notification => notification.titleNumber === titleNumber)
    },

    /**
     * Get a list of all the unique titles number we have notifications for
     * @param state
     */
    getSummaryTitleNumbers: (state: IAssetMonitoringState): Array<ISummaryItem> => {
        return state.summaryTitles.map((titleNumber: string) => ({
            id: titleNumber,
            label: titleNumber,
        }))
    },

    /**
     * Get a list of all the unique companies we have notifications for
     * @param state
     */
    getSummaryCompanies: (state: IAssetMonitoringState): Array<ISummaryItem> => {
        const companyNumbers = state.summaryCompanies
        const groupedCompanies = state.companyGroups

        const matchedNames = companyNumbers.map(companyNumber => {
            // Find the matching company object by companyNumber
            const company = groupedCompanies
                .flatMap(group => group.companies) // Flatten all companies from all groups
                .find(company => company.companyNumber === companyNumber) // Match the companyNumber

            // Return the company name if found
            return {
                id: company?.companyNumber,
                label: company?.name || null,
            }
        })

        // Remove any null values in case some company numbers have no match
        return matchedNames.filter(company => !isNullOrWhitespace(company.label))
    },

    /**
     * Get the eraliest date of an unread alert.
     * Used as the default from date on the summary page.
     * @param state
     */
    getEarliestUnreadAlertDate: (state: IAssetMonitoringState): Date => {
        return state.notifications.reduce((earliestDate: Date, notification: IGetNotificationResponseItem) => {
            const createdOn = new Date(notification.createdOn)
            return notification.isRead ? earliestDate : createdOn < earliestDate ? createdOn : earliestDate
        }, new Date())
    },
}
