<template>
    <div v-if="showHmlrClosedMessage">
        <slot v-if="hasDefaultSlot" />
        <ow-alert v-else
                  type="warning"
                  :text="t('documentOrdering.hmlrOutOfHours')" />
    </div>
</template>

<script setup lang="ts">
    import {computed,
            useSlots} from "vue"
    import {useI18n} from "vue-i18n"

    import OwIconWarning from "@/components/core/icons/ow-icon-warning.vue"
    import OwAlert from "@/components/core/ow-alert.vue"
    import OwIcon from "@/components/core/ow-icon.vue"
    import {isHmlrOutOfHours} from "@/utils/hmlr-utils"

    const { t } = useI18n()

    const showHmlrClosedMessage = computed(() => isHmlrOutOfHours())
    const slots = useSlots()
    const hasDefaultSlot = computed(() => !!slots.default && !!slots.default().length)
</script>

<style scoped lang="scss">

</style>
