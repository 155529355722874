<template>
    <div class="am-alert-filter">
        <div class="am-alert-filter__top">
            <am-alert-filter-pills :filters="filters"
                                   :disabled="disabled"
                                   @remove="removeFilter"
                                   @clear="onClearAll" />

            <am-header-actions :disabled="disabled"
                               :has-items="hasItems"
                               class="py-3 pb-4"
                               no-padding
                               :matter-id="matterId"
                               @refresh="onClearAll">
                <ow-dropdown-filter ref="ddFilter"
                                    v-model="showDropdownFilter"
                                    v-model:filter="filterText"
                                    :disabled="isNullOrEmpty(items)"
                                    :items="items"
                                    @check="onToggleCheck">
                    <template #input-append-inner>
                        <v-icon>
                            $filter
                        </v-icon>
                    </template>
                    <template #input-prepend-inner>
                        <v-icon>
                            mdi-magnify
                        </v-icon>
                    </template>
                </ow-dropdown-filter>
            </am-header-actions>
        </div>
    </div>
</template>

<script setup lang="ts" generic="T extends IOptionLinkItem">

    import { debounce } from "lodash"
    import {
        computed,
        nextTick,
        onActivated,
        onBeforeMount,
        onMounted,
        reactive,
        ref,
        watch,
    } from "vue"
    import {useI18n} from "vue-i18n"
    import { useStore } from "vuex"

    import AmAlertFilterPills from "@/components/asset-monitoring/am-alert-filter-pills.vue"
    import AmHeaderActions from "@/components/asset-monitoring/am-header-actions.vue"
    import { IAssetMonitoringNotification } from "@/components/asset-monitoring/asset-monitoring-notification.interface"
    import OwDropdownFilter from "@/components/core/ow-dropdown-filter.vue"
    import { IOptionLinkItem } from "@/interfaces/option-item.interface"
    import { useAssetMonitoringStore } from "@/stores/asset-monitoring"
    import {
        isNullOrEmpty,
    } from "@/utils/array-utils"
    const props = defineProps<{
        disabled?: boolean
    }>()

    const emit = defineEmits<{
        check: (item: T) => void
        clear: () => void
    }>()

    const items = defineModel('items', {
        type: Array as () => Array<T>,
        default: [],
    })

    const filters = defineModel('filters', {
        type: Array as () => Array<T>,
        default: [],
    })

    const { t } = useI18n()
    const store = useStore()
    const assetMonitoringStore = useAssetMonitoringStore()
    const ddFilter = ref<OwDropdownFilter>()
    const filterText = ref<string>('')
    const showDropdownFilter = ref<boolean>(false)

    const matterId = computed<number>(() => store.state.matter.currentMatter.id)
    const notifications = computed((): Array<IAssetMonitoringNotification> => assetMonitoringStore.userNotifications)
    const totalNotificationsCount = computed<number>((): number => assetMonitoringStore.totalResults)
    const hasItems = computed((): boolean => !isNullOrEmpty(notifications.value) || totalNotificationsCount.value > 0)
    const userNotifications = computed((): Array<IAssetMonitoringNotification> => assetMonitoringStore.userNotifications)

    const removeFilter = async (item: T) => {
        item.checked = false
        await nextTick()
        ddFilter.value?.refresh(item)
    }
    const onToggleCheck = async (item: T) => {
        emit('check', item)
        debounceUpdateFilters()
    }

    const updateFilters = () => {
        if (isNullOrEmpty(items.value)) {
            return
        }
        const result: T[] = []
        items.value.forEach((item) => {
            if (item.disableFilter) {
                return
            }

            if (item.checked) {
                result.push(item)
            }
            if (item.items) {
                item.items.forEach((subItem) => {
                    if (subItem.checked) {
                        result.push(subItem)
                    }
                })
            }
        })
        filters.value = result

    }

    const debounceUpdateFilters = debounce(updateFilters, 500)


    const onClearAll = async () => {
        filters.value = []
        filterText.value = ''
        showDropdownFilter.value = false
        emit('clear')
    }

</script>

<style scoped lang="scss">
@import './am-alert-filter';
</style>
