// @/stores/asset-monitoring/index.ts
import {
    acceptHMRUpdate,
    defineStore,
} from 'pinia'

import { IGetCompanyGroupResponse } from '@/api/company-groups.api'
import { ICompanyNotificationSettings } from "@/api/company-notifications"
import {
    IGetNotificationResponseItem,
    INotificationMessageArguments,
    ISummaryItem,
    ITitleNotificationSettings,
} from '@/api/notifications.api'
import { IUserEmailPreferences } from "@/api/user-email-preferences.api"
import { IAssetMonitoringCompanyGroup } from '@/components/asset-monitoring/company-groups/types'
import { NotificationPriorityType } from "@/components/asset-monitoring/notification-priority-type.enum"
import { NotificationSubType } from "@/components/asset-monitoring/notification-sub-type.enum"

import amActions from './actions'
import amGetters from './getters'

/**
 * Represents the state of the asset monitoring store.
 */

export interface IAssetMonitoringState {
    notifications: IGetNotificationResponseItem[],
    allNotifications: IGetNotificationResponseItem[],
    pendingNotifications: INotificationMessageArguments[],

    summaryNotifications: IGetNotificationResponseItem[],
    summaryTotalChanges: number,
    summaryTitles: string[],
    summaryCompanies: string[],

    totalResults: number
    currentPageIndex: number
    pageSize: number
    sortBy: string,
    filterText?: string
    showOnlyUnread?: boolean,
    lastCheckedDate?: string,
    dateSince?: Date,
    dateBefore?: Date,

    availableTitleNumbers: string[],
    availableCompanies: string[],
    availablePriorities: NotificationPriorityType[],
    availableNotificationSubTypes: NotificationSubType[],
    selectedNotificationTypes?: string[],
    selectedNotificationSubTypes?: string[],
    selectedCompanies?: string[],
    selectedTitles?: string[],
    selectedPriorities?: [],

    isLoading: boolean
    unreadTitleNotifications: number,
    unreadCompanyNotifications: number,
    titleNotificationSettings: ITitleNotificationSettings,
    companyNotificationSettings: ICompanyNotificationSettings,
    userEmailPreferences: IUserEmailPreferences,
    areTitleNotificationSettingsLoaded: boolean,
    areCompanyNotificationSettingsLoaded: boolean,
    isLoadingSettings: boolean,
    isUpdatingSettings: boolean,
    isLoadingUserEmailPreferences: boolean,
    isUpdatingUserEmailPreferences: boolean,
    areUserEmailPreferencesLoaded: boolean,
    reloadNotifications: boolean,
    reloadFilters: boolean,
    companyGroups: IGetCompanyGroupResponse[],

    hasAccessToAlerts: boolean,
}

export const assetMonitoringOptions = {
    state: (): IAssetMonitoringState => ({
        notifications: [],
        allNotifications: [],
        pendingNotifications: [],

        summaryNotifications: [],
        summaryTotalChanges: 0,
        summaryTitles: [],
        summaryCompanies: [],

        totalResults: 0,
        currentPageIndex: 0,
        pageSize: 100,
        sortBy: 'DateDesc',
        selectedCompanies: [],
        selectedTitles: [],

        isLoading: false,
        unreadTitleNotifications: 0,
        unreadCompanyNotifications: 0,
        titleNotificationSettings: {
            isActive: false,
            trackDaylist: false,
            trackOcda: false,
            trackOwnership: false,
            trackBoundary: false,
            trackCompaniesHouse: false,
            trackEpc: false,
            dailySummaryEmailEnabled: false,
        },
        companyNotificationSettings: {
            isActive: false,
            trackCompaniesHouseInformation: false,
            trackCompaniesHouseInsolvency: false,
            trackCompanyTitleOwnership: false,
        },

        userEmailPreferences: {
            optedIn: false,
        },
        areTitleNotificationSettingsLoaded: false,
        areCompanyNotificationSettingsLoaded: false,
        isLoadingSettings: false,
        isUpdatingSettings: false,
        isLoadingUserEmailPreferences: false,
        isUpdatingUserEmailPreferences: false,
        areUserEmailPreferencesLoaded: false,
        reloadNotifications: false,
        reloadFilters: false,
        selectedNotificationSubTypes: [],
        selectedNotificationTypes: [],
        selectedPriorities: [],
        hasAccessToAlerts: false,
        availableCompanies: [],
        availableTitleNumbers: [],
        availablePriorities: [],
        availableNotificationSubTypes: [],
        companyGroups: [],
    }),
    getters: { ...amGetters },
    actions: { ...amActions },
}

/**
 * The `useAssetMonitoringStore` variable is a store created using the `defineStore` function.
 * It is used to manage the state, getters, and actions related to asset monitoring.
 */
export const useAssetMonitoringStore = defineStore('assetMonitoring', assetMonitoringOptions)

// Enables Hot Module Replacement in Pinia
if ((import.meta as any).hot) {
    (import.meta as any).hot.accept(acceptHMRUpdate(useAssetMonitoringStore, (import.meta as any).hot))
}
