<template>
    <div class="am-header-actions"
         :class="{
             'am-header-actions--no-padding': noPadding,
         }">
        <div class="am-header-actions__left d-flex justify-space-between w-100">
            <div class="d-flex align-center gc-4">
                <slot />
                <ow-toggle v-model="showOnlyUnread"
                           :disabled="disabled"
                           data-track="Asset Monitoring: Toggle to show only unread"
                           class="am-header-actions__unreadOnly"
                           :label="t('assetMonitoring.readStatus.showUnreadOnly')" />
                <ow-button v-if="hasItems && !isNullOrEmpty(unreadNotificationIds)"
                           is-link
                           :disabled="disabled"
                           class="link-text am-header-actions__link"
                           data-test="mark-all-read"

                           data-track="Asset Monitoring: Mark all as read"
                           @click="markAllReadHandler">
                    {{ t('action.markAllRead') }}
                </ow-button>
            </div>
            <div class="d-flex align-center gc-2">
                <span class="accents-small">{{ t('assetMonitoring.text.lastChecked', { date: assetMonitoringStore?.lastCheckedDate }) }}</span>
                <ow-button class="accents-small am-header-actions__link"
                           data-test="refresh-notifications"
                           data-track="Asset Monitoring: Refresh notifications"
                           is-link
                           :disabled="disabled"
                           small
                           @click="refreshNotificationsHandler">
                    {{ t('action.refresh') }}
                </ow-button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

    import {
        computed,
    } from "vue"
    import {useI18n} from "vue-i18n"

    import OwButton from "@/components/core/ow-button-ds.vue"
    import OwToggle from "@/components/core/ow-toggle.vue"
    import {useAssetMonitoringStore} from "@/stores/asset-monitoring"
    import { isNullOrEmpty } from "@/utils/array-utils"

    const { t } = useI18n()
    const assetMonitoringStore = useAssetMonitoringStore()

    const props = defineProps<{
        hasItems?: boolean,
        matterId: number,
        noPadding?: boolean,
        disabled?: boolean,
    }>()

    const emit = defineEmits<{
        (e: 'refresh'),
    }>()

    const showOnlyUnread = computed({
        get: () => assetMonitoringStore.showOnlyUnread,
        set: (value: boolean) => assetMonitoringStore.updateShowUnreadOnly(props.matterId, value),
    })

    const refreshNotificationsHandler = async () => {
        emit('refresh')
    }

    const unreadNotificationIds = computed(() => assetMonitoringStore.userNotifications
        .filter((notification) => !notification.isRead)
        .map((notification) => notification.notificationId))

    const markAllReadHandler = async () => {
        await assetMonitoringStore.markAsRead(props.matterId, unreadNotificationIds.value)
        emit('refresh')
    }
</script>

<style scoped lang="scss">
    @import './am-header-actions';
</style>
