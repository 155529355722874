<template>
    <row-item v-if="baseRowModel"
              v-bind="props"
              v-model="isShowingDetailsPanel"
              has-extra-info

              :row-model="baseRowModel"
              :view-title-text="viewTitleText"

              @view-title="emit('view-title', $event)"
              @mark-as-read="emit('mark-as-read', $event)">
        <sub-type-renderer v-model:update-count="numberOfUpdates"
                           :showing-details-panel="isShowingDetailsPanel"
                           :notification-details="item.notificationDetails"
                           :notification-type="item.notificationType"
                           :title-number="item.titleNumber"
                           :company-number="item.companyNumbers?.[0]"
                           :notification-id="item.notificationId"
                           @area-difference="handleBoundaryArea" />
    </row-item>
</template>

<script lang="ts"
        setup>
    import {
        computed,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import { IAssetMonitoringNotification } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import {
        AssetMonitoringDataSource,
        BaseRowItemModel,
    } from "@/components/asset-monitoring/base-row-item-model"
    import RowItem from "@/components/asset-monitoring/grid/row-items/row-item.vue"
    import SubTypeRenderer from "@/components/asset-monitoring/grid/sub-type-items/sub-type-renderer.vue"
    import { NotificationSubType } from '@/components/asset-monitoring/notification-sub-type.enum'
    import { NotificationType } from "@/components/asset-monitoring/notification-type.enum"
    import { checkFlag } from '@/feature-flags'
    const { t } = useI18n()
    const numberOfUpdates = ref<number>(0)
    const isShowingDetailsPanel = defineModel<boolean>()
    const store = useStore()

    const props = defineProps<{
        item: IAssetMonitoringNotification,
        matterId: number,
    }>()

    const emit = defineEmits<{
        (e: 'view-title', {
            titleNumber,
            notificationType,
            notificationSubType,
        }: {
            titleNumber: string
            notificationType: NotificationType
            notificationSubType: NotificationSubType
        }),
        (e: 'mark-as-read', payload: { notificationId: string, isRead: boolean }),
    }>()

    const viewTitleText = computed(() => {
        if (hasBoundaryChangeNotification.value) {
            return t('assetMonitoring.subType.boundaryUpdated.viewTitle')
        } else if (hasOwnershipChangeNotification.value) {
            return t('assetMonitoring.subType.ownershipUpdated.viewTitle')
        } else if (hasOC2ChangeNotification.value) {
            return t('assetMonitoring.subType.oc2DocumentUpdated.viewTitle')
        } else if (hasPendingApplicationChangeNotification.value) {
            return t('assetMonitoring.subType.pendingApplicationsUpdated.viewTitle')
        } else if (hasEditionDateMismatchNotification.value) {
            return t('assetMonitoring.subType.editionDateDoesntMatchOcdaResponse.viewTitle')
        } else if (hasEPCChangeNotification.value) {
            return t('assetMonitoring.subType.epc.viewTitle')
        } else if (hasPlanningApplicationsNotification.value) {
            return t('assetMonitoring.subType.planningApplications.viewTitle')
        }
        return t('action.viewTitle')
    })

    const hasBoundaryChangeNotification = computed(() => props.item?.notificationDetails?.some((notificationDetail) => {
        return notificationDetail.notificationSubType === NotificationSubType.BoundaryChange
    }))

    const hasOwnershipChangeNotification = computed(() => props.item?.notificationDetails?.some((notificationDetail) => {
        return notificationDetail.notificationSubType === NotificationSubType.OwnershipChange
    }))

    const hasOC2ChangeNotification = computed(() => props.item?.notificationDetails?.some((notificationDetail) => {
        return notificationDetail.notificationSubType === NotificationSubType.NewDocumentInOCDA
    }))

    const hasEditionDateMismatchNotification = computed(() => props.item?.notificationDetails?.some((notificationDetail) => {
        return notificationDetail.notificationSubType === NotificationSubType.EditionDateDoesntMatchOcdaResponse
    }))

    const hasPendingApplicationChangeNotification = computed(() => props.item?.notificationDetails?.some((notificationDetail) => {
        return notificationDetail.notificationSubType === NotificationSubType.CompletedPendingApplication
    }))

    const hasEPCChangeNotification = computed(() => props.item?.notificationDetails?.some((notificationDetail) => {
        return notificationDetail.notificationSubType === NotificationSubType.Epc
    }))

    const hasPlanningApplicationsNotification = computed(() => props.item?.notificationDetails?.some((notificationDetail) => {
        if (!store.state?.config?.featureFlags?.assetMonitoringPlanningApplications) {
            return false
        }
        return notificationDetail.notificationSubType === NotificationSubType.PlanningApplications
    }))

    const boundaryAreaDifference = ref<number>(0)
    const isBoundaryLarger = ref<boolean>(false)
    const handleBoundaryArea = (area: number) => {
        isBoundaryLarger.value = area > 0
        boundaryAreaDifference.value = area > 0 ? area : area * -1
    }

    const baseRowModel = computed((): BaseRowItemModel => {
        if (hasBoundaryChangeNotification.value) {
            return {
                title: t('assetMonitoring.subType.boundaryUpdated.title'),
                descriptionPath: 'assetMonitoring.subType.boundaryUpdated.description',
                dataSource: AssetMonitoringDataSource.HMLR,
                titleNumber: props.item.titleNumber,
                boundaryDifference: boundaryAreaDifference.value,
                isBoundaryLarger: isBoundaryLarger.value,
            }
        }
        if (hasOwnershipChangeNotification.value) {
            return {
                title: t('assetMonitoring.subType.ownershipUpdated.title'),
                descriptionPath: 'assetMonitoring.subType.ownershipUpdated.description',
                dataSource: AssetMonitoringDataSource.HMLR,
                titleNumber: props.item.titleNumber,
            }
        }
        if (hasOC2ChangeNotification.value) {
            return {
                title: `${ t('assetMonitoring.subType.oc2DocumentUpdated.title') }  (${ numberOfUpdates.value })`,
                descriptionPath: 'assetMonitoring.subType.oc2DocumentUpdated.description',
                dataSource: AssetMonitoringDataSource.HMLR,
                titleNumber: props.item.titleNumber,
            }
        }
        if (hasPendingApplicationChangeNotification.value) {
            return {
                title: `${ t('assetMonitoring.subType.pendingApplicationsUpdated.title') }  (${ numberOfUpdates.value })`,
                descriptionPath: 'assetMonitoring.subType.pendingApplicationsUpdated.description',
                dataSource: AssetMonitoringDataSource.HMLR,
                titleNumber: props.item.titleNumber,
            }
        }
        if (hasEditionDateMismatchNotification.value) {
            return {
                title: t('assetMonitoring.subType.editionDateDoesntMatchOcdaResponse.title'),
                descriptionPath: 'assetMonitoring.subType.editionDateDoesntMatchOcdaResponse.description',
                dataSource: AssetMonitoringDataSource.HMLR,
                titleNumber: props.item.titleNumber,
            }
        }
        if (hasEPCChangeNotification.value) {
            return {
                title: t('assetMonitoring.subType.epc.title'),
                descriptionPath: 'assetMonitoring.subType.epc.description',
                dataSource: AssetMonitoringDataSource.HMLR,
                titleNumber: props.item.titleNumber,
            }
        }
        if (hasPlanningApplicationsNotification.value) {
            return {
                title: t('assetMonitoring.subType.planningApplications.title'),
                descriptionPath: 'assetMonitoring.subType.planningApplications.description',
                dataSource: AssetMonitoringDataSource.HMLR,
                titleNumber: props.item.titleNumber,
            }
        }

        if (!checkFlag('asset-monitoring-planning-applications')) {
            console.warn('Planning applications flag is off, but a planning application notification was received')
            return null
        }

        throw new Error(`Unable to match notification type to row model: ${ JSON.stringify(props.item?.notificationDetails) })`)
    })
</script>
