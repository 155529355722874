import { UploadedDocumentStatus } from '@/enums/uploaded-document-status.enum'
import { UploadedDocumentType } from '@/enums/uploaded-document-type.enum'
import { ISearchesDocument } from '@/interfaces/searches-document.interface'
import { IDocumentState } from '@/interfaces/store/documents/document-state.interface'
import { IUploadedDocument } from '@/interfaces/uploaded-document.interface'
import {
    DOCUMENT_VIEWER_MUTATE_ENTRY_POINT,
    DOCUMENTS_MUTATE_ADD_DOCUMENT,
    DOCUMENTS_MUTATE_ADD_TITLE_ANALYSIS_REPORT_REQUEST,
    DOCUMENTS_MUTATE_LIBRARY_DOCUMENTS,
    DOCUMENTS_MUTATE_LOADING_STATE,
    DOCUMENTS_MUTATE_MOCK_BEHAVIOUR,
    DOCUMENTS_MUTATE_REMOVE_TITLE_ANALYSIS_REPORT_REQUEST,
    DOCUMENTS_MUTATE_UPLOADED_DOCS_LOADING,
    DOCUMENTS_MUTATE_UPLOADED_DOCUMENTS,
    DOCUMENTS_MUTATE_UPLOADED_DOCUMENTS_FROM_MESSAGE,
    DOCUMENTS_MUTATE_UPLOADED_DOCUMENTS_STATUS,
    DOCUMENTS_REMOVE_DOCUMENT,
    DOCUMENTS_REMOVE_UPLOADED_DOCUMENT,
    DOCUMENTS_REMOVE_UPLOADED_DOCUMENTS,
    DOCUMENTS_REPLACE_SEARCHES_DOCUMENTS,
    DOCUMENTS_REPLACE_UPLOADED_DOCUMENTS,
    MUTATE_DOCUMENT_ORDER_ERROR,
    SET_LIBRARY_LOADING,
    SET_LIBRARY_PROPERTIES,
    SET_NEW_LIBRARY_DOCUMENTS,
    SET_UPDATED_DOCUMENTS,
    SET_UPLOAD_DOCUMENTS_DIALOG_STATE,
    SET_VIEWER_LOGGING_PROPERTIES,
} from '@/store/modules/documents/documents-types'
import {
    IUploadedDocumentStatusUpdatedNotification,
} from '@/store/modules/matter-hub/interfaces/uploaded-document-status-updated-notification.interface'
import {
    dynamicSort,
    isNullOrEmpty,
} from '@/utils/array-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

export default {

    [DOCUMENT_VIEWER_MUTATE_ENTRY_POINT](state: IDocumentState, entryPoint: string) {
        state.viewer.originalEntryPoint = entryPoint
    },

    [SET_LIBRARY_LOADING](state: IDocumentState, loading: boolean) {
        state.library.loading = loading
    },

    [SET_LIBRARY_PROPERTIES](state: IDocumentState, properties: Array<any>) {
        for (const prop in properties) {
            state.library[prop] = properties[prop]
        }
    },

    [SET_UPDATED_DOCUMENTS](state: IDocumentState, updatedDocuments: Array<any>) {
        updatedDocuments.forEach(updatedDocument => {
            // Update documents in the document library
            const staleLibraryDocument = state.library.documents.find(document => document.documentId === updatedDocument.documentId)
            if (staleLibraryDocument) {
                for (const prop in updatedDocument) {
                    staleLibraryDocument[prop] = updatedDocument[prop]
                }
            }
        })
    },

    [SET_NEW_LIBRARY_DOCUMENTS](state: IDocumentState, newDocuments: Array<any>) {
        state.library.documents.push(...newDocuments)
    },

    [SET_VIEWER_LOGGING_PROPERTIES](state, properties: Array<any>) {
        for (const prop in properties) {
            state.viewer.logging[prop] = properties[prop]
        }
    },

    [SET_UPLOAD_DOCUMENTS_DIALOG_STATE](state, newState: boolean) {
        state.showUploadDocumentsDialog = newState
    },

    [MUTATE_DOCUMENT_ORDER_ERROR](state: IDocumentState, error: string) {
        if (isNullOrWhitespace(error)) {
            state.orderErrors.length = 0
        } else {
            state.orderErrors.push(error)
        }
    },

    [DOCUMENTS_MUTATE_LIBRARY_DOCUMENTS](state: IDocumentState, documents: Array<any>) {
        state.library.documents = documents
    },

    [DOCUMENTS_MUTATE_LOADING_STATE](state, {
        key,
        value,
    }) {
        state.loadingState[key] = value
    },

    [DOCUMENTS_MUTATE_ADD_DOCUMENT](state: IDocumentState, doc: any) {
        state.library.documents.push(doc)
    },

    [DOCUMENTS_MUTATE_ADD_TITLE_ANALYSIS_REPORT_REQUEST](state: IDocumentState, requestId: string) {
        state.titleAnalysisReportRequests.push(requestId)
    },

    [DOCUMENTS_MUTATE_REMOVE_TITLE_ANALYSIS_REPORT_REQUEST](state: IDocumentState, requestId: string) {
        const index = state.titleAnalysisReportRequests.indexOf(requestId, 0)
        if(index > -1) {
            state.titleAnalysisReportRequests.splice(index, 1)
        }
    },

    [DOCUMENTS_REPLACE_UPLOADED_DOCUMENTS](state: IDocumentState, uploadedDocuments: Array<IUploadedDocument>) {
        state.library.uploadedDocuments = uploadedDocuments.sort(dynamicSort('-updatedOn'))
    },

    [DOCUMENTS_MUTATE_UPLOADED_DOCUMENTS](state: IDocumentState, uploadedDocuments: Array<IUploadedDocument>) {
        uploadedDocuments.forEach(doc => {
            const exists = state.library.uploadedDocuments.find(d => d.id === doc.id)
            if (exists) {
                exists.status = doc.status
                if (!isNullOrWhitespace(doc.titleNumber)) {
                    exists.titleNumber = doc.titleNumber
                }
                if (doc.type) {
                    exists.type = doc.type
                }
            } else {
                state.library.uploadedDocuments.push(doc)
            }
        })
        state.library.uploadedDocuments = state.library.uploadedDocuments.sort(dynamicSort('-updatedOn'))
    },

    [DOCUMENTS_MUTATE_MOCK_BEHAVIOUR](state: IDocumentState, val: boolean) {
        state.useMockBehaviour = val
    },

    [DOCUMENTS_MUTATE_UPLOADED_DOCUMENTS_STATUS](state: IDocumentState, docs: Array<IUploadedDocument>) {
        state.library.uploadedDocuments.forEach(doc => {
            doc.status = docs.find(d => d.id === doc.id).status
        })
    },

    [DOCUMENTS_MUTATE_UPLOADED_DOCUMENTS_FROM_MESSAGE](state: IDocumentState, message: IUploadedDocumentStatusUpdatedNotification) {
        const doc = state.library.uploadedDocuments.find(doc => doc.id === message.documentId)
        if (!doc) {
            // Probably isn't already loaded, so ignore the message
            return
        }

        // Save the message too
        if (isNullOrEmpty(doc.messages)) {
            doc.messages = []
        }

        doc.status = message.status
        if (message.status === UploadedDocumentStatus.complete) {
            state.library.lastUploadedDocument = doc
        }

        if (!isNullOrWhitespace(message.titleNumber)) {
            doc.titleNumber = message.titleNumber
        }

        if (message.documentType !== null) {
            const docType = typeof message.documentType === 'number'
                ? Object.keys(UploadedDocumentType)[message.documentType]
                : message.documentType
            doc.type = docType
        }

        doc.messages.push(message)
    },

    [DOCUMENTS_MUTATE_UPLOADED_DOCS_LOADING](state: IDocumentState, isLoading: boolean) {
        state.library.loadingUploadedDocuments = isLoading
    },

    [DOCUMENTS_REPLACE_SEARCHES_DOCUMENTS](state: IDocumentState, searchesDocuments: Array<ISearchesDocument>) {
        state.library.searchesDocuments = searchesDocuments
    },

    [DOCUMENTS_REMOVE_DOCUMENT](state: IDocumentState, documentId: number) {
        const index = state.library.documents.findIndex(doc => doc.documentId === documentId)
        if (index > -1) {
            state.library.documents.splice(index, 1)
        }
    },

    [DOCUMENTS_REMOVE_UPLOADED_DOCUMENT](state: IDocumentState, documentId: number) {
        const index = state.library.uploadedDocuments.findIndex(doc => doc.id === documentId)
        if (index > -1) {
            state.library.uploadedDocuments.splice(index, 1)
        }
    },

    [DOCUMENTS_REMOVE_UPLOADED_DOCUMENTS](state: IDocumentState, documentIds: number[]) {
        documentIds.forEach(docId => {
            const index = state.library.uploadedDocuments.findIndex(doc => doc.id === docId)
            if (index > -1) {
                state.library.uploadedDocuments.splice(index, 1)
            }
        })
    },
}
