<template>
    <div class="am-alerts">
        <ow-loading-slot v-if="loading"
                         loading />
        <div v-else-if="splashPage"
             class="am-alerts__splash-page">
            <splash-page :title="splashTitle"
                         :button-title="t('action.enableMonitoring')"
                         :matter-id="matterId"
                         @button-click="$emit('splash-button-click', !splashPage)" />
        </div>
        <div v-else>
            <am-alert-filter v-model:items="items"
                             v-model:filters="selectedItems"
                             :disabled="showLoadingSkeleton"
                             @clear="$emit('clear')"
                             @check="$emit('item-check', $event)" />

            <ow-loading-slot :loading="showLoadingSkeleton">
                <am-data-grid :is-loading="assetMonitoringStore.isLoading"
                              :items="assetMonitoringStore.userNotifications"
                              :matter-id="matterId"
                              data-test="alert-monitors-grid"
                              class="am-alerts__data-grid"
                              @refresh="$emit('refresh')"
                              @sort-by="$emit('sort-by', $event)"
                              @view-title="onViewTitle"
                              @view-company="onViewCompany"
                              @mark-as-read="markAsReadHandler"
                              @load-next-page="loadNextNotificationsPageHandler" />
            </ow-loading-slot>
        </div>
    </div>
</template>

<script setup lang="ts">

    import {
        computed,
        ref,
        watch,
    } from "vue"
    import { useI18n } from "vue-i18n"
    import {
        useRoute,
        useRouter,
    } from "vue-router"
    import { useStore } from "vuex"

    import AmAlertFilter from "@/components/asset-monitoring/am-alert-filter.vue"
    import AmDataGrid from "@/components/asset-monitoring/grid/data-grid.vue"
    import { NotificationSubType } from "@/components/asset-monitoring/notification-sub-type.enum"
    import SplashPage from "@/components/asset-monitoring/splash-page.vue"
    import OwLoadingSlot from "@/components/core/ow-loading-slot.vue"
    import { Route } from "@/enums/route.enum"
    import { TitlePanelTabName } from "@/enums/title-panel-tab-name"
    import { checkFlag } from "@/feature-flags"
    import { IOptionLinkItem } from "@/interfaces/option-item.interface"
    import { OPEN_WINDOW } from "@/store/mutation-types"
    import { useAssetMonitoringStore } from "@/stores/asset-monitoring"
    import { getCompaniesHouseLinkFromCompanyRegistrationNumber } from "@/utils/link-utils"

    const { t } = useI18n()

    const props = withDefaults(defineProps<{
        matterId: number
        splashPage: boolean
    }>(), {
        splashPage: false,
    })

    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const assetMonitoringStore = useAssetMonitoringStore()
    const isLoadingSettings = computed(() => assetMonitoringStore.isLoadingSettings)
    const isLoadingNotifications = computed(() => assetMonitoringStore.isLoading)
    const loading = ref<boolean>(true)
    const splashTitle = computed(() =>{
        return t('assetMonitoring.splashPage.assetMonitoring')
    })
    const items = defineModel('items', {
        type: Array as () => Array<IOptionLinkItem>,
        default: [],
    })
    const selectedItems = defineModel('selectedItems', {
        type: Array as () => Array<IOptionLinkItem>,
        default: [],
    })

    const showLoadingSkeleton = computed(() => {
        return isLoadingNotifications.value || isLoadingSettings.value
    })

    const loadNextNotificationsPageHandler = async () => {
        await assetMonitoringStore.getNextNotificationsPage(props.matterId)
    }

    const getActiveTab = (notificationSubType: NotificationSubType) => {
        let activeTab =  TitlePanelTabName.Summary
        switch (notificationSubType) {
            case NotificationSubType.Epc:
                activeTab = TitlePanelTabName.Epc
                break
            case NotificationSubType.NewDocumentInOCDA:
            case NotificationSubType.EditionDateDoesntMatchOcdaResponse:
                activeTab = TitlePanelTabName.CopiesFiled
                break
            case NotificationSubType.CompletedPendingApplication:
                activeTab = TitlePanelTabName.DayList
                break
        }
        return activeTab
    }

    const onViewCompany = async (args: {
        companyNumbers: number[],
        notificationSubType: NotificationSubType
    }): Promise<void> => {
        await store.dispatch(OPEN_WINDOW, getCompaniesHouseLinkFromCompanyRegistrationNumber(args?.companyNumbers?.[0]))
    }

    const onViewTitle = async (args: {
        titleNumber: string,
        notificationSubType: NotificationSubType
    }): Promise<void> => {
        if (checkFlag('title-panel-v-2', false)) {
            const activeTab = getActiveTab(args.notificationSubType)
            await router.push({
                name: Route.AssetMonitoringTitleDetailsPanel,
                params: {
                    titleNumber: args.titleNumber,
                    matterId: props.matterId,
                },
                query: {
                    from: route.name.toString(),
                    tab: activeTab,
                },
            })
        } else {
            await router.push({
                name: Route.MatterMapTitle,
                params: {
                    titleNumber: args.titleNumber,
                    matterId: props.matterId,
                },
            })
        }
    }

    const markAsReadHandler = async ({
        notificationId,
        isRead,
    }) => {
        await assetMonitoringStore.markAsRead(props.matterId, [notificationId], isRead)
    }

    const emit = defineEmits<{
        (e: 'splash-button-click', active: boolean)
        (e: 'clear')
        (e: 'item-check', item: IOptionLinkItem)
        (e: 'refresh')
        (e: 'sort-by', value: string)
    }>()

    const fetch = async () => {
        await assetMonitoringStore.fetchNotifications(props.matterId)
    }

    defineExpose({
        fetch,
    })

    watch(() => props.matterId, (value, oldValue) => {
        if (value !== oldValue) {
            emit('clear')
        }
    })

    watch(() => loading, (val) => {
        if (val) {
            setTimeout(() => {
                loading.value = false
            }, 500)
        }
    }, {
        immediate: true,
    })
</script>

<style scoped lang="scss">
@import './am-alerts';
</style>
