<template>
    <div class="title-alerts-preferences d-flex flex-column gr-4">
        <div v-if="!hideHeader"
             class="monitoring-preferences__instructions">
            <p v-t="'assetMonitoring.preferences.instructions'"
               class="body-regular" />
        </div>

        <section class="monitoring-preferences__content">
            <preference-presets-card :settings="settings"
                                     class="monitoring-preferences__content--row"
                                     data-test="orbital-recommended-preference-card"
                                     data-track="Asset Monitoring: Orbital recommended preference card"
                                     @update="updateSettings" />

            <preference-details-card :description="t('assetMonitoring.preferences.cards.preferenceDetails.cardDescription')"
                                     :is-loading="isUpdating"
                                     :preference-properties="preferenceProperties"
                                     :settings="settings"
                                     :title="t('assetMonitoring.preferences.cards.preferenceDetails.cardTitle')"
                                     class="mb-1"
                                     @update="updateSettings" />

            <title-group-card v-if="isTitleGroupsEnabled"
                              :titles="titles"
                              :settings="settings"
                              @update="updateSettings" />
        </section>
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        onMounted,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useRoute } from 'vue-router'
    import { useStore } from 'vuex'

    import { ITitleNotificationSettings } from '@/api/notifications.api'
    import PreferenceDetailsCard from '@/components/asset-monitoring/monitoring-preferences/preference-details-card.vue'
    import { IPreferenceDetailsModel } from '@/components/asset-monitoring/monitoring-preferences/preference-details-model-interface'
    import PreferencePresetsCard from '@/components/asset-monitoring/monitoring-preferences/title-preference-presets-card.vue'
    import { ITitleGroup } from '@/components/asset-monitoring/title-groups/title-group.model'
    import TitleGroupCard from '@/components/asset-monitoring/title-groups/title-group-card.vue'
    import { checkFlag } from '@/feature-flags'
    import { MATTER_GET_ASSET_MONITORING_TITLES } from '@/store/modules/matter/types'
    import { useAssetMonitoringStore } from '@/stores/asset-monitoring'

    const { t } = useI18n()
    const route = useRoute()
    const store = useAssetMonitoringStore()
    const globalStore = useStore()
    const currentMatterId = computed<number>(() => Number(route.params.matterId))
    const settings = computed<ITitleNotificationSettings>(() => store.titleNotificationSettings)
    const isUpdating = computed<boolean>(() => store.isUpdatingSettings)

    defineProps<{
        hideHeader?: boolean
    }>()

    onMounted(() => {
        if (!store.areTitleNotificationSettingsLoaded) {
            store.getTitleNotificationSettings(currentMatterId.value)
        }
    })

    const preferenceProperties: Array<IPreferenceDetailsModel> = [
        {
            value: 'trackDaylist',
        },
        {
            value: 'trackOwnership',
        },
        {
            value: 'trackBoundary',
        },
        {
            value: 'trackCompaniesHouse',
        },
        {
            value: 'trackEpc',
        },
    ]

    const updateSettings = async (request: ITitleNotificationSettings) => {
        await store.updateTitleNotificationSettings(currentMatterId.value, request)
    }

    const isTitleGroupsEnabled = computed<boolean>(() => checkFlag('asset-monitoring-title-groups', false))

    const titles = computed<ITitleGroup[]>(() => globalStore.getters[MATTER_GET_ASSET_MONITORING_TITLES])
</script>

<style lang="scss" scoped>
@import './monitoring-preferences';
</style>
