import { saveAs } from 'file-saver'

import DocumentUploadApi from '@/api/document-upload.api'
import DocumentsApi from '@/api/documents.api'
import HttpClient from '@/api/http-client'
import ReportApi from '@/api/report.api'
import SearchesApi from '@/api/searches.api'
import { IOwFileUploaderEvent } from '@/components/core/types/ow-file-uploader-event.interface'
import { Route } from '@/enums/route.enum'
import {
    uploadDocumentFailureResponses,
    UploadedDocumentStatus,
} from '@/enums/uploaded-document-status.enum'
import { UploadedDocumentType } from '@/enums/uploaded-document-type.enum'
import { IUploadedDocument } from '@/interfaces/uploaded-document.interface'
import i18n from '@/plugins/i18n'
import router from "@/router"
import { CHECKOUT_MUTATE_ADD_DOWNLOADED_REPORT } from '@/store/modules/checkout/types'
import {
    ADD_DOCUMENT_TO_MATTER,
    CANCEL_LAST_DOWNLOAD_REQUEST,
    DOCUMENTS_ASYNC_GENERATE_TAR,
    DOCUMENTS_ASYNC_TAR_COMPLETE,
    DOCUMENTS_DOWNLOAD_UPLOADED_DOCUMENT,
    DOCUMENTS_FETCH_SEARCHES_DOCUMENTS,
    DOCUMENTS_FETCH_UPLOAD_STATUS,
    DOCUMENTS_FETCH_UPLOADED_DOCUMENTS,
    DOCUMENTS_MUTATE_ADD_DOCUMENT,
    DOCUMENTS_MUTATE_REMOVE_TITLE_ANALYSIS_REPORT_REQUEST,
    DOCUMENTS_MUTATE_UPLOADED_DOCS_LOADING,
    DOCUMENTS_MUTATE_UPLOADED_DOCUMENTS,
    DOCUMENTS_MUTATE_UPLOADED_DOCUMENTS_FROM_MESSAGE,
    DOCUMENTS_MUTATE_UPLOADED_DOCUMENTS_STATUS,
    DOCUMENTS_REMOVE_DOCUMENT,
    DOCUMENTS_REMOVE_UPLOADED_DOCUMENT,
    DOCUMENTS_REMOVE_UPLOADED_DOCUMENTS,
    DOCUMENTS_REPLACE_SEARCHES_DOCUMENTS,
    DOCUMENTS_REPLACE_UPLOADED_DOCUMENTS,
    DOCUMENTS_UPLOAD_FILES,
    DOWNLOAD_DOCUMENT,
    DOWNLOAD_MANY,
    GENERATE_DOCX_AND_DOWNLOAD,
    GENERATE_REPORT_DOCX_AND_DOWNLOAD,
    GET_LIBRARY_PENDING_DOCUMENTS,
    HANDLE_ORDER_RESPONSE,
    MUTATE_DOCUMENT_ORDER_ERROR,
    ORDER_COPY_FILED_DOCUMENT,
    ORDER_MULTIPLE_COPY_FILED_DOCUMENTS,
    POLL_FOR_UPDATES,
    REFRESH_DOCUMENTS,
    REMOVE_SINGLE_DOCUMENT_FROM_MATTER,
    REMOVE_SINGLE_UPLOADED_DOCUMENT_FROM_MATTER,
    REMOVE_UPLOADED_DOCUMENTS_FROM_MATTER,
    SET_LIBRARY_LOADING,
    SET_LIBRARY_PROPERTIES,
    SET_NEW_LIBRARY_DOCUMENTS,
    SET_UPDATED_DOCUMENTS,
} from '@/store/modules/documents/documents-types'
import {
    MATTER_RESET_CURRENT,
    MATTER_UPDATE_CURRENT_MATTER_CHARGES,
} from '@/store/modules/matter/types'
import { ITitleAnalysisReportGeneratedNotification } from "@/store/modules/matter-hub/interfaces/title-analysis-report-generated.notification.interface"
import {
    IUploadedDocumentStatusUpdatedNotification,
} from '@/store/modules/matter-hub/interfaces/uploaded-document-status-updated-notification.interface'
import { ACTION_FROM_MATTER_HUB_DOCUMENT_UPLOAD_STATUS_UPDATED_MESSAGE,
    ACTION_FROM_MATTER_HUB_TITLE_ANALYSIS_REPORT_GENERATED_MESSAGE } from '@/store/modules/matter-hub/types'
import { NOTIFICATIONS_DISPLAY_NOTIFICATION } from '@/store/modules/notifications/types'
import { INotification } from '@/store/modules/notifications/types/notification.interface'
import { TITLE_MUTATE_UPDATED_TITLE_DOCUMENTS } from '@/store/modules/titles/types'
import {
    LOGGING_INTERCOM_TRACK_EVENT,
    LOGGING_LOG_AXIOS_ERROR,
    LOGGING_LOG_FEATURE_USAGE,
    OPEN_WINDOW,
} from '@/store/mutation-types'
import { useCoreComponentStore } from "@/stores/core"
import {
    dynamicSort,
    isNullOrEmpty,
} from '@/utils/array-utils'
import { format } from '@/utils/date-utils'
import { DocumentTypes } from '@/utils/document-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

export default {

    /**
     * Update the document library store of documents, or update only a subset
     * @param commit
     * @param rootState
     * @param documents - zero or more documents from the document
     *     library
     */
    async [REFRESH_DOCUMENTS]({
        commit,
        rootState,
    }, documents: Array<any>) {
        if (!rootState.matter.currentMatter.id) {
            return
        }

        const partialRefresh = !isNullOrEmpty(documents)
        let result: Array<any>
        commit(SET_LIBRARY_LOADING, true)

        if (partialRefresh) {
            const matterDocumentIds = documents.map((document: any) => document.matterDocumentId)
            result = await DocumentsApi.getDocumentsByMatterDocumentIds(matterDocumentIds, rootState.matter.currentMatter.id)
        } else {
            result = await DocumentsApi.getAll(rootState.matter.currentMatter.id)
        }
        if (partialRefresh) {
            commit(SET_UPDATED_DOCUMENTS, result)
        } else {
            // Temp code to add additional properties not yet populated by API
            result.forEach(doc => {
                doc.groupId = null
                doc.groupName = null
                doc.documentPending = doc.documentStatus === 'Pending...'
                // TODO: Create mapping of properties to a single document
                // status?
            })
            commit(SET_LIBRARY_PROPERTIES, { documents: result })
        }
        commit(SET_LIBRARY_LOADING, false)
    },

    /**
     * Update the document library store of documents, or update only a subset
     * @param commit {Function}
     * @param dispatch {Function}
     * @param rootState
     * @param {Object} request - {titleNumber: , documentTypeField: ,
     *     documentDate: , filedUnderField: , show: }
     */
    async [ORDER_COPY_FILED_DOCUMENT]({
        commit,
        dispatch,
        rootState,
    }, request) {
        request.document.loading = true
        request.matterId = rootState.matter.currentMatter.id

        const response = await DocumentsApi.orderDocument(request)
        const doc = await dispatch(HANDLE_ORDER_RESPONSE, {
            request,
            response,
        })
        commit(DOCUMENTS_MUTATE_ADD_DOCUMENT, doc)

        // Poll for document updates
        await dispatch(POLL_FOR_UPDATES)

        request.document.loading = false

        return doc
    },

    async [GENERATE_DOCX_AND_DOWNLOAD]({
        commit,
        dispatch,
        rootState,
    }, titleNumbers: string[]) {
        try {
            const response = await ReportApi.generateDOCXAndDownload({ titleNumbers })
            if (response.ok === true) {
                const filenameMetadata = titleNumbers.length === 1
                    ? titleNumbers[0]
                    : rootState.matter.currentMatter.id
                        ? rootState.matter.currentMatter.name.replace(/[^a-z\d- ]/gi, '_')
                        : `${ titleNumbers.length } titles`
                const timeStamp = rootState.matter.currentMatter.id ? '' : format(Date(), ' - dd-MM-yy')
                const filename = `Title register extract on ${ filenameMetadata }${ timeStamp }.docx`
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
                const downloadedReport = {
                    blobDownloadUrl: blob,
                    filename,
                }
                commit(`checkout/${ CHECKOUT_MUTATE_ADD_DOWNLOADED_REPORT }`, downloadedReport, { root: true })

                saveAs(blob, filename)
            } else {
                throw new Error('An error was encountered while generating the report.')
            }
        } catch (error) {
            if (!HttpClient.isCancelledError(error)) {
                console.error(error)
            }
            throw error
        } finally {
            await dispatch(LOGGING_LOG_FEATURE_USAGE, {
                type: 'title-register-extract',
                description: null,
            }, { root: true })
        }
    },

    async [GENERATE_REPORT_DOCX_AND_DOWNLOAD]({
        commit,
    }, {
        template,
        matterId,
        titleNumbers,
        disabledFeatures = [],
    }) {
        try {
            const response = await ReportApi.generateReportDOCXAndDownload({
                titleNumbers,
                template: template.path || template.template,
                matterId,
                disabledFeatures,
            })
            if (response?.ok === true) {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
                const filename = response.headers['content-disposition']?.split('=')[1]?.replaceAll('"', '') || 'report.docx'
                const downloadedReport = {
                    blobDownloadUrl: blob,
                    filename,
                }
                commit(`checkout/${ CHECKOUT_MUTATE_ADD_DOWNLOADED_REPORT }`, downloadedReport, { root: true })

                saveAs(blob, filename)
            } else {
                throw new Error('An error was encountered while generating the report.')
            }
        } catch (error) {
            if (!HttpClient.isCancelledError(error)) {
                console.error(error)
            }
            throw error
        }
    },

    [CANCEL_LAST_DOWNLOAD_REQUEST]() {
        ReportApi.cancelLastRequest()
    },

    async [ORDER_MULTIPLE_COPY_FILED_DOCUMENTS]({
        dispatch,
        rootState,
    }, request) {
        const titleNumber = request.titleNumber
        request.documents.forEach(doc => {
            doc.loading = true
        })
        request.matterId = rootState.matter.currentMatter.id

        const responses = await DocumentsApi.orderMultipleDocuments(request)

        if (!isNullOrEmpty(responses)) {
            for (const [index, document] of request.documents.entries()) {
                const request = {
                    titleNumber,
                    document,
                    show: false,
                }

                await dispatch(HANDLE_ORDER_RESPONSE, {
                    request,
                    response: responses[index],
                })
            }

            // Poll for document updates
            await dispatch(POLL_FOR_UPDATES)
        }
    },

    async [DOWNLOAD_DOCUMENT]({ rootState }, document) {
        if (DocumentTypes.NOT_COPY_FILED.has(document.documentType)) {
            await DocumentsApi.downloadOtherDocument(document.documentDownloadUrl, rootState.matter.currentMatter.id)
        } else {
            await DocumentsApi.downloadCopyFiled(document.filename)
        }
    },

    async [POLL_FOR_UPDATES]({
        dispatch,
        getters,
    }) {
        // Get pending matter document documents
        const pendingDocuments = getters[GET_LIBRARY_PENDING_DOCUMENTS]
        if (pendingDocuments.length > 0) {
            await dispatch(REFRESH_DOCUMENTS, pendingDocuments)

            setTimeout(() => {
                dispatch(POLL_FOR_UPDATES)
            }, 5000)
        }
    },

    async [DOWNLOAD_MANY]({
        dispatch,
        rootState,
    }, request) {
        // Intercom track download request
        const eventMetadata = {
            documentTypes: request
                .map(d => d.documentType)
                .toString(),
            documentIds: request
                .map(d => d.documentId)
                .toString(),
        }
        await dispatch(LOGGING_INTERCOM_TRACK_EVENT, {
            type: 'bulk-import',
            metadata: eventMetadata,
        }, { root: true })
        request.matterId = rootState.matter.currentMatter.id
        await DocumentsApi.downloadDocuments(request)
    },

    async [HANDLE_ORDER_RESPONSE]({
        commit,
        dispatch,
        state,
    }, {
        response,
        request,
    }) {
        if (response.data.ok) {
            // the document is not pending
            request.document.filename = response.data.filename
            request.document.documentId = response.data.documentId
            request.document.augmentedFilename = response.data.augmentedFilename
            request.document.loading = false
            request.document.documentPending = false
            request.document.matterDocumentId = response.data.matterDocumentId

            if (request.show === true) {
                const resultDocumentURL = `${ state.BASE_DOCUMENT_URL }${ response.data.filename }`
                await dispatch(OPEN_WINDOW, resultDocumentURL, { root: true })
            }
        } else if (!isNullOrWhitespace(response.data.rejection)) {
            // There was an error, user should not expect a document in any form
            request.document.documentError = true
            commit(MUTATE_DOCUMENT_ORDER_ERROR, `<p>${ response.data.message }</p>`)
        } else {
            // the document is pending in some way with HMLR; probably - back-end code isn't reliable
            request.document.documentPending = true
            if (response.data?.message === null) {
                response.data.message = 'We are unable to retrieve this document from HMLR.'
            }
            let errorMessage = `<p>${ response.data.message } (${ request.document.documentType }/${ request.document.documentDate }`
            if (!isNullOrWhitespace(request.document.filedUnderField)) {
                errorMessage += `/${ request.document.filedUnderField }`
            }
            errorMessage += ')</p>'
            commit(MUTATE_DOCUMENT_ORDER_ERROR, errorMessage)
        }
        request.document.loading = false

        if (response.data.ok) {
            // Update the document library
            commit(SET_NEW_LIBRARY_DOCUMENTS, [request.document])
            // Update the document in the possibly selected title
            commit(TITLE_MUTATE_UPDATED_TITLE_DOCUMENTS, [request.document], { root: true })
        }
        // Update charges
        await dispatch(MATTER_UPDATE_CURRENT_MATTER_CHARGES, null, { root: true })

        return request.document
    },

    async [ADD_DOCUMENT_TO_MATTER]({ dispatch }, { matterId, documentType, documentId }) {
        if (!matterId) {
            // No matter Id provided, not applicable.
            return
        }
        try {
            await DocumentsApi.addDocumentToMatter(documentType, documentId, matterId)
        } catch (e) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, e)
        }
    },

    async [DOCUMENTS_FETCH_UPLOADED_DOCUMENTS]({ commit }, matterId: number) {
        if (matterId) {
            commit(DOCUMENTS_MUTATE_UPLOADED_DOCS_LOADING, true)
            try {
                const response = await DocumentUploadApi.getByMatterId(matterId)
                if (response.ok) {
                    commit(DOCUMENTS_REPLACE_UPLOADED_DOCUMENTS, response.data)
                }
            } catch (e) {
                const message = `Unable to get uploaded documents for matter ${ matterId }`
                console.error(message, e)
            }
            commit(DOCUMENTS_MUTATE_UPLOADED_DOCS_LOADING, false)
        }
    },

    async [DOCUMENTS_DOWNLOAD_UPLOADED_DOCUMENT](_, { matterId, documentId }) {
        if (matterId && documentId) {
            try {
                await DocumentUploadApi.downloadDocumentById(matterId, documentId)
            } catch (e) {
                const message = `Unable to get uploaded documents for matter ${ matterId }`
                console.error(message, e)
            }
        }
    },

    async [DOCUMENTS_FETCH_UPLOAD_STATUS]({ commit }, matterId: number) {
        if (matterId !== null) {
            try {
                const response = await DocumentUploadApi.getByMatterId(matterId)
                if (response.ok) {
                    const docs = response.data
                    commit(DOCUMENTS_MUTATE_UPLOADED_DOCUMENTS_STATUS, docs)
                }
            } catch (e) {
                const message = `Unable to get uploaded documents for matter ${ matterId }`
                console.error(message, e)
            }
        }
    },

    async [ACTION_FROM_MATTER_HUB_TITLE_ANALYSIS_REPORT_GENERATED_MESSAGE]({commit, state, dispatch}, messageData: ITitleAnalysisReportGeneratedNotification){
        if (state.titleAnalysisReportRequests.includes(messageData.requestId)) {
            dispatch(DOCUMENTS_ASYNC_TAR_COMPLETE)
            await DocumentsApi.downloadDocumentByTypeAndId('Spreadsheet',messageData.documentId)
            commit(DOCUMENTS_MUTATE_REMOVE_TITLE_ANALYSIS_REPORT_REQUEST, messageData.requestId)
        }

    },

    async [ACTION_FROM_MATTER_HUB_DOCUMENT_UPLOAD_STATUS_UPDATED_MESSAGE]({ commit, dispatch, state, rootState }, messageData: IUploadedDocumentStatusUpdatedNotification) {
        if (isNullOrEmpty(state.library.uploadedDocuments)) {
            return
        }

        // Is message for any documents currently being uploaded?
        const documentsForThisMessage: IUploadedDocument[] = state.library.uploadedDocuments.filter((d: IUploadedDocument) => d.documentUploadRequestId === messageData.uploadId)
        if (isNullOrEmpty(documentsForThisMessage)) {
            return
        }

        commit(DOCUMENTS_MUTATE_UPLOADED_DOCUMENTS_FROM_MESSAGE, messageData)

        const isUploadComplete = documentsForThisMessage
            .every((doc: IUploadedDocument) => (
                doc.status === UploadedDocumentStatus.complete) ||
                uploadDocumentFailureResponses.includes(doc.status))

        if (isUploadComplete) {
            const failedUploadsCount = documentsForThisMessage
                .filter((doc: IUploadedDocument) => uploadDocumentFailureResponses.includes(doc.status))
                .length
            const successfullyUploadedDocs = documentsForThisMessage
                .filter((doc: IUploadedDocument) => doc.status === UploadedDocumentStatus.complete)
            const uploadedRegistersCount = successfullyUploadedDocs
                .filter((doc: IUploadedDocument) =>
                    doc.type === UploadedDocumentType.TitleRegister
                    || doc.type == UploadedDocumentType.CautionTitle &&
                    doc.status === UploadedDocumentStatus.complete)
                .length
            const scotLISTitleSheetCount = successfullyUploadedDocs.filter((doc: IUploadedDocument) =>
                doc.type === UploadedDocumentType.ScotLISTitleSheet).length

            let titlesMessage = null
            if (uploadedRegistersCount > 0) {
                titlesMessage = i18n.global.t('documents.upload.notifications.titlesAddedToMatter', uploadedRegistersCount)
            }
            if (scotLISTitleSheetCount > 0) {
                if (titlesMessage != null) {
                    titlesMessage += '\n' + i18n.global.t('documents.upload.notifications.scotLISTitleSheetError', scotLISTitleSheetCount)
                } else {
                    titlesMessage = i18n.global.t('documents.upload.notifications.scotLISTitleSheetError', scotLISTitleSheetCount)
                }
            }

            let notification = null
            const successMsg = i18n.global.t('documents.upload.notifications.completedSuccessfully', successfullyUploadedDocs.length)
            const failureMsg = i18n.global.t('documents.upload.notifications.completeWithErrors', failedUploadsCount)

            // any duplicates?
            const duplicates = documentsForThisMessage.filter(d => d?.status === UploadedDocumentStatus.duplicate)
            let duplicatesHeading = null
            let duplicateFiles = null
            let maxReportableDuplicates: IUploadedDocument[] = []
            if (!isNullOrEmpty(duplicates)) {
                maxReportableDuplicates = duplicates.slice(0, 10)
                duplicateFiles = maxReportableDuplicates.map(d => `<li>${ d.fileName }</li>`).join('')
                duplicatesHeading = i18n.global.t('documents.upload.notifications.completeWithDuplicates', {
                    count: duplicates.length,
                })
            }

            const translationKey = duplicates.length > maxReportableDuplicates.length ? 'documents.upload.notifications.duplicatesMessageAndMore' : 'documents.upload.notifications.duplicatesMessage'
            const duplicatesToasterMessage = !isNullOrEmpty(duplicates) ? i18n.global.t(translationKey, {
                files: duplicateFiles,
                additionalFileCount: duplicates.length - maxReportableDuplicates.length,
            }) : null

            if (failedUploadsCount === 0) {
                // All succeeded
                notification = {
                    toaster: {
                        isSuccess: true,
                        heading: successMsg,
                        message: titlesMessage,
                    },
                }
            } else if (documentsForThisMessage.length === failedUploadsCount) {
                const heading = `${ failureMsg }${ duplicatesHeading ? ` (${ duplicatesHeading })` : '' }`
                notification = {
                    toaster: {
                        isWarning: true,
                        heading,
                        message: [titlesMessage,duplicatesToasterMessage].filter(Boolean).join('<br /><br />'),
                    },
                }
            } else {
                // Mix of success and failures
                const heading = `${ successMsg }${ failureMsg ? `, ${ failureMsg }` : '' }${ duplicatesHeading ? ` (${ duplicatesHeading })` : '' }`
                notification = {
                    toaster: {
                        isWarning: true,
                        heading,
                        message: [titlesMessage,duplicatesToasterMessage].filter(Boolean).join('<br /><br />'),
                    },
                }
            }

            await dispatch(NOTIFICATIONS_DISPLAY_NOTIFICATION, notification, { root: true })

            // TODO: This is a hack to avoid resetting the matter when in doc library / adding documents overlays
            if (router.currentRoute.value.name === Route.MatterMap) {
                await dispatch(MATTER_RESET_CURRENT, {}, { root: true })
            }
        }
    },

    async [DOCUMENTS_ASYNC_TAR_COMPLETE]({ dispatch }) {
        let notification: INotification = null
        notification = {
            toaster: {
                isSuccess: true,
                message: i18n.global.t('documents.tar.notifications.complete'),
            },
        }
        await dispatch(NOTIFICATIONS_DISPLAY_NOTIFICATION, notification, { root: true })
    },

    async [DOCUMENTS_ASYNC_GENERATE_TAR]({ dispatch }) {
        let notification: INotification = null
        notification = {
            toaster: {
                isInProgress: true,
                message: i18n.global.t('documents.tar.notifications.processing'),
            },
        }
        await dispatch(NOTIFICATIONS_DISPLAY_NOTIFICATION, notification, { root: true })
    },

    async [DOCUMENTS_UPLOAD_FILES]({ commit, dispatch, rootState }, filesToUpload: IOwFileUploaderEvent) {
        let notification: INotification = null

        try {
            await dispatch(NOTIFICATIONS_DISPLAY_NOTIFICATION, {
                toaster: {
                    isInProgress: true,
                    heading: i18n.global.t('documents.upload.notifications.uploading'),
                },
            }, { root: true })

            const response = await DocumentUploadApi.uploadInBatches(
                rootState.matter.currentMatter.id,
                filesToUpload.formData,
                10,
                this.matterGroupId,
                false)
            commit(DOCUMENTS_MUTATE_UPLOADED_DOCUMENTS, response?.data?.documents.filter(d => d))

            // Check if some are processing. Otherwise, assume all have errors and can show the error notification
            if (response?.data?.documents?.some(d => d?.status === UploadedDocumentStatus.processing)) {
                notification = {
                    toaster: {
                        isInProgress: true,
                        heading: i18n.global.t('documents.upload.notifications.processing'),
                    },
                }
            } else {
                const failureMsg = i18n.global.t('documents.upload.notifications.completeWithErrors', {
                    count: filesToUpload.filesCount,
                })

                // any duplicates?
                const duplicates = response?.data?.documents?.filter(d => d?.status === UploadedDocumentStatus.duplicate)
                let duplicatesMsg = null
                let duplicateFiles = null
                let maxReportableDuplicates: IUploadedDocument[] = []
                if (!isNullOrEmpty(duplicates)) {
                    maxReportableDuplicates = duplicates.slice(0, 10)
                    duplicateFiles = maxReportableDuplicates.map(d => `<li>${ d.fileName }</li>`).join('')
                    duplicatesMsg = i18n.global.t('documents.upload.notifications.completeWithDuplicates', {
                        count: duplicates.length,
                    })
                }
                const heading = `${ failureMsg }${ duplicatesMsg ? ` (${ duplicatesMsg })` : '' }`

                const translationKey = duplicates.length > maxReportableDuplicates.length ? 'documents.upload.notifications.duplicatesMessageAndMore' : 'documents.upload.notifications.duplicatesMessage'
                const message = !isNullOrEmpty(duplicates) ? i18n.global.t(translationKey, {
                    files: duplicateFiles,
                    additionalFileCount: duplicates.length - maxReportableDuplicates.length,
                }) : null

                notification = {
                    toaster: {
                        isWarning: true,
                        heading,
                        message,
                    },
                }
            }
            await dispatch(NOTIFICATIONS_DISPLAY_NOTIFICATION, notification, { root: true })
        } catch (error) {
            console.error(error)
            const notification: INotification = {
                toaster: {
                    isWarning: true,
                    heading: i18n.global.t('documents.upload.notifications.completeWithErrors', [filesToUpload.filesCount]),
                },
            }
            await dispatch(NOTIFICATIONS_DISPLAY_NOTIFICATION, notification, { root: true })
        }
    },

    async [DOCUMENTS_FETCH_SEARCHES_DOCUMENTS]({ commit }, matterId: number) {
        if (matterId !== null) {
            try {
                const response = await SearchesApi.getByMatterId(matterId)
                if (response.ok) {
                    commit(DOCUMENTS_REPLACE_SEARCHES_DOCUMENTS, response.data.documents.sort(dynamicSort('fileName')))
                }
            } catch (e) {
                const message = `Unable to get search documents for matter ${ matterId }`
                console.error(message, e)
            }
        }
    },

    async [REMOVE_SINGLE_DOCUMENT_FROM_MATTER]({ dispatch, commit }, { matterId, documentId, documentType }) {
        if (!matterId || !documentId || !documentType) {
            return
        }
        try {
            await DocumentsApi.removeDocumentFromMatter(matterId, documentId, documentType)
            commit(DOCUMENTS_REMOVE_DOCUMENT, documentId)
            const coreComponentStore = useCoreComponentStore()
            coreComponentStore.updateResetPageAfterUpdate(false)

        } catch (e) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, e)
        }
    },

    async [REMOVE_SINGLE_UPLOADED_DOCUMENT_FROM_MATTER]({ dispatch, commit }, { matterId, documentId }) {
        if (!matterId || !documentId) {
            return
        }
        try {
            await DocumentUploadApi.removeUploadedDocumentFromMatter(matterId, documentId)
            commit(DOCUMENTS_REMOVE_UPLOADED_DOCUMENT, documentId)
            const coreComponentStore = useCoreComponentStore()
            coreComponentStore.updateResetPageAfterUpdate(false)
        } catch (e) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, e)
        }
    },

    async [REMOVE_UPLOADED_DOCUMENTS_FROM_MATTER]({ dispatch, commit }, { matterId, documentIds }) {
        if (!matterId || isNullOrEmpty(documentIds)) {
            return
        }
        try {
            await DocumentUploadApi.removeUploadedDocumentsFromMatter(matterId, documentIds)
            commit(DOCUMENTS_REMOVE_UPLOADED_DOCUMENTS, documentIds)
            const coreComponentStore = useCoreComponentStore()
            coreComponentStore.updateResetPageAfterUpdate(false)
        } catch (e) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, e)
        }
    },
}

