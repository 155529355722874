<template>
    <section class="am-summary">
        <div class="am-summary__side">
            <h3 v-if="hasTitles"
                v-t="'assetMonitoring.text.titles'"
                class="label-caps-smallest" />
            <ul v-if="hasTitles"
                class="am-summary__side--items-container">
                <li v-if="isLoading">
                    <ow-progress color="secondary"
                                 size="small" />
                </li>
                <li v-for="title in monitoredTitles"
                    v-else
                    :key="title.id">
                    <ow-button class="d-inline-flex"
                               is-link
                               @click="onSelectSummaryItem(title)">
                        <span :class="{ 'selected': title.id === selected?.id}"
                              class="body-highlight">{{ title.label }}</span>
                    </ow-button>
                </li>
            </ul>

            <h3 v-if="hasCompanies"
                v-t="'assetMonitoring.text.companies'"
                class="label-caps-smallest" />
            <ul v-if="hasCompanies"
                class="am-summary__side--items-container">
                <li v-if="isLoading">
                    <ow-progress color="secondary"
                                 size="small" />
                </li>
                <li v-for="company in monitoredCompanies"
                    v-else
                    :key="company.id">
                    <ow-button class="d-inline-flex"
                               is-link
                               @click="onSelectSummaryItem(company)">
                        <span :class="{ 'selected': company.id === selected?.id}"
                              class="body-highlight">{{ company.label }}</span>
                    </ow-button>
                </li>
            </ul>
        </div>
        <div class="am-summary__main body-regular">
            <div v-dompurify-html="t('assetMonitoring.text.changesSummary', {
                     changes: t('assetMonitoring.text.change', { count: numberOfChanges }),
                     titles: t('assetMonitoring.text.title', { count: numberOfTitles }),
                     companies: t('assetMonitoring.text.company', { count: numberOfCompanies })
                 })"
                 class="am-summary__header" />
            <div class="am-summary__feed">
                <data-grid :items="filteredNotifications"
                           :matter-id="props.matterId"
                           :is-loading="isLoading"
                           :auto-load-more-data="false"
                           :expand-all-rows="true" />
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import {
        computed,
        onActivated,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import {
        IGetNotificationResponseItem,
        ISummaryItem,
    } from '@/api/notifications.api'
    import { IAssetMonitoringNotification } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import DataGrid from '@/components/asset-monitoring/grid/data-grid.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwProgress from '@/components/core/ow-progress.vue'
    import { useAssetMonitoringStore } from '@/stores/asset-monitoring'
    import { mapNotificationResponseToAssetMonitoringItem } from '@/stores/asset-monitoring/getters'
    import { isNullOrEmpty } from '@/utils/array-utils'

    const store = useAssetMonitoringStore()
    const { t } = useI18n()

    const props = defineProps<{
        matterId: number
    }>()

    const isLoading = computed<boolean>(() => store.isLoading)
    const startDate = computed<Date>(() => store.getEarliestUnreadAlertDate)
    const numberOfChanges = computed<number>(() => store.summaryTotalChanges)
    const notifications = computed<IGetNotificationResponseItem[]>(() => store.summaryNotifications)

    const monitoredTitles = computed<ISummaryItem[]>(() => store.getSummaryTitleNumbers)
    const numberOfTitles = computed<number>(() => monitoredTitles.value?.length)
    const hasTitles = computed(() => !isNullOrEmpty(monitoredTitles.value))

    const monitoredCompanies = computed<ISummaryItem[]>(() => store.getSummaryCompanies)
    const numberOfCompanies = computed<number>(() => monitoredCompanies.value?.length)
    const hasCompanies = computed(() => !isNullOrEmpty(monitoredCompanies.value))

    const beforeDate = ref<Date>(new Date()) // TODO: get the dates through the UI
    const selected = ref<ISummaryItem>(null)
    const filteredNotifications = ref<IAssetMonitoringNotification[]>([])

    const fetchData = async () => {
        await store.fetchSummaryNotifications(props.matterId, startDate.value, beforeDate.value)
    }

    const filterNotifications = () => {
        // filter the notifications based on the selected item
        filteredNotifications.value = notifications.value
            .filter((notification: IGetNotificationResponseItem) =>
                notification?.companyNumbers?.includes(selected.value?.id) || notification?.titleNumber === selected.value?.id)
            .map((notification: IGetNotificationResponseItem) => mapNotificationResponseToAssetMonitoringItem(notification))
    }

    const onSelectSummaryItem = async (item: ISummaryItem) => {
        selected.value = item
        // do filtering here
        filterNotifications()
    }


    // Todo: Disable the watch for now as it causes havoc with unread notifications
    // watch(startDate, fetchData)
    watch(notifications, () => {
        filterNotifications()
    }, { immediate: true })

    watch(monitoredTitles, async () => {
        // Set the initial selected item
        if (selected.value === null
            && !isNullOrEmpty(monitoredTitles.value)) {
            await onSelectSummaryItem(monitoredTitles.value[0])
        }
    })
    watch(monitoredCompanies, async () => {
        // Set the initial selected item
        if (selected.value === null
            && isNullOrEmpty(monitoredTitles.value)
            && !isNullOrEmpty(monitoredCompanies.value)) {
            // only set this if the titles are empty. Titles would be selected over the companies.
            await onSelectSummaryItem(monitoredCompanies.value[0])
        }
    })

    onActivated(async () => {
        await fetchData()
    })
</script>

<style lang="scss" scoped>
@import './am-summary';
</style>
