<template>
    <div class="company-alerts-preferences d-flex flex-column gr-4">
        <section v-if="!hideHeader"
                 class="d-flex align-center justify-space-between gc-4">
            <p v-t="'assetMonitoring.preferences.companyGroupsMonitoring'"
               class="body-regular" />
            <ow-button is-primary
                       @click="onAddGroup">
                {{ $t('action.addCompanies') }}
            </ow-button>
        </section>
        <section class="monitoring-preferences__content">
            <preference-presets-card v-if="!isNullOrEmpty(companyGroups)"
                                     :settings="settings"
                                     class="monitoring-preferences__content--row"
                                     data-track="Asset Monitoring: Orbital recommended preference card"
                                     data-test="orbital-recommended-preference-card"
                                     @update="updateSettings" />

            <preference-details-card v-if="!isNullOrEmpty(companyGroups)"
                                     :is-loading="isUpdating"
                                     :settings="settings"
                                     class="monitoring-preferences__content--row"
                                     :preference-properties="preferenceProperties"
                                     :title="t('assetMonitoring.preferences.cards.preferenceDetails.cardTitle')"
                                     :description="t('assetMonitoring.preferences.cards.preferenceDetails.cardDescription')"
                                     @update="updateSettings" />
            <company-groups-card :matter-id="matterId"
                                 class="mb-1" />
        </section>
    </div>
</template>

<script setup lang="ts">

    import {
        computed,
        onActivated,
        onMounted,
        ref,
    } from "vue"
    import { useI18n } from "vue-i18n"
    import {
        useRoute,
        useRouter,
    } from "vue-router"

    import CompanyGroupsApi, {
        IGetCompanyGroupResponse,
        IGetCompanyGroupsResponse,
    } from "@/api/company-groups.api"
    import { ICompanyNotificationSettings } from "@/api/company-notifications"
    import CompanyGroupsCard from "@/components/asset-monitoring/monitoring-preferences/company-groups-card.vue"
    import PreferencePresetsCard
        from "@/components/asset-monitoring/monitoring-preferences/company-preference-presets-card.vue"
    import PreferenceDetailsCard
        from "@/components/asset-monitoring/monitoring-preferences/preference-details-card.vue"
    import { IPreferenceDetailsModel } from "@/components/asset-monitoring/monitoring-preferences/preference-details-model-interface"
    import OwButton from "@/components/core/ow-button-ds.vue"
    import { Route } from "@/enums/route.enum"
    import { useAssetMonitoringStore } from "@/stores/asset-monitoring"
    import { isNullOrEmpty } from "@/utils/array-utils"

    const router = useRouter()
    const assetMonitoringStore = useAssetMonitoringStore()
    const isUpdating = computed<boolean>(() => assetMonitoringStore.isUpdatingSettings)
    const settings = computed<ICompanyNotificationSettings>(() => assetMonitoringStore.companyNotificationSettings)
    const companyGroups = ref<IGetCompanyGroupResponse[]>()
    const route = useRoute()
    const currentMatterId = computed<number>(() => Number(route.params.matterId))
    const { t } = useI18n()

    const props = defineProps<{
        matterId: number
        hideHeader?: boolean
    }>()

    const fetch = async () => {
        if (!assetMonitoringStore.areCompanyNotificationSettingsLoaded) {
            await assetMonitoringStore.getCompanyNotificationSettings(currentMatterId.value)
        }

        const resp = await CompanyGroupsApi.getCompanyGroups(props.matterId)
        if (resp?.data?.companyGroups) {
            companyGroups.value = resp.data.companyGroups
        }
    }

    onMounted(async () => {
        await fetch()
    })

    onActivated(async () => {
        await fetch()
    })

    const onAddGroup = () => {
        router.push({
            name: Route.AssetMonitoringCompanyGroupsCreate,
            params: {
                matterId: props.matterId,
            },
        })
    }

    const preferenceProperties: Array<IPreferenceDetailsModel> = [
        {
            value: 'trackCompaniesHouseInformation',
        },
        {
            value: 'trackCompaniesHouseInsolvency',
        },
        {
            value: 'trackCompanyTitleOwnership',
        },
    ]

    const updateSettings = async (request: ICompanyNotificationSettings) => {
        await assetMonitoringStore.updateCompanyNotificationSettings(props.matterId, request)
    }
</script>

<style lang="scss" scoped>
    @import './monitoring-preferences';
</style>
