<template>
    <base-monitoring-preference-card hide-header
                                     class="company-groups-card">
        <ow-loading-slot v-if="loading"
                         loading />
        <div v-else-if="companyGroups?.length === 0"
             class="d-flex gr-4 flex-column py-4">
            <splash-page :title="splashTitle"
                         :button-title="t('action.addCompany')"
                         :matter-id="matterId"
                         type="company"
                         @button-click="onSplashButtonClick" />
        </div>
        <template v-for="(company, index) of companyGroups"
                  v-else
                  :key="company.id">
            <company-group-card class="pa-6"
                                v-bind="company"
                                @toggle-group="onToggleGroup(company)"
                                @edit-group="onEditGroup(company)" />
            <v-divider v-if="index !== companyGroups.length - 1" />
        </template>
    </base-monitoring-preference-card>
</template>

<script lang="ts" setup>
    import {
        computed,
        onActivated,
        onMounted,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useRouter } from "vue-router"

    import CompanyGroupsApi, { IGetCompanyGroupResponse } from "@/api/company-groups.api"
    import CompanyGroupCard from "@/components/asset-monitoring/company-groups/company-group-card.vue"
    import BaseMonitoringPreferenceCard from "@/components/asset-monitoring/monitoring-preferences/base-monitoring-preference-card.vue"
    import SplashPage from "@/components/asset-monitoring/splash-page.vue"
    import OwLoadingSlot from "@/components/core/ow-loading-slot.vue"
    import { Route } from "@/enums/route.enum"
    import { useAssetMonitoringStore } from "@/stores/asset-monitoring"
    import { isNullOrEmpty } from '@/utils/array-utils'

    const props = defineProps<{
        matterId: number
    }>()

    const { t } = useI18n()
    const companyGroups = computed<IGetCompanyGroupResponse[]>(() => assetMonitoringStore.companyGroups)

    // OW Recommended card
    const loading = ref<boolean>(true)
    const router = useRouter()
    const assetMonitoringStore = useAssetMonitoringStore()

    const splashTitle = computed(() =>{
        return t('assetMonitoring.splashPage.titleCompany')
    })

    const onEditGroup = (company: IGetCompanyGroupResponse) => {
        router.push({
            name: Route.AssetMonitoringSettingsEditCompany,
            params: {
                matterId: props.matterId,
                groupId: company.id,
            },
        })
    }

    const onSplashButtonClick = () => {
        router.push({
            name: Route.AssetMonitoringSettingsAddCompany,
            params: {
                matterId: props.matterId,
            },
        })
    }

    const onToggleGroup = async (company: IGetCompanyGroupResponse) => {
        const resp = await CompanyGroupsApi.updateCompanyGroup(company.id, {
            groupName: company.groupName,
            isEnabled: !company.isEnabled,
        })
        if (resp?.ok) {
            const index = companyGroups.value.findIndex((c) => c.id === company.id)
            if (index !== -1) {
                companyGroups.value[index].isEnabled = !company.isEnabled
            }
        }

        // Reload notifications after updating settings
        assetMonitoringStore.reloadNotifications = true
    }

    const fetch = async () => {
        loading.value = true

        if (!assetMonitoringStore.areCompanyNotificationSettingsLoaded) {
            await assetMonitoringStore.getCompanyNotificationSettings(props.matterId)
        }

        if (props.matterId && isNullOrEmpty(assetMonitoringStore.companyGroups)) {
            try {
                const resp = await CompanyGroupsApi.getCompanyGroups(props.matterId)
                assetMonitoringStore.companyGroups = resp?.data?.companyGroups
            } finally {
                loading.value = false
            }
        }
    }

    onMounted(async () => {
        await fetch()
    })

    onActivated(async () => {
        await fetch()
    })

</script>

<style lang="scss" scoped>
    @import './preference-presets-card';
</style>
