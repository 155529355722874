<template>
    <row-item v-bind="props"
              v-model:is-showing-details-panel="isShowingDetailsPanel"
              has-extra-info

              :row-model="baseRowModel"
              :view-title-text="viewTitleText"

              @view-title="emit('view-title', $event)"
              @view-company="emit('view-company', $event)"
              @mark-as-read="emit('mark-as-read', $event)">
        <notification-type-renderer v-model:update-count="numberOfUpdates"
                                    :showing-details-panel="isShowingDetailsPanel"
                                    :notification-details="item.notificationDetails"
                                    :notification-type="item.notificationType"
                                    :title-number="item.titleNumber"
                                    :company-numbers="item.companyNumbers"
                                    :notification-id="item.notificationId"
                                    @area-difference="handleBoundaryArea" />
    </row-item>
</template>

<script lang="ts"
        setup>
    import {
        computed,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import { IAssetMonitoringNotification } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import {
        AssetMonitoringDataSource,
        BaseRowItemModel,
    } from "@/components/asset-monitoring/base-row-item-model"
    import NotificationTypeRenderer from "@/components/asset-monitoring/grid/notification-type-items/notification-type-renderer.vue"
    import RowItem from "@/components/asset-monitoring/grid/row-items/row-item.vue"
    import { NotificationSubType } from '@/components/asset-monitoring/notification-sub-type.enum'
    import { NotificationType } from "@/components/asset-monitoring/notification-type.enum"

    const { t } = useI18n()
    const store = useStore()
    const numberOfUpdates = ref<number>(0)
    const isShowingDetailsPanel = defineModel<boolean>()

    const props = defineProps<{
        item: IAssetMonitoringNotification,
        matterId: number,
    }>()

    const emit = defineEmits<{
        (e: 'view-title', {
            titleNumber,
            notificationType,
            notificationSubType,
        }: {
            titleNumber: string
            notificationType: NotificationType
            notificationSubType: NotificationSubType
        }),
        (e: 'view-company', {
            companyNumbers,
            notificationType,
            notificationSubType,
        }: {
            companyNumbers: string[]
            notificationType: NotificationType
            notificationSubType: NotificationSubType
        }),
        (e: 'mark-as-read', payload: { notificationId: string, isRead: boolean }),
    }>()

    const viewTitleText = computed(() => {
        if (hasCompaniesHouseNotification.value) {
            return t('assetMonitoring.subType.companiesHouseUpdated.viewTitle')
        }
        return t('action.viewTitle')
    })

    const hasCompaniesHouseNotification = computed(() => {
        return props.item.notificationType === NotificationType.CompaniesHouseChanged
    })

    const boundaryAreaDifference = ref<number>(0)
    const isBoundaryLarger = ref<boolean>(false)
    const handleBoundaryArea = (area: number) => {
        isBoundaryLarger.value = area > 0
        boundaryAreaDifference.value = area > 0 ? area : area * -1
    }

    const baseRowModel = computed((): BaseRowItemModel => {
        if (hasCompaniesHouseNotification.value) {
            return {
                title: t('assetMonitoring.subType.companiesHouseUpdated.title'),
                descriptionPath: 'assetMonitoring.subType.companiesHouseUpdated.description',
                dataSource: AssetMonitoringDataSource.COMPANIES_HOUSE,
                titleNumber: props.item.titleNumber,
                companyNumbers: props.item.companyNumbers,
            }
        }

        throw new Error(`Unable to match notification type: ${ JSON.stringify(props.item?.notificationType) })`)
    })
</script>
