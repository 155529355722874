<template>
    <div class="full-width ow-page-container title-analysis"
         data-test="title-analysis-page">
        <title-analysis-column-selection v-if="hasLoadedInitialGridState" />
        <title-analysis-template-selection v-if="hasLoadedInitialGridState" />

        <title-analysis-header v-show="!isLoading"
                               ref="analysisHeader"
                               :all-titles-purchased="allTitlesPurchased"
                               :all-unpurchased-titles="allUnpurchasedTitles"
                               :has-purchased-titles="hasPurchasedTitles"
                               :has-filter-applied="hasFilterApplied"
                               :has-template-applied="hasTemplateApplied"
                               :is-order-in-progress-for-visible-registers="isOrderInProgressForVisibleRegisters"
                               :search-result-count="searchResultCount"
                               :visible-unpurchased-titles="visibleUnpurchasedTitles"
                               @export="quickActionHandler"
                               @search="searchText = $event"
                               @next-result="searchIndex = $event"
                               @prev-result="searchIndex = $event"
                               @click="quickActionHandler" />

        <quick-actions v-show="!isLoading"
                       :has-purchased-titles="hasPurchasedTitles"
                       @click="quickActionHandler" />

        <div class="title-analysis__content">
            <div class="header">
                <div class="d-flex flex header__search--showing caption-regular"
                     data-test="title-analysis-showing-count">
                    <span v-if="!loadingData"
                          class="headers-h3-card-heading">
                        <strong>{{ titleCountTextVisibleTitles }}</strong>{{ titleCountTextAllTitles }}
                    </span>
                </div>
                <div class="header__actions">
                    <div v-if="appliedTemplate"
                         :title="appliedTemplate.description"
                         class="header__actions--applied-template-name caption-regular">
                        {{ $t('label.template') }}: <strong data-test="title-analysis-applied-template-name">
                            {{ appliedTemplate.name }}
                        </strong>
                    </div>
                    <ow-button data-test="title-analysis-show-purchased-button"
                               data-track="TAM - Show purchased titles"
                               is-secondary
                               @click="getNextFilterState()">
                        <v-icon class="mr-1">
                            {{ getDocumentFilterIcon }}
                        </v-icon>
                        {{ getDocumentFilterText }}
                    </ow-button>
                    <ow-button v-if="showClearFilterButton"
                               data-test="title-analysis-clear-filter-button"
                               data-track="TAM - Clear all filters"
                               is-secondary
                               @click="clearFilter()">
                        <v-icon>$close</v-icon>
                        {{ $t('action.clearFilter') }}
                    </ow-button>
                    <ow-button v-if="showFilterButton"
                               class="header__actions--filter"
                               :is-highlight="hasFilterApplied"
                               data-test="title-analysis-toggle-filter-button"
                               data-track="TAM - Show filter bar"
                               is-secondary
                               @click="showFilterBar = !showFilterBar">
                        <template #iconPrefix>
                            <v-icon>
                                $filter
                            </v-icon>
                        </template>

                        <span v-show="showFilterBar">Hide Filter</span>
                        <span v-show="!showFilterBar">Filter</span>
                        <span v-show="hasFilterApplied"> *</span>
                    </ow-button>

                    <ow-button v-if="appliedTemplate"
                               class="has-filter"
                               data-test="title-analysis-clear-template-button"
                               data-track="TAM - Clear template"
                               is-secondary
                               @click="clearTemplate()">
                        <template #iconPrefix>
                            <v-icon>$close</v-icon>
                        </template>
                        {{ $t('action.clearTemplate') }}
                    </ow-button>

                    <template v-if="!isRegisterEntriesTemplateApplied">
                        <ow-button v-if="!ddFilterShow"
                                   data-test="title-analysis-show-column-selection-button"
                                   data-track="TAM - Show column selection"
                                   is-secondary
                                   @click="onColumnSelect">
                            <template #iconPrefix>
                                <v-icon>
                                    $edit-columns
                                </v-icon>
                            </template>
                            {{ $t('action.editColumns') }}
                        </ow-button>

                        <ow-dropdown-filter v-show="ddFilterShow"
                                            ref="ddFilter"
                                            :items="ddItems"
                                            :filter-icon="false"
                                            placeholder="Search columns..."
                                            @blur="onDdFilterBlur"
                                            @check="onCheck">
                            <template #input-prepend-inner>
                                <v-icon>
                                    mdi-magnify
                                </v-icon>
                            </template>
                        </ow-dropdown-filter>
                    </template>
                    <ow-button data-track="TAM - Reset table"
                               is-secondary
                               @click="reset()">
                        {{ $t('action.reset') }}
                    </ow-button>
                </div>
            </div>

            <table-page-loading-skeleton v-if="isLoading"
                                         page="Analysis" />
            <analysis-table v-else
                            ref="analysisTable"
                            :data="enrichedData"
                            :export-data="exportData"
                            :has-template-applied="hasTemplateApplied"
                            :loading="loadingData"
                            :search-index="searchIndex"
                            :search-text="searchText"
                            class="title-analysis__table"
                            @added-column-sorts="onAddedColumnSorts"
                            @added-columns="onAddedColumns"
                            @added-filters="onAddedFilters"
                            @added-groupings="onAddedGroupings"
                            @filter-applied="onFilterApplied"
                            @removed-columns="onRemovedColumns"
                            @search-count="searchResultCount = $event"
                            @set-unpurchased-visible-titles-count="setUnpurchasedVisibleTitlesCount"
                            @title-number-selected="redirectToTitleNumber" />

            <title-panel-v2 v-if="selectedTitleNumber && isTitlePanelV2"
                            :is-pinned="isPinned"
                            :refresh-tam-on-register-order="true"
                            :selected-title-number="selectedTitleNumber"
                            :show-add-to-matter-action="false"
                            :show-title-visibility-action="false"
                            :matter-id="currentMatter.id"
                            class="title-analysis__content--title-details-panel-v2"
                            @clear-titles="clearSelectedTitle"
                            @title-number-selected="redirectToTitleNumber" />
            <title-details-panel v-if="selectedTitleNumber && !isTitlePanelV2"
                                 :is-pinned="isPinned"
                                 :refresh-tam-on-register-order="true"
                                 :selected-title-number="selectedTitleNumber"
                                 :show-add-to-matter-action="false"
                                 :show-title-visibility-action="false"
                                 class="title-analysis__content--title-details-panel"
                                 @clear-titles="clearSelectedTitle"
                                 @title-number-selected="redirectToTitleNumber" />

            <exporting-status-modal v-model="isExportStatusDialogVisible"
                                    :error="hasError"
                                    :exporting="isExportingData"
                                    data-test-attribute="tam-export-status-dialog"
                                    @request-retry="onRequestRetry()" />
        </div>
    </div>
</template>

<script lang="ts">
    import { debounce } from "lodash"
    import pluralize from 'pluralize'
    import {
        computed,
        onMounted,
        ref,
        watch,
    } from "vue"
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
        useStore,
    } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwDropdownFilter from "@/components/core/ow-dropdown-filter.vue"
    import TablePageLoadingSkeleton from '@/components/loading-skeletons/table-page-loading-skeleton.vue'
    import ExportingStatusModal from '@/components/reporting/exporting-status-modal-with-teleport.vue'
    import {
        getColumnGroups,
        getGridConfig,
    } from '@/components/title-analysis/configuration/gridConfig.js'
    import QuickActions from '@/components/title-analysis/quick-actions.vue'
    import TitleAnalysisColumnSelection from '@/components/title-analysis/title-analysis-column-selection.vue'
    import TitleAnalysisHeader from '@/components/title-analysis/title-analysis-header.vue'
    import AnalysisTable from '@/components/title-analysis/title-analysis-table.vue'
    import TitleAnalysisTemplateSelection from '@/components/title-analysis/title-analysis-template-selection.vue'
    import TitleDetailsPanel from '@/components/title-panel/title-panel.vue'
    import TitlePanelV2 from '@/components/title-panel/v2/title-panel.vue'
    import { DocumentOrderStatus } from '@/consts/document-order-status'
    import {TAMDocumentFilter} from "@/consts/tam-document-filter"
    import { Route } from '@/enums/route.enum'
    import { TitlePanelTabName } from "@/enums/title-panel-tab-name"
    import { checkFlag } from '@/feature-flags'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin.js'
    import { IExportTemplateOptions } from '@/interfaces/export-template-options.interface'
    import { IOptionLinkItem } from "@/interfaces/option-item.interface"
    import { IState } from '@/interfaces/store/state.interface'
    import ExportOptionsMixin from '@/mixins/export-options.mixin'
    import { MATTER_HAS_TITLE_NUMBER } from '@/store/modules/matter/types'
    import {
        ANALYSIS_FETCH_ALL_TITLES_FOR_MATTER,
        ANALYSIS_GET_SOURCE_DATA_LOADING_STATUS,
        ANALYSIS_MUTATE_GROUPING,
        ANALYSIS_MUTATE_INITIAL_GRID_CONFIG,
        ANALYSIS_MUTATE_IS_FILTER_BAR_VISIBLE,
        ANALYSIS_MUTATE_SELECTED_COLUMNS,
        ANALYSIS_MUTATE_SELECTED_FILTERS,
        ANALYSIS_MUTATE_SHOW_TEMPLATE_SELECTION,
        ANALYSIS_MUTATE_SORT,
        ANALYSIS_MUTATE_TEMPLATE_APPLIED,
        ANALYSIS_RESET_GRID_CONFIGURATION,
    } from '@/store/modules/title-analysis/types'
    import {
        TITLE_CLEAR,
        TITLE_LOOKUP_TITLE,
    } from '@/store/modules/titles/types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import {
        isNullOrEmpty,
        unique,
    } from '@/utils/array-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'TitleAnalysisPage',

        components: {
            TablePageLoadingSkeleton,
            OwDropdownFilter,
            OwButton,
            ExportingStatusModal,
            QuickActions,
            AnalysisTable,
            TitleAnalysisHeader,
            TitleDetailsPanel,
            TitlePanelV2,
            TitleAnalysisColumnSelection,
            TitleAnalysisTemplateSelection,
        },

        mixins: [
            FeatureFlagsMixin,
            ExportOptionsMixin,
        ],

        setup() {
            const store = useStore()
            const gridInstance = computed(() => store.state.titleAnalysis.gridInstance)
            const gridConfig = computed(() => store.state.titleAnalysis.initialGridConfig)
            const currentSelectedColumns = computed(() => store.state.titleAnalysis.selectedColumns)
            const data = ref([])
            const ddItems = ref<IOptionLinkItem[]>([])
            const ddFilter = ref(null)
            const ddFilterShow = ref(false)

            const onCheck = () => {
                updateColumns()
            }

            const updateColumns = () => {
                debouncedSaveColumnSelection()
            }

            const saveColumnSelection = () => {
                // selected fields
                const newSelectedFields = []
                ddItems.value.forEach(group => {
                    group.items.forEach(column => {
                        if (column.checked) {
                            newSelectedFields.push(column.value)
                        }
                    })
                })


                store.commit(ANALYSIS_MUTATE_SELECTED_COLUMNS, gridConfig.value.columns.filter(c => {
                    return newSelectedFields.includes(c.field)
                }))
            }

            const debouncedSaveColumnSelection = debounce(saveColumnSelection, 250)

            const refresh = () => {
                data.value = []

                if (!gridConfig?.value?.columns || !gridInstance?.value?.columns) {
                    return
                }

                const selectedFields = currentSelectedColumns.value
                    .map(c => c.field)

                // Builds a local data structure representing the currently selected columns within groups
                const columnGroups = getColumnGroups()
                data.value = columnGroups.map(group => {
                    return {
                        ...group,
                        columns: gridConfig.value.columns
                            .filter(c => c.group === group.id)
                            .filter(c => c.excludeFromColumnSelectionPanel !== true)
                            .map(column => {
                                return {
                                    ...column,
                                    visible: selectedFields.includes(column.field),
                                }
                            }),
                    }
                }).filter(group => group.columns.length > 0)

                refreshColumnSelection()
            }

            const refreshColumnSelection = () => {
                ddItems.value = []
                data.value.forEach(group => {
                    const ddItem: IOptionLinkItem = {
                        title: group.label,
                        value: group.id,
                        disableCheckRow: true,
                        items: [],
                    }

                    group.columns.forEach(column => {
                        ddItem.items.push({
                            title: column.text,
                            value: column.field,
                            tag: group.label,
                            checked: column.visible,
                            parentId: group.id,
                        })
                    })

                    ddItems.value.push(ddItem)
                })
                ddFilter.value.refresh()
            }

            const onColumnSelect = () => {
                ddFilterShow.value = true
                ddFilter.value.show()
            }

            const onDdFilterBlur = () => {
                setTimeout(() => {
                    ddFilterShow.value = false
                }, 250)
            }

            // Watch for changes to the selected columns and refresh the grid
            watch(() => currentSelectedColumns.value, (val) => {
                if (!isNullOrEmpty(val)) {
                    refresh()
                }
            }, {
                deep: true,
            })

            return {
                gridInstance,
                ddItems,
                onCheck,
                refresh,
                ddFilter,
                ddFilterShow,

                onColumnSelect,
                onDdFilterBlur,
            }
        },

        data() {
            return {
                exportData: null,
                searchIndex: null,
                searchResultCount: 0,
                searchText: '',
                selectedTitleNumber: null,
                unpurchasedVisibleTitlesCount: null,
                hasValidatedTAMTitleCount: false, // TODO: remove when TAM instability resolved
                visibleTitles: [],
                allTitlesPurchased: false,
                hasFilterApplied: false,
                documentOrderStatusesThatIndicateInProgressOrCompletion: [ // for the purposes of TAM. This and any code related to ordering status, counts etc needs a rewrite to use state only.
                    DocumentOrderStatus.ORDERED,
                    DocumentOrderStatus.DERIVED,
                    DocumentOrderStatus.ORDERING,
                    DocumentOrderStatus.PROCESSING,
                    DocumentOrderStatus.COMPLETED,
                    DocumentOrderStatus.DERIVING,
                ],
                documentsShown: TAMDocumentFilter.ShowAll,
                lastReportType: '',
                isDataLoaded: false,
            }
        },

        computed: {
            ...mapState({
                currentMatter: (state: IState) => state.matter?.currentMatter,
                loadingList: (state: IState) => state.matter?.loadingList,
                loadingMatter: (state: IState) => state.matter?.loading,
                debugModeEnabled: (state: IState) => state.diagnostics.debugModeEnabled,

                gridData: (state: IState) => state.titleAnalysis.derivedData,
                gridInstance: (state: IState) => state.titleAnalysis.gridInstance,
                appliedTemplate: (state: IState) => state.titleAnalysis.appliedTemplate,
                applyingTemplate: (state: IState) => state.titleAnalysis.applyingTemplate,
                hasLoadedInitialGridState: (state: IState) => state.titleAnalysis.hasLoadedInitialGridState,
                selectedColumns: (state: IState) => state.titleAnalysis.selectedColumns,
                sourceData: (state: IState) => state.titleAnalysis.sourceData,
                isFilterBarVisible: (state: IState) => state.titleAnalysis.isFilterBarVisible,
            }),

            ...mapGetters({
                loadingData: ANALYSIS_GET_SOURCE_DATA_LOADING_STATUS,
                matterHasTitle: MATTER_HAS_TITLE_NUMBER,
            }),

            registerSourceMap() {
                const registerSource = new Map()

                for (const { titleNumber, isRegisterOrdered, isRegisterUploaded, registerResponseDate } of this.currentMatter.selectedTitles) {
                    registerSource.set(titleNumber, { isRegisterOrdered, isRegisterUploaded, registerResponseDate })
                }

                return registerSource
            },

            isTitlePanelV2() {
                return checkFlag('title-panel-v-2', false)
            },

            enrichedData() {
                const data = this.gridData.map(data => ({
                    ...data,
                    ...this.registerSourceMap.get(data.titleNumber),
                }))

                switch(this.documentsShown){
                    case TAMDocumentFilter.ShowAll:
                        return data
                    case TAMDocumentFilter.ShowOwned:
                        return data.filter(t => !isNullOrWhitespace(t.editionDate))
                    case TAMDocumentFilter.ShowUnowned:
                        return data.filter(t => isNullOrWhitespace(t.editionDate))
                }

                return null
            },

            sourceDataCount() {
                let count = unique(this.gridData).length
                if (this.isRegisterEntriesTemplateApplied) {
                    // The rows in the grid are actually register entries, so going back to the
                    // source to set this value instead
                    count = this.sourceData.length
                }
                return count
            },

            initialised() {
                return this.hasLoadedInitialGridState === true && this.currentMatter.id !== null
            },

            isLoading() {
                return this.loadingList ||
                    this.loadingMatter ||
                    !this.isFeatureFlagsLoaded
            },

            showFilterBar: {
                get() {
                    return this.isFilterBarVisible
                },
                set(isVisible) {
                    this.mutateIsFilterBarVisible(isVisible)
                },
            },

            isOrderInProgressForVisibleRegisters() {
                const result =
                    Boolean(this.visibleTitles
                        .find(title => title.documentOrderStatus === DocumentOrderStatus.ORDERING ||
                            title.documentOrderStatus === DocumentOrderStatus.RETRYING))
                return result
            },

            titleCountTextVisibleTitles() {
                const visibleTitlesCount = this.visibleTitles?.length
                const visibleUnpurchasedTitlesCount = this.visibleUnpurchasedTitles.length
                const visibleTitlesText = `${ pluralize('title', visibleTitlesCount, true) }`
                const inTemplateText = `${ this.hasTemplateApplied ? 'in this template' : '' }`
                const showUnorderedTitlesText = visibleUnpurchasedTitlesCount > 0 && !this.allTitlesPurchased
                const unorderedTitles = showUnorderedTitlesText ? `(${ visibleUnpurchasedTitlesCount } ${ visibleUnpurchasedTitlesCount === 1 ? 'is' : 'are' } unpurchased)` : ''

                return `Showing ${ visibleTitlesText } ${ inTemplateText } ${ unorderedTitles }`
            },

            getDocumentFilterIcon(){
                switch(this.documentsShown){
                    case TAMDocumentFilter.ShowAll:
                        return 'mdi-check-all'
                    case TAMDocumentFilter.ShowOwned:
                        return 'mdi-cash-check'
                    case TAMDocumentFilter.ShowUnowned:
                        return 'mdi-shopping'
                }

                return null
            },

            getDocumentFilterText(){
                switch(this.documentsShown){
                    case TAMDocumentFilter.ShowAll:
                        return this.$t('tam.filter.showAll')
                    case TAMDocumentFilter.ShowOwned:
                        return this.$t('tam.filter.showPurchased')
                    case TAMDocumentFilter.ShowUnowned:
                        return this.$t('tam.filter.showUnpurchased')
                }

                return null
            },

            titleCountTextAllTitles() {
                return this.allTitles.length !== this.visibleTitles.length
                    ? ` of ${ this.sourceDataCount } ${ pluralize('title', this.sourceDataCount) } in your matter`
                    : ''
            },

            allTitles() {
                if (!isNullOrEmpty(this.gridData) && !this.isRegisterEntriesTemplateApplied) {
                    return this.gridData.map(this.mapTitleData)
                } else if (this.isRegisterEntriesTemplateApplied) {
                    return this.sourceData.map(this.mapTitleData)
                }
                return []
            },

            hasTemplateApplied() {
                return !isNullOrWhitespace(this.appliedTemplate)
            },

            allUnpurchasedTitles() {
                return this.allTitles.filter(title => {
                    if (title) {
                        return !title.purchased
                    }
                    return false
                })
            },

            hasPurchasedTitles() {
                return this.allTitles.some(title => title.purchased)
            },

            visibleUnpurchasedTitles() {
                return this.visibleTitles.filter(title => {
                    if (title) {
                        return !title.purchased
                    }
                    return false
                })
            },

            isRegisterEntriesTemplateApplied() {
                return this.appliedTemplate?.name === this.$t('tam.templates.allRegisterEntries.name')
            },

            showFilterButton() {
                return !this.hasTemplateApplied || this.isRegisterEntriesTemplateApplied
            },

            showClearFilterButton() {
                return this.hasFilterApplied && this.showFilterButton
            },

            isPinned() {
                return this.matterHasTitle(this.selectedTitleNumber)
            },
        },

        watch: {
            $route(to, from) {
                //switching tabs in title panel
                if(to.name === Route.AnalysisTitleDetails && from.name === Route.AnalysisTitleDetails) {
                    return
                }
                switch (to.name) {
                    case Route.AnalysisTitleDetails:
                        this.showTitleDetailsPanel(to.params.titleNumber)
                        break
                    case Route.Analysis:
                        if (!this.isDataLoaded) {
                            this.clearSelectedTitle()
                            this.getData() // refresh data
                        }
                        break
                }
            },

            'currentMatter.id'() {
                this.getData()
                // TODO: remove when TAM instability resolved
                this.hasValidatedTAMTitleCount = false
            },

            hasLoadedInitialGridState() {
                this.getData()
            },

            selectedColumns() {
                this.getData()
            },

            showFilterBar(val) {
                if (val === true) {
                    // eslint-disable-next-line no-unused-expressions
                    this.gridInstance?.features.filterBar.showFilterBar()
                } else {
                    // eslint-disable-next-line no-unused-expressions
                    this.gridInstance?.features.filterBar.hideFilterBar()
                }
            },

            allTitles(newVal) {
                this.setVisibleTitles()
                let mappedData = newVal
                if (this.isRegisterEntriesTemplateApplied) {
                    mappedData = this.sourceData.map(this.mapTitleData)
                }
                this.allTitlesPurchased = mappedData.filter(title => title.purchased !== true).length === 0
            },

            isFeatureFlagsLoaded: {
                handler(loaded) {
                    if (loaded) {
                        this.init()
                    }
                },
                immediate: true,
            },
        },

        unmounted() {
            this.clearTitle()
        },

        methods: {
            ...mapActions({
                clearTitle: TITLE_CLEAR,
                lookupTitle: TITLE_LOOKUP_TITLE,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
                fetchData: ANALYSIS_FETCH_ALL_TITLES_FOR_MATTER,
                resetGridConfiguration: ANALYSIS_RESET_GRID_CONFIGURATION,
            }),

            ...mapMutations({
                mutateInitialGridConfig: ANALYSIS_MUTATE_INITIAL_GRID_CONFIG,
                // showColumnSelection: ANALYSIS_MUTATE_SHOW_COLUMN_SELECTION,
                showTemplateSelection: ANALYSIS_MUTATE_SHOW_TEMPLATE_SELECTION,
                mutateSelectedFilters: ANALYSIS_MUTATE_SELECTED_FILTERS,
                mutateTemplateApplied: ANALYSIS_MUTATE_TEMPLATE_APPLIED,
                mutateSort: ANALYSIS_MUTATE_SORT,
                mutateGrouping: ANALYSIS_MUTATE_GROUPING,
                mutateIsFilterBarVisible: ANALYSIS_MUTATE_IS_FILTER_BAR_VISIBLE,
            }),

            init() {
                this.mutateInitialGridConfig(getGridConfig({
                    debugMode: this.debugModeEnabled,
                    includeTitleComplexity: true,
                    includeMlTags: this.isMlTagsEnabled,
                }))

                this.showTitleDetailsPanel(this.$route.params.titleNumber)
                this.hasValidatedTAMTitleCount = false
            },



            getNextFilterState() {
                switch(this.documentsShown){
                    case TAMDocumentFilter.ShowAll:
                        this.documentsShown = TAMDocumentFilter.ShowOwned
                        break
                    case TAMDocumentFilter.ShowOwned:
                        this.documentsShown = TAMDocumentFilter.ShowUnowned
                        break
                    case TAMDocumentFilter.ShowUnowned:
                        this.documentsShown = TAMDocumentFilter.ShowAll
                        break
                }
            },

            async getData() {
                await this.fetchData({
                    matter: this.currentMatter,
                })
                this.isDataLoaded = true
            },

            onAddedColumns(columns) {
                // Log events to Heap indicating columns have been selected.
                if (this.initialised && !this.applyingTemplate) {
                    columns.forEach(column => {
                        this.logHeapEvent({
                            type: 'TAM - Column Visibility',
                            metadata: {
                                column: column.field,
                                visible: true,
                            },
                        })
                    })
                }
            },

            onRemovedColumns(columns) {
                // Log events to Heap indicating columns have been deselected.
                if (this.initialised && !this.applyingTemplate) {
                    columns.forEach(column => {
                        this.logHeapEvent({
                            type: 'TAM - Column Visibility',
                            metadata: {
                                column: column.field,
                                visible: false,
                            },
                        })
                    })
                }
            },

            onAddedColumnSorts(columns) {
                // Log events to Heap indicating columns has been sorted.
                if (this.initialised && !this.applyingTemplate) {
                    columns.forEach(column => {
                        this.logHeapEvent({
                            type: 'TAM - Column Sort',
                            metadata: {
                                column: column.field,
                                ascending: column.ascending,
                            },
                        })
                    })
                }
            },

            onAddedFilters(filters) {
                // Log events to Heap indicating columns have been filtered on.
                if (this.initialised && !this.applyingTemplate) {
                    filters.forEach(filter => {
                        let filterValue = filter.value
                        if (Array.isArray(filterValue)) {
                            filterValue = filterValue.join(',')
                        }
                        this.logHeapEvent({
                            type: 'TAM - Column Filter',
                            metadata: {
                                column: filter.property,
                                value: filterValue,
                            },
                        })
                    })
                }
            },

            setUnpurchasedVisibleTitlesCount(val) {
                this.setVisibleTitles()
                this.unpurchasedVisibleTitlesCount = val
            },

            onAddedGroupings(grouping) {
                // Log events to Heap indicating columns have been filtered on.
                if (this.initialised && !this.applyingTemplate) {
                    grouping.forEach(group => {
                        this.logHeapEvent({
                            type: 'TAM - Column Group',
                            metadata: {
                                column: group.field,
                                ascending: group.ascending,
                            },
                        })
                    })
                }
            },

            // NOTE: (DOBO) called when title panel is closed and redirect to Route.Analysis
            clearSelectedTitle() {
                this.clearTitle()
                this.selectedTitleNumber = null
                this.$router.push({
                    name: Route.Analysis,
                    params: {
                        matterId: this.currentMatter.id,
                    },
                })
            },

            // NOTE: (DOBO) called when a title is selected in TAM table or within the title panel itself
            redirectToTitleNumber(titleNumber) {
                if (titleNumber !== this.selectedTitleNumber) {
                    this.$router.push({
                        name: Route.AnalysisTitleDetails,
                        params: {
                            matterId: this.currentMatter.id,
                            titleNumber,
                        },
                        query: {
                            tab: TitlePanelTabName.Summary,
                            section: 'overview',
                        },
                    })
                }
            },

            // NOTE: (DOBO) called on init and redirect to Route.AnalysisTitleDetails
            showTitleDetailsPanel(titleNumber) {
                if (!titleNumber) {
                    return
                }

                if (titleNumber !== this.selectedTitleNumber ||
                    !this.selectedTitleNumber?.record) {
                    this.lookupTitle(titleNumber)
                }
                this.selectedTitleNumber = titleNumber
            },

            onFilterApplied(applied) {
                this.hasFilterApplied = applied
            },

            clearFilter() {
                this.mutateSelectedFilters([])
            },

            clearTemplate() {
                this.clearFilter()
                this.mutateGrouping([])
                this.mutateSort([])
                this.mutateTemplateApplied(null)
                this.$refs.analysisTable.loadPriorGridState()
            },

            async reset() {
                await this.resetGridConfiguration(this.currentMatter.id)
                this.mutateInitialGridConfig(getGridConfig({
                    debugMode: this.debugModeEnabled,
                    includeTitleComplexity: true,
                    includeMlTags: this.isMlTagsEnabled,
                }))
                this.clearTemplate()
                this.$refs.analysisHeader.clearSearch()

                await this.$nextTick()
                this.refresh()
            },

            setVisibleTitles() {
                if (isNullOrEmpty(this.gridInstance?.store?.storage?.values)) {
                    this.visibleTitles = []
                    return
                }

                // Filter our group heading rows which have no title number so they're not counted.
                this.visibleTitles = this.gridInstance.store.storage.values
                    .filter(title => title?.titleNumber !== undefined)
                    .map(this.mapTitleData)

                if (this.isRegisterEntriesTemplateApplied) {
                    // The rows are actually the register entries, so need to de-de-duplicate
                    const deduplicatedTitles = unique(this.visibleTitles.map(t => t.titleNumber))
                    this.visibleTitles = deduplicatedTitles.map(titleNumber => ({
                        titleNumber,
                        purchased: true,
                    }))
                }
            },

            mapTitleData(title) {
                return {
                    titleNumber: title.titleNumber,
                    purchased: !isNullOrWhitespace(title.editionDate) || // this is not an indicator of whether the title is purchased, all of this needs rewriting.
                        !isNullOrWhitespace(title.data?.editionDate) ||
                        this.documentOrderStatusesThatIndicateInProgressOrCompletion.includes(title.documentOrderStatus),
                    documentOrderStatus: title.documentOrderStatus,
                }
            },

            async quickActionHandler(type: string) {
                let selectedExportTemplate: IExportTemplateOptions

                switch (type) {
                    case 'applyTemplate':
                        this.showTemplateSelection(true)
                        break
                    case 'exportCurrentView':
                        this.exportData = this.exportData + 1
                        break
                    case 'downloadTAR':
                        selectedExportTemplate = this.exportOptions.find((option: IExportTemplateOptions) => option.name === 'Title Analysis Report (Excel)')
                        await this.exportDataForTemplate(selectedExportTemplate)
                        break
                    case 'downloadHighQTAR':
                        selectedExportTemplate = this.exportOptions.find((option: IExportTemplateOptions) => option.name === 'HighQ Title Analysis Report')
                        await this.exportDataForTemplate(selectedExportTemplate)
                        break
                }
                this.lastReportType = type
            },

            async exportDataForTemplate(selectedExportTemplate: IExportTemplateOptions) {
                const titleNumbers = this.visibleTitles
                    .filter((title: any) => title.purchased)
                    .map((title: any) => title.titleNumber)

                await this.linkClickHandler(
                    titleNumbers,
                    this.currentMatter.id,
                    'TAM Quick Action Card - Download TAR',
                    selectedExportTemplate,
                )
            },

            async onRequestRetry() {
                await this.quickActionHandler(this.lastReportType)
            },
        },
    }
</script>

<style lang="scss">
    @import './title-analysis.scss';
</style>
