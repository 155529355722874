<template>
    <ow-page-layout page-name="map-overlays"
                    :header-svg-icon="OwOverlayMapIcon"
                    header-title="Overlay Plans">
        <template #headerActions>
            <ow-button class="map-overlays__close-btn"
                       is-borderless
                       size="large"
                       data-test="map-overlays-close-btn"
                       data-track="OVERLAY PLANS - X button"
                       icon
                       @click="handleCloseOverlayClick">
                <v-icon>$close</v-icon>
            </ow-button>
        </template>

        <template #navigation>
            <ow-step-flow :steps="steps"
                          :current-step-id="currentStepId"
                          class="map-overlays__step-flow"
                          @step-change="handleStepChange" />
        </template>

        <hr class="map-overlays__divider" />

        <div class="map-overlays__content"
             :class="{ 'select-page': currentStepId === OverlaySteps.PageSelection }">
            <div v-if="currentStepId === OverlaySteps.DocumentSelection">
                <select-a-document :selected-document="selectedDocument"
                                   :selected-document-id="selectedDocumentId"
                                   @selected="handleSelectedDocument"
                                   @clear="handleClear" />
            </div>

            <page-selection v-if="currentStepId === OverlaySteps.PageSelection && !loadingFromQueryString"
                            :document-url="selectedDocumentUrl"
                            :pre-selected-page-numbers="preSelectedPageNumbers"
                            @select="onSelectPages" />
            <div v-if="currentStepId === OverlaySteps.OverlayPlan"
                 class="loading-overlay">
                <ow-loading-text :text="getOverlaysLoadingText()"
                                 is-loading />
                <ow-loading-skeleton />
            </div>
        </div>

        <div class="map-overlays__actions">
            <ow-button class="map-overlays__action-button"
                       is-secondary
                       size="large"
                       :data-track="backButtonDataTrackLabel"
                       data-test="map-overlays-back-btn"
                       @click="handleBackButtonClick">
                {{ backButtonLabel }}
            </ow-button>
            <ow-button class="map-overlays__action-button"
                       style="margin-right: 80px"
                       :is-loading="isLoading"
                       :disabled="confirmButtonDisabled"
                       :data-track="confirmButtonDataTrackLabel"
                       data-test="map-overlays-next-btn"
                       is-primary
                       size="large"
                       @click="handleNextStepClick">
                {{ confirmButtonLabel }}
            </ow-button>
        </div>
    </ow-page-layout>
</template>

<script setup lang="ts">
    import {
        computed,
        onActivated,
        onDeactivated,
        onMounted,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import {              useRoute,
                          useRouter } from 'vue-router'
    import { useStore } from 'vuex'

    import DocumentUploadApi from '@/api/document-upload.api'
    import DocumentsApi from '@/api/documents.api'
    import OverlaysApi from '@/api/overlays.api'
    import SearchesApi, { IGetSearchDocumentSasTokensRequest } from '@/api/searches.api'
    import OwOverlayMapIcon from '@/components/core/icons/ow-icon-overlay-map.vue'
    import OwPageLayout from '@/components/core/layout/full-width.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import OwLoadingText from '@/components/core/ow-loading-text.vue'
    import OwStepFlow from '@/components/core/ow-step-flow.vue'
    import {
        CreateOverlayRequest,
        CreateOverlayResponse,
        OverlayModel,
        PageNumberAndScale,
        SelectedPage,
    } from '@/components/map/overlays/overlays-types'
    import PageSelection from '@/components/map/overlays/page-selection.vue'
    import SelectADocument from '@/components/map/overlays/select-a-document.vue'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { OverlayDocumentType } from '@/enums/overlay-document-type'
    import { OverlaySourceFileFormat } from '@/enums/overlays-enums'
    import { Route } from '@/enums/route.enum'
    import { OverlaySteps } from '@/enums/step-flow-options.enums'
    import { IOwStepFlowItem } from '@/interfaces/core-components/ow-step-flow.interface'
    import { IHttpClientResponse } from '@/interfaces/http-client-response.interface'
    import { ISearchesDocument } from '@/interfaces/searches-document.interface'
    import { IUploadedDocument } from '@/interfaces/uploaded-document.interface'
    import {
        DOCUMENTS_FETCH_SEARCHES_DOCUMENTS,
        DOCUMENTS_FETCH_UPLOADED_DOCUMENTS,
        GET_LIBRARY_DOCUMENTS,
        GET_LIBRARY_SEARCH_DOCUMENTS,
        GET_LIBRARY_UPLOADED_DOCUMENTS,
        REFRESH_DOCUMENTS,
    } from '@/store/modules/documents/documents-types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { getPurchasedDocumentName } from '@/utils/document-utils'
    import { getApiUri } from '@/utils/environment.utils'
    import { validCentrePoint } from '@/utils/map-utils'
    import {
        getNameForOverlay,
        getOverlayDocumentType,
        getSuggestedCentrePointForOverlayRequest,
    } from '@/utils/overlays-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const { t } = useI18n()
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    const currentStepId = ref(OverlaySteps.DocumentSelection)
    const currentMatterId = computed((): number => store.state.matter.currentMatter.id)
    const selectedDocument = ref(null)
    const loading = ref(false)
    const selectedDocumentUrl = ref<string>(null)
    const preSelectedPages = ref<PageNumberAndScale[]>([])
    const preSelectedPageNumbers = computed(() => preSelectedPages.value?.map(x => x.pageNumber) ?? [])
    const loadingOverlayTakingALongTime = ref(false)
    const loadingFromQueryString = ref(false)
    const selectedDocumentId = ref(null)
    const selectedDocumentType = ref(null)
    const titlePlanRegex = new RegExp(/Official_Copy_Plan_(.*).pdf/, 'i')

    const preSelectedDocumentId = computed(() => route.query?.documentId?.toString() ?? null)
    const autoSelect = computed<boolean>(() => route.query?.autoSelect === undefined ? true : route.query?.autoSelect === 'false' ? false : true)
    const overlayDocumentType = computed(() => getOverlayDocumentType(selectedDocument.value))

    const props = defineProps<{
        fromRouteName?: string
    }>()

    watch(selectedDocument, () => {
        // Changed the document, so reset the selected pages
        preSelectedPages.value = []
    })

    const resetState = () => {
        selectedDocument.value = null
        loading.value = false
        selectedDocumentUrl.value = null
        preSelectedPages.value = []
        loadingOverlayTakingALongTime.value = false
    }

    const steps: Array<IOwStepFlowItem> = [{
        id: OverlaySteps.DocumentSelection,
        title: t('mapOverlays.stepFlow.step1.title'),
        description: t('mapOverlays.stepFlow.step1.description'),
    }, {
        id: OverlaySteps.PageSelection,
        title: t('mapOverlays.stepFlow.step2.title'),
        description: t('mapOverlays.stepFlow.step2.description'),
    }, {
        id: OverlaySteps.OverlayPlan,
        title: t('mapOverlays.stepFlow.step3.title'),
        description: t('mapOverlays.stepFlow.step3.description'),
    }]

    const handleStepChange = async (stepId: number): Promise<void> => {
        if (stepId === OverlaySteps.DocumentSelection) {
            clearQueryString()
        }
        if (stepId === OverlaySteps.PageSelection) {
            if (!selectedDocument.value) {
                currentStepId.value = OverlaySteps.DocumentSelection
                return
            }

            // TODO: Doesn't exist yet.
            // Get overlay predications for the selected document.
            loading.value = true
            // preSelectedPages.value = (await OverlaysApi.detectOverlays({
            //     fileFormat: OverlaySourceFileFormat.PDF,
            //     titlePlanId: selectedHighLevelDocumentType.value === HighLevelDocumentType.TitlePlan ? selectedDocument.value.titlePlanId : null,
            //     uploadFileId: selectedHighLevelDocumentType.value === HighLevelDocumentType.Uploaded ? selectedDocument.value.documentUploadRequestId : null,
            //     registerId: selectedHighLevelDocumentType.value === HighLevelDocumentType.Register ? selectedDocument.value.registerId : null,
            //     associatedTitles: selectedDocumentTitleNumbers.value,
            // })).detectedOverlays
            // For now, check for an official title plan and it's usually the 2nd page.
            setDocumentPage()
            loading.value = false
            selectedDocumentUrl.value = await getDocumentUrl()
        } else if (stepId === OverlaySteps.OverlayPlan) {
            if (!selectedDocument.value) {
                currentStepId.value = OverlaySteps.DocumentSelection
                return
            }

            // Create an overlay for each selected page.
            const results: Array<CreateOverlayResponse> = [] // returns an array of created overlay ids
            loading.value = true
            for (const selectedPage of selectedPages.value) {
                const name = getNameForOverlay(selectedDocument.value)
                const createRequest: CreateOverlayRequest = {
                    name,
                    fileFormat: OverlaySourceFileFormat.PDF,
                    titlePlanId: selectedHighLevelDocumentType.value === HighLevelDocumentType.TitlePlan ? selectedDocument.value.documentId : null,
                    uploadedFileId: selectedHighLevelDocumentType.value === HighLevelDocumentType.Uploaded ? selectedDocument.value.id : null,
                    registerId: selectedHighLevelDocumentType.value === HighLevelDocumentType.Register ? selectedDocument.value.documentId : null,
                    oc2FileId: selectedHighLevelDocumentType.value === HighLevelDocumentType.OC2Document ? selectedDocument.value.documentId : null,
                    sasUri: selectedHighLevelDocumentType.value === HighLevelDocumentType.Searches ? selectedDocumentUrl.value : null,
                    searchesFileId: selectedHighLevelDocumentType.value === HighLevelDocumentType.Searches ? selectedDocument.value.orderItemId : null,
                    associatedTitles: selectedDocumentTitleNumbers.value,
                    targetPages: [selectedPage.page.pageNumber],
                    scale: selectedPage.scale,
                    matterId: store.state.matter.currentMatter.id,
                }
                // Validate associated titles
                createRequest.associatedTitles = createRequest.associatedTitles.filter(x => !isNullOrWhitespace(x))

                // If there's a title associated with the document we can use that to suggest a centre point. TODO: move this to the automation pipeline?
                const suggestedCentrePoint = await getSuggestedCentrePointForOverlayRequest(createRequest)
                if (validCentrePoint(suggestedCentrePoint)) {
                    createRequest.centrePoint = suggestedCentrePoint
                }

                const response = await OverlaysApi.createOverlay(createRequest)
                results.push(response?.data)
            }
            loading.value = false

            let overlayTimer = () => setTimeout(() => {
                loadingOverlayTakingALongTime.value = true
            }, 5000)

            if (results.length) {
                // Wait until the overlay is ready before attempting to display it.
                const useOverlayId = results[0].overlayId
                const poll = () => setTimeout(async () => {
                    const response: IHttpClientResponse<OverlayModel> = await OverlaysApi.getOverlay(useOverlayId)
                    if (response?.data?.sourceImageUrl) {
                        resetState() // todo: check
                        currentStepId.value = OverlaySteps.DocumentSelection
                        overlayTimer = null

                        await router.push({
                            name: Route.Overlay,
                            params: {
                                matterId: store.state.matter.currentMatter.id,
                                overlayId: results[0].overlayId,
                            },
                        })
                    } else {
                        poll()
                    }
                }, 2000)

                overlayTimer()

                poll()
            }
        }
        currentStepId.value = stepId
    }

    const handleCloseOverlayClick = (): void => {
        if (props.fromRouteName === Route.DocumentViewer) {
            router.push({
                name: Route.DocumentViewer,
                params: {
                    documentType: selectedDocumentType.value,
                    documentId: selectedDocumentId.value,
                },
                query: {
                    fromMatterId: store.state.matter.currentMatter.id,
                },
            })
        } else {
            router.push({ name: Route.MatterMap })
        }
    }

    const handleBackButtonClick = (): void => {
        switch (currentStepId.value) {
            case OverlaySteps.DocumentSelection:
                handleCloseOverlayClick()
                break
            default:
                handleStepChange(currentStepId.value - 1)
                break
        }
    }

    const purchasedDocuments = computed((): any[] => {
        return store.getters[GET_LIBRARY_DOCUMENTS]
    })

    const uploadedDocuments = computed((): IUploadedDocument[] => {
        return store.getters[GET_LIBRARY_UPLOADED_DOCUMENTS]
    })

    const searchesDocuments = computed((): ISearchesDocument[] => {
        return store.getters[GET_LIBRARY_SEARCH_DOCUMENTS]
    })

    const confirmButtonLabel = computed((): string => {
        // The overlay plan step isn't part of this page (redirect to the map), so no button needed
        switch (currentStepId.value) {
            case OverlaySteps.DocumentSelection:
                return t('mapOverlays.actions.confirmDocument')
            case OverlaySteps.PageSelection:
                return t('mapOverlays.actions.confirmPage')
            default:
                // Should never get here, but needs a default
                return t('action.confirm')
        }
    })

    const backButtonLabel = computed((): string => {
        switch (currentStepId.value) {
            case OverlaySteps.PageSelection:
                return t('action.back')
            default:
                return t('action.cancel')
        }
    })

    const confirmButtonDataTrackLabel = computed((): string => {
        switch (currentStepId.value) {
            case OverlaySteps.PageSelection:
                return 'OVERLAY PLANS - Confirm page'
            default:
                return 'OVERLAY PLANS - Confirm document'
        }
    })

    const backButtonDataTrackLabel = computed((): string => {
        switch (currentStepId.value) {
            case OverlaySteps.PageSelection:
                return 'OVERLAY PLANS - Back'
            default:
                return 'OVERLAY PLANS - Cancel'
        }
    })

    const handleSelectedDocument = (doc: any) => {
        selectedDocument.value = doc

        store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'OVERLAY PLANS - Click overlaid plan row item',
            metadata: {
                selectedDoc: doc,
                isUploadedDoc: Boolean(doc?.documentUploadRequestId),
            },
        })
    }

    const handleClear = () => {
        selectedDocumentId.value = null
        selectedDocument.value = null
        refreshDocuments()
    }

    const handleNextStepClick = async (): Promise<void> => {
        const nextStepIndex = currentStepId.value + 1
        if (nextStepIndex < steps.length) {
            await handleStepChange(nextStepIndex)
        }
    }

    // Selected pages.
    const selectedPages = ref<SelectedPage[]>([])
    const onSelectPages = (pages: SelectedPage[]): void => {
        selectedPages.value = [pages[0]]

        store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'OVERLAY PLANS - Select page',
            metadata: {
                selectedPages: selectedPages.value,
            },
        })
    }

    const confirmButtonDisabled = computed(() => {
        if (loading.value) return true
        switch (currentStepId.value) {
            case OverlaySteps.DocumentSelection:
                return !selectedDocument.value
            case OverlaySteps.PageSelection:
                return isNullOrEmpty(selectedPages.value)
            default:
                return true
        }
    })

    const selectedHighLevelDocumentType = computed<string>(() => {
        if (!selectedDocument.value) {
            return null
        }
        if (overlayDocumentType.value === OverlayDocumentType.Uploaded) {
            return HighLevelDocumentType.Uploaded
        } else if (overlayDocumentType.value === OverlayDocumentType.Searches) {
            return HighLevelDocumentType.Searches
        } else {
            return getHighLevelDocumentType(selectedDocument.value.documentType)
        }
    })

    const selectedDocumentTitleNumbers = computed<Array<string>>(() => {
        if (!selectedDocument.value) {
            return []
        }
        const documentOverlayType = getOverlayDocumentType(selectedDocument.value)
        if (documentOverlayType === OverlayDocumentType.Uploaded) {
            return [selectedDocument.value.titleNumber]
        } else if (documentOverlayType === OverlayDocumentType.Searches) {
            return [selectedDocument.value.location]
        } else {
            return [selectedDocument.value.titleNo]
        }
    })

    const isLoading = computed(() => {
        return loading.value
    })

    const getSearchesDocumentUri = async (document: ISearchesDocument) => {
        const orderId = document.orderId
        const fileName = document.fileName

        const request: IGetSearchDocumentSasTokensRequest = {
            documents: { [orderId]: [fileName] },
        }

        const response: IHttpClientResponse = await SearchesApi.getSasToken(request)

        if (response.ok) {
            return response.data[0] // Only 1 doc should be returned from the call
        }
        return null
    }

    const getDocumentUrl = async () => {
        if (!selectedDocument.value) {
            return null
        }
        // Uploaded documents have a different model, API, and URL.
        if (selectedHighLevelDocumentType.value === HighLevelDocumentType.Uploaded ||
            selectedHighLevelDocumentType.value === HighLevelDocumentType.UploadedOC2) {
            const uploadedDocResponse =
                await DocumentUploadApi.getDocumentUrlWithToken(store.state.matter.currentMatter.id, selectedDocument.value.id)
            return uploadedDocResponse.data.uri
        }

        // Searches document
        if (selectedHighLevelDocumentType.value === HighLevelDocumentType.Searches) {
            return await getSearchesDocumentUri(selectedDocument.value)
        }

        // All other documents.
        const metadata:any = await DocumentsApi.getDocumentMetadataByTypeAndId(selectedHighLevelDocumentType.value, selectedDocument.value.documentId)
        return `${ getApiUri() }/${ metadata.documentDownloadUrl }`
    }
    const refreshDocuments = async () => {
        await store.dispatch(REFRESH_DOCUMENTS)
        await store.dispatch(DOCUMENTS_FETCH_UPLOADED_DOCUMENTS, store.state.matter.currentMatter.id)
        await store.dispatch(DOCUMENTS_FETCH_SEARCHES_DOCUMENTS, store.state.matter.currentMatter.id)
    }
    const getOverlaysLoadingText = () => {
        return loadingOverlayTakingALongTime.value
            ? t('mapOverlays.loadingOverlayTakingALongTime').toString()
            : t('mapOverlays.loadingOverlay').toString()
    }

    const resetStepFlowState = async () => {
        currentStepId.value = OverlaySteps.DocumentSelection
        await refreshDocuments()
    }

    const loadStateFromQueryString = async () => {
        // Hide page whilst loading data
        loadingFromQueryString.value = true
        currentStepId.value = OverlaySteps.PageSelection
        // Ensure there are documents
        await refreshDocuments()
        // Get the selected document by id from state
        const doc = await getPreSelectedDocument()
        selectedDocumentId.value = preSelectedDocumentId.value
        // Clear core state variables
        await resetState()

        if (doc) {
            if (autoSelect.value) {
                await setPreselectedDocument(doc)
            } else {
                clearQueryString()
                selectedDocument.value = doc
                currentStepId.value = OverlaySteps.DocumentSelection
            }
        } else {
            clearQueryString()
            currentStepId.value = OverlaySteps.DocumentSelection
        }

        // Set the components state using the document
        loadingFromQueryString.value = false
    }

    const setPreselectedDocument = async (doc): Promise<void> => {
        handleSelectedDocument(doc)
        selectedDocumentUrl.value = await getDocumentUrl()
        setDocumentPage()
    }

    const setDocumentPage = () => {
        let filename = ''
        switch (overlayDocumentType.value) {
            case OverlayDocumentType.Uploaded:
            case OverlayDocumentType.Searches:
                filename = selectedDocument?.value?.fileName
                break
            default:
                filename = getPurchasedDocumentName(selectedDocument.value)
                break
        }

        if (titlePlanRegex.test(filename)) {
            preSelectedPages.value = [{
                pageNumber: 2,
                scale: 1250,
            }]
        }
    }

    const getPreSelectedDocument = (): any => {
        // check purchased
        let doc = purchasedDocuments.value.find(pd => pd.documentId.toString() === preSelectedDocumentId.value)
        setSelectedDocumentId(doc?.documentId, getHighLevelDocumentType(doc?.documentType))

        if (!doc) {
            // check uploaded
            doc = uploadedDocuments.value.find(ud => ud.id.toString() === preSelectedDocumentId.value)
            setSelectedDocumentId(doc?.id, HighLevelDocumentType.Uploaded)
        }

        if (!doc) {
            // check searches
            doc = searchesDocuments.value.find(ud => ud.orderItemId === preSelectedDocumentId.value || ud.orderId === preSelectedDocumentId.value)
            setSelectedDocumentId(doc?.path, HighLevelDocumentType.Searches)
        }

        if (!doc) {
            // check uploaded oc2
            doc = uploadedDocuments.value.find(ud => ud.externalId?.toString() === preSelectedDocumentId.value)
            setSelectedDocumentId(doc?.externalId, HighLevelDocumentType.UploadedOC2)
        }

        return doc || null
    }

    const getHighLevelDocumentType = (type) => {
        switch (type) {
            case 'Register':
                return HighLevelDocumentType.Register
            case 'Title Plan':
                return HighLevelDocumentType.TitlePlan
            default:
                return HighLevelDocumentType.OC2Document
        }
    }

    const setSelectedDocumentId = (id, type) => {
        if (!id || !type) {
            return
        }

        selectedDocumentId.value = id
        selectedDocumentType.value = type
    }

    const clearQueryString = () => {
        const queryParams = { ...route.query }
        delete queryParams.documentId
        delete queryParams.autoSelect
        router.replace({ query: queryParams })
    }

    onMounted(async () => {
        await resetStepFlowState()
    })
    onDeactivated(async () => {
        await resetStepFlowState()
    })
    onActivated(async () => {
        if (preSelectedDocumentId.value) {
            await loadStateFromQueryString()
        }
    })

    watch(() => currentMatterId.value, (val) => {
        if (val) {
            refreshDocuments()
        }
    }, {
        immediate: true,
    })

    defineExpose({
        currentStepId,
        getDocumentUrl,
        handleStepChange,
        preSelectedPages,
        selectedDocument,
        selectedDocumentUrl,
        selectedPages,
        loadStateFromQueryString,
        router,
        route,
    })
</script>

<style lang="scss">
    @import './map-overlays.scss';
</style>
