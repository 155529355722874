<template>
    <base-sub-type-table v-model:update-count="updateCount"
                         :detail-columns="detailColumns"
                         :notification="item"
                         data-track="OC2 Document Updated"
                         data-test="oc2-document-updated" />
</template>

<script lang="ts" setup>
    import {
        computed,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotificationDetail } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import BaseSubTypeTable
        from "@/components/asset-monitoring/grid/sub-type-items/base-sub-type-table/base-sub-type-table.vue"
    import { IBaseSubTypeTableColumn } from "@/components/asset-monitoring/grid/sub-type-items/types"
    import useDates from '@/composables/use-dates'

    defineProps<{
        item: IAssetMonitoringNotificationDetail,
    }>()

    const { t } = useI18n()
    const { formatDateShort } = useDates()
    const updateCount = defineModel<number>('update-count')

    const detailColumns = computed<IBaseSubTypeTableColumn<unknown>[]>(() => ([
        {
            field: 'type',
            title: t('label.document'),
            dataTest: 'oc2-change-row-document',
            customRender: (_row: unknown, value: string) => {
                return `<b>${ value }</b>`
            },
        },
        {
            field: 'date',
            title: t('label.updated'),
            dataTest: 'oc2-change-row-updated',
            customRender: (_row: unknown, value) => {
                return formatDateShort(value)
            },
        },
        {
            field: 'entryNumbers',
            title: t('label.entries'),
            dataTest: 'oc2-change-row-entries',
            customRender: (_row: unknown, value) => {
                return value.join(', ')
            },
        },
        {
            field: 'filedUnder',
            title: t('label.filedUnder'),
            dataTest: 'oc2-change-row-filed-under',
        },
    ]))

</script>