<template>
    <div v-if="initialGridConfig !== null"
         class="title-analysis-table">
        <v-container :class="{ 'grid-loading': loading }"
                     class="grid">
            <ow-loading-slot :loading="loading" />
            <bryntum-grid ref="grid"
                          :adopt="initialGridConfig.adopt"
                          :cell-edit-feature="initialGridConfig.cellEdit"
                          :cell-tooltip-feature="initialGridConfig.cellTooltip"
                          :column-drag-toolbar-feature="initialGridConfig.dragColumns"
                          :column-lines="initialGridConfig.columnLines"
                          :column-picker-feature="initialGridConfig.features.columnPickerFeature"
                          :columns="initialGridConfig.columns"
                          :data="data"
                          :empty-text="initialGridConfig.emptyText"
                          :excel-exporter-feature="initialGridConfig.excelExporter"
                          :fill-last-column="initialGridConfig.fillLastColumn"
                          :filter-bar-feature="initialGridConfig.features.filterBar"
                          :group-feature="initialGridConfig.features.group"
                          :group-summary-feature="initialGridConfig.groupSummary"
                          :header-menu-feature="initialGridConfig.features.headerMenu"
                          :load-mask="initialGridConfig.loadMask"
                          :min-height="initialGridConfig.minHeight"
                          :region-resize-feature="initialGridConfig.regionSize"
                          :row-height="initialGridConfig.rowHeight"
                          :search-feature="initialGridConfig.search"
                          :selection-mode="initialGridConfig.selectionMode"
                          :show-remove-row-in-context-menu="false"
                          :tree-feature="initialGridConfig.tree"
                          class="fill-height"
                          :class="{
                              'classic-light': true,
                          }"
                          data-test="title-analysis-grid"
                          quick-find-feature
                          stripe-feature
                          @cellclick="onCellClick"
                          @focusin="onFocusIn"
                          @renderrows="onRenderRows"
                          @selectionchange="onSelectionChange" />
        </v-container>
    </div>
</template>
<script>
    import { ColumnStore } from '@bryntum/grid'
    import debounce from 'lodash.debounce'
    import { markRaw } from 'vue'
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwLoadingSlot from "@/components/core/ow-loading-slot.vue"
    import BryntumGrid from '@/components/title-analysis/bryntum-grid/bryntum-grid.vue'
    // Custom column renderers
    import {
        ParentTitleNumberColumn,
        TitleNumberColumn,
    } from '@/components/title-analysis/column-renderers/_column-renderers'
    import { Colors } from '@/enums/colors.enum'
    import { DefaultBoundaryColourPalette } from "@/models/styles/default-boundary-colour-palette"
    import {
        ANALYSIS_MUTATE_GRID_INSTANCE,
        ANALYSIS_MUTATE_GROUPING,
        ANALYSIS_MUTATE_HAS_LOADED_INITIAL_GRID_STATE,
        ANALYSIS_MUTATE_IS_FILTER_BAR_VISIBLE,
        ANALYSIS_MUTATE_SELECTED_COLUMNS,
        ANALYSIS_MUTATE_SELECTED_FILTERS,
        ANALYSIS_MUTATE_SHOW_COLUMN_SELECTION,
        ANALYSIS_MUTATE_SORT,
        ANALYSIS_UPDATE_FILTER_OPTIONS,
        STATE_STORAGE_KEY,
    } from '@/store/modules/title-analysis/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        OPEN_WINDOW,
    } from '@/store/mutation-types'
    import { excelExportFromGrid } from '@/utils/excel-export'
    import { getCompaniesHouseLinkFromCompanyRegistrationNumber } from '@/utils/link-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    ColumnStore.registerColumnType(TitleNumberColumn)
    ColumnStore.registerColumnType(ParentTitleNumberColumn)

    export default {
        name: 'TitleAnalysisTable',

        components: {
            OwLoadingSlot,
            BryntumGrid,
        },

        props: {
            data: {
                type: Array,
            },
            exportData: {
                type: Number,
                default: null,
            },
            loading: {
                type: Boolean,
                default: true,
            },
            searchIndex: {
                type: Number,
                default: null,
            },
            searchText: {
                type: String,
                default: null,
            },
        },

        emits: [
            'added-column-sorts',
            'added-columns',
            'added-filters',
            'added-groupings',
            'removed-columns',
            'row-count',
            'search-count',
            'set-unpurchased-visible-titles-count',
            'title-number-selected',
            'filter-applied',
        ],

        data() {
            return {
                isSelected: false,
                groupedColumns: [],
                sortedColumns: [],
                selectedFilters: [],
                initialized: false,
                exportCount: 0,
                gridInstance: null,
                debounceUpdateStateFollowingChanges: null,
            }
        },

        computed: {
            ...mapState({
                initialGridConfig: state => state.titleAnalysis.initialGridConfig,
                selectedColumns: state => state.titleAnalysis.selectedColumns,
                hasLoadedInitialGridState: state => state.titleAnalysis.hasLoadedInitialGridState,
                appliedTemplate: state => state.titleAnalysis.appliedTemplate,
            }),
        },

        watch: {
            searchText(value) {
                this.gridInstance.features.search.search(value, false, true)
                if (value) {
                    this.$emit('search-count', this.$refs.grid.gridInstance.features.search.foundCount)
                } else {
                    this.$emit('search-count', 0)
                }
            },

            searchIndex: {
                handler(val) {
                    this.gridInstance.features.search.gotoHit(val-1)
                },
            },

            async exportData() {
                this.exportCount++
                await excelExportFromGrid(this.$refs.grid.gridInstance, 'matter-titles', {
                    onGetCellStyle: (cell, rowIndex, colIndex) => {
                        // Skip the header row
                        if (rowIndex === 1) {
                            return
                        }

                        const column = cell.worksheet.getColumn(colIndex)
                        let style = cell.style || {}
                        const palette = new DefaultBoundaryColourPalette()
                        switch (column.key) {
                            case 'complexityLabel':
                                const entry = palette.getEntryForComplexity(cell.value.toString())
                                style.font = { color: { argb: entry.colour.replace('#', '') } }
                                break
                            case 'tenureType': {
                                const entry = palette.getEntryForTenure(cell.value.toString())
                                style.font = { color: { argb: entry.colour.replace('#', '') } }
                                break
                            }
                            case 'trOwnerMatchesPublicOwner': {
                                style.alignment = { horizontal: 'center', vertical: 'middle' }
                                if (cell.value._value) {
                                    style.font = { color: { argb: Colors.Success250.replace('#', '') } }
                                } else {
                                    style.font = { color: { argb: Colors.Red700.replace('#', '') } }
                                }
                                break
                            }
                            case 'overseas': {
                                style.alignment = { horizontal: 'center', vertical: 'middle' }
                                break
                            }
                        }
                        cell.style = style
                    },
                    onGetCellValue: (cell, rowIndex, colIndex) => {
                        // Skip the header row
                        if (rowIndex === 1) {
                            return cell.value
                        }
                        const column = cell.worksheet.getColumn(colIndex)
                        switch (column.key) {
                            case 'trOwnerMatchesPublicOwner':
                                return cell.value._value === true ? 'Yes' : ''
                        }

                        // if html is present in the cell, return the title attribute value
                        if (cell?.value && typeof cell?.value === 'string' && cell.value.includes('title=')) {
                            const title = cell.value.match(/title="([^"]*)"/)
                            return title ? title[1] : ''
                        }
                        return cell.value
                    },
                })

                // Log usage to Heap
                const heapEvent = {
                    type: 'Title Analysis - Excel Export',
                    metadata: {
                        downloadsInSessionCount: this.exportCount,
                        totalTitlesCount: this.gridInstance.store.data.length,
                        filteredTitlesCount: this.gridInstance.data.filter(d => d.groupChildren === undefined).length,
                        selectedColumnsCount: this.selectedColumns.length,
                        appliedFiltersCount: this.gridInstance.getState().store.filters?.length ?? 0,
                        appliedGroupsCount: this.gridInstance.getState().store.groupers?.length ?? 0,
                    },
                }

                // Add column selection state
                this.selectedColumns.forEach(column => {
                    heapEvent.metadata[`selected_col_${ column.field }`] = true
                })
                // Add filtered columns
                // eslint-disable-next-line no-unused-expressions
                this.gridInstance.getState().store.filters?.forEach(filter => {
                    heapEvent.metadata[`filtered_col_${ filter.property }`] = filter.value
                })
                // Add grouped columns
                // eslint-disable-next-line no-unused-expressions
                this.gridInstance.getState().store.groupers?.forEach(group => {
                    heapEvent.metadata[`grouped_col_${ group.field }`] = true
                })
                // Add comma separated columns
                heapEvent.metadata.selectedColumnsList = this.selectedColumns.join(',')

                this.logHeapEvent(heapEvent)
            },

            selectedColumns(newColumns, oldColumns) {
                const addedColumns = newColumns.filter(c => !oldColumns.includes(c)).map(c => c.field)
                const removedColumns = oldColumns.filter(c => !newColumns.includes(c)).map(c => c.field)

                // Emit an event to indicate that the selected columns have been added to which may require more data be requested.
                const newColumnConfigurations = this.initialGridConfig.columns.filter(c => addedColumns.includes(c.field))

                // For logging purposes, determine which columns were added/removed
                if (oldColumns.length > 0) {
                    if (this.hasLoadedInitialGridState) {
                        if (addedColumns.length > 0) {
                            this.$emit('added-columns', newColumnConfigurations)
                        }

                        if (removedColumns.length > 0) {
                            this.$emit('removed-columns', this.initialGridConfig.columns.filter(c => removedColumns.includes(c.field)))
                        }
                    }
                }

                // Update the columns actually visible on the grid component
                if (this.gridInstance) {
                    const newFields = newColumns.map(c => c.field)
                    this.gridInstance.columns.allRecords.forEach(r => {
                        r.hidden = !newFields.includes(r.field)
                    })
                }
            },

            sortedColumns(newColumns, oldColumns) {
                // For logging purposes, determine which columns were sorted
                if (this.hasLoadedInitialGridState) {
                    const addedColumns = newColumns?.filter(c => !oldColumns?.includes(c)) ?? []
                    if (addedColumns.length > 0) {
                        this.$emit('added-column-sorts', addedColumns)
                    }
                }
            },

            selectedFilters(newFilters, oldFilters) {
                if (this.hasLoadedInitialGridState) {
                    const addedFilters = newFilters?.filter(f => !oldFilters?.some(s => s.property === f.property && s.value === f.value)) ?? []
                    if (addedFilters.length > 0) {
                        this.$emit('added-filters', addedFilters)
                    }
                    const unpurchasedVisibleTitlesCount = this.gridInstance.store.records.filter(record => (!record.editionDate && record.titleNumber)).length
                    this.$emit('set-unpurchased-visible-titles-count', unpurchasedVisibleTitlesCount)
                }
            },

            groupedColumns(newColumns, oldColumns) {
                if (this.hasLoadedInitialGridState) {
                    const addedColumns = newColumns?.filter(c => !oldColumns?.includes(c)) ?? []

                    if (addedColumns.length > 0) {
                        this.$emit('added-groupings', addedColumns)
                    }
                }
            },
        },

        mounted() {
            this.init()
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
                openWindow: OPEN_WINDOW,
                updateFilterOptions: ANALYSIS_UPDATE_FILTER_OPTIONS,
            }),

            ...mapMutations({
                showColumnSelectionMenu: ANALYSIS_MUTATE_SHOW_COLUMN_SELECTION,
                mutateHasLoadedInitialGridState: ANALYSIS_MUTATE_HAS_LOADED_INITIAL_GRID_STATE,
                mutateSelectedColumns: ANALYSIS_MUTATE_SELECTED_COLUMNS,
                mutateGridInstance: ANALYSIS_MUTATE_GRID_INSTANCE,
                mutateSort: ANALYSIS_MUTATE_SORT,
                mutateGrouping: ANALYSIS_MUTATE_GROUPING,
                mutateFilters: ANALYSIS_MUTATE_SELECTED_FILTERS,
                mutateIsFilterBarVisible: ANALYSIS_MUTATE_IS_FILTER_BAR_VISIBLE,
            }),

            init() {
                if (this.initialized === false) {
                    if (this.$refs.grid?.gridInstance) {
                        this.debounceUpdateStateFollowingChanges = debounce(this.updateStateFollowingChanges, 100)
                        this.initialized = true
                        this.gridInstance = markRaw(this.$refs.grid.gridInstance)
                        // window.gridInstance = this.gridInstance // useful for testing
                        this.mutateGridInstance(this.gridInstance)

                        this.loadPriorGridState()

                        // Log column events
                        this.gridInstance.columns.on('change', (event) => {
                            if (!event.isMove) {
                                this.$nextTick(() => {
                                    // All other events, re-asses grid state before possible action
                                    this.debounceUpdateStateFollowingChanges()
                                })
                            }
                        })

                        // Setup context menu functionality
                        this.gridInstance.features.headerMenu.items.selectColumns.onItem = () => this.showColumnSelectionMenu(true)

                        // Hide the filter bar
                        this.gridInstance.features.filterBar.hideFilterBar()
                        this.mutateIsFilterBarVisible(false)

                        this.mutateHasLoadedInitialGridState(true)
                    } else {
                        setTimeout(() => {
                            // Not possible to react to grid instance existing, so check back until it does exist.
                            this.init()
                        }, 100)
                    }
                }
            },

            onSelectionChange({ action }) {
                this.isSelected = action === 'select'
            },

            onFocusIn() {
                this.storeGridState()
            },

            onRenderRows() {
                this.init()
                if (this.debounceUpdateStateFollowingChanges) {
                    this.debounceUpdateStateFollowingChanges()
                }
            },

            updateStateFollowingChanges() {
                if (this.initialized === true) {
                    this.$emit('row-count', this.gridInstance?.data.filter(d => d.groupChildren === undefined).length)
                    if (this.hasLoadedInitialGridState) {
                        this.updateFromGridState()
                    }
                    this.storeGridState()
                }
            },

            loadPriorGridState() {
                const state = JSON.parse(localStorage.getItem(STATE_STORAGE_KEY))

                // Update filters that provide values based on the available data
                this.updateFilterOptions()

                // Restore previous state based on past configuration
                if (state) {
                    if (state.version === this.initialGridConfig.version) {
                        this.$nextTick(() => {
                            // Selected columns
                            const newColumns = state.columns.map(c => {
                                return this.initialGridConfig.columns.find(i => i.field === c)
                            })
                            this.mutateSelectedColumns(newColumns)
                            this.mutateSort(state.sortBy ?? [])
                            this.mutateGrouping(state.groupBy ?? [])
                            this.mutateFilters(state.filters ?? [])
                        })
                        this.debounceUpdateStateFollowingChanges()
                    }
                }
            },

            storeGridState() {
                // We don't want to store state resulting from templates as they are temporary, and buggy.
                if (this.appliedTemplate === null && this.gridInstance?.columns) {
                    // Record known grid state to local storage
                    const state = {
                        columns: this.gridInstance.columns.visibleColumns.map(f => f.field),
                        version: this.initialGridConfig.version,
                    }
                    if (this.gridInstance.store.sorters?.length > 0) {
                        // Assume only one sorted column
                        const sortBy = this.gridInstance.store.sorters[0]
                        state.sortBy = {
                            field: sortBy.field,
                            ascending: sortBy.ascending,
                        }
                    }
                    if (this.gridInstance.store.groupers?.length > 0) {
                        // Assume only one grouped column
                        const groupBy = this.gridInstance.store.groupers[0]
                        state.groupBy = {
                            field: groupBy.field,
                            ascending: groupBy.ascending,
                            complexMapping: false,
                        }
                    }

                    if (this.gridInstance.store.filters) {
                        state.filters = this.selectedFilters
                    }

                    localStorage.setItem(STATE_STORAGE_KEY, JSON.stringify(state))

                    this.updateFilterOptions()
                } else if (this.appliedTemplate?.name === this.$t('tam.templates.allRegisterEntries.name') && this.gridInstance?.columns) {
                    this.updateFilterOptions()
                }
            },

            updateFromGridState() {
                this.updateSelectedFilters()
                this.updateGroupedColumns()
                this.updateSortedColumns()
            },

            updateSelectedFilters() {
                const filters = []
                this.gridInstance.columns.visibleColumns.forEach(c => {
                    const appliedFilter = this.gridInstance.features.filterBar.getColumnFilterField(c)
                    if (appliedFilter) {
                        if ((!isNullOrWhitespace(appliedFilter.value) && !Array.isArray(appliedFilter.value)) ||
                            (appliedFilter.value?.length > 0 && Array.isArray(appliedFilter.value))) {
                            filters.push({
                                property: appliedFilter.name,
                                value: appliedFilter.value,
                            })
                        }
                    }
                })
                this.selectedFilters = filters
                this.$emit('filter-applied', filters.length > 0)
            },

            updateGroupedColumns() {
                this.groupedColumns = this.gridInstance?.store.groupers
            },

            updateSortedColumns() {
                this.sortedColumns = this.gridInstance?.store.sorters
            },

            onCellClick(evt) {
                // click on a group title shouldn't do any additional logic
                if (evt.cellElement.classList.contains('b-group-title')) {
                    return
                }
                if (evt.column.field === 'parentTitleNumber') {
                    if (!isNullOrWhitespace(evt.record.parentTitleNumber)) {
                        this.$emit('title-number-selected', evt.record.parentTitleNumber)
                    }
                } else if (evt.column.field === 'proprietorCompanyRegNo') {
                    // Just open the link
                    if (!isNullOrWhitespace(evt.record.proprietorCompanyRegNo)) {
                        const numbers = evt.record.proprietorCompanyRegNo.split(',')
                        if (evt.target.dataset.index !== undefined) {
                            const number = numbers[evt.target.dataset.index].trim()
                            const chLink = getCompaniesHouseLinkFromCompanyRegistrationNumber(number)
                            this.openWindow(chLink)
                        }
                    }
                } else {
                    this.$emit('title-number-selected', evt.record.titleNumber)
                }
            },
        },
    }
</script>
<style lang="scss">
    @import 'title-analysis-table.scss';
</style>
