<template>
    <div class="d-flex flex-column gr-3"
         :class="{
             'sub-types-container': isFlexContainer
         }">
        <div v-for="row in rows"
             :key="row.data.notificationSubType">
            <component :is="row.component"
                       v-model:update-count="updateCount"
                       :item="row.data"
                       :title-number="titleNumber"
                       :notification-id="notificationId"
                       :showing-details-panel="showingDetailsPanel"
                       @area-difference="handleBoundaryArea" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        markRaw,
        ref,
        watch,
    } from 'vue'
    import { useStore } from 'vuex'

    import { IAssetMonitoringNotificationDetail } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import BoundaryUpdatedItem from '@/components/asset-monitoring/grid/sub-type-items/boundary-updated.vue'
    import CompletedPendingApplicationItem
        from '@/components/asset-monitoring/grid/sub-type-items/completed-pending-application.vue'
    import EpcUpdatedItem from '@/components/asset-monitoring/grid/sub-type-items/epc-updated-item.vue'
    import Oc2DocumentUpdatedItem from '@/components/asset-monitoring/grid/sub-type-items/oc2-document-updated.vue'
    import OwnershipChangeItem from '@/components/asset-monitoring/grid/sub-type-items/ownership-change.vue'
    import PlanningApplicationsItem
        from '@/components/asset-monitoring/grid/sub-type-items/planning-applications-item.vue'
    import { NotificationSubType } from '@/components/asset-monitoring/notification-sub-type.enum'

    const props = defineProps<{
        notificationDetails: Array<IAssetMonitoringNotificationDetail>,
        titleNumber: string,
        notificationId: string,
        showingDetailsPanel: boolean,
    }>()

    const emit = defineEmits<{
        (e: 'area-difference', value: number),
    }>()

    const store = useStore()
    const rows = ref([])
    const isFlexContainer = ref(false)
    const updateCount = defineModel('update-count')

    const getSubTypeComponent = (notificationSubType: NotificationSubType) => {
        switch (notificationSubType) {
            case NotificationSubType.CompletedPendingApplication:
                return markRaw(CompletedPendingApplicationItem)

            case NotificationSubType.NewDocumentInOCDA:
            case NotificationSubType.EditionDateDoesntMatchOcdaResponse:
                return markRaw(Oc2DocumentUpdatedItem)

            case NotificationSubType.OwnershipChange:
                return markRaw(OwnershipChangeItem)

            case NotificationSubType.BoundaryChange:
                isFlexContainer.value = true
                return markRaw(BoundaryUpdatedItem)

            case NotificationSubType.Epc:
                return markRaw(EpcUpdatedItem)

            case NotificationSubType.PlanningApplications:
                if (!store.state?.config?.featureFlags?.assetMonitoringPlanningApplications) {
                    return false
                }
                return markRaw(PlanningApplicationsItem)

            case NotificationSubType.CompaniesHouse:
            case NotificationSubType.Unknown:
                console.error('Unknown notification sub type')
        }
    }

    watch(() => props.notificationDetails, () => {
        rows.value = props.notificationDetails?.map((item) => {
            return {
                component: getSubTypeComponent(item.notificationSubType),
                data: item,
            }
        })
    }, { immediate: true })

    const handleBoundaryArea = (area: number) => {
        emit('area-difference', area)
    }
</script>

<style scoped lang="scss">
    @import './sub-type-renderer.scss';
</style>
