<template>
    <div ref="gridRef">
        <ow-data-grid v-model="selected"
                      :headers="headers"
                      :height="gridHeight"
                      :items="documents"
                      :no-data-text="$t('documents.upload.table.noData')"
                      :sort-by="sortBy"
                      class="uploaded-documents-table"
                      has-pagination
                      has-pagination-all-option
                      hover
                      item-key="id">
            <template #[`item.fileName`]="{ item }">
                <td :title="item.fileName"
                    class="body-regular uploaded-documents-table__filename">
                    <v-icon>$uploaded-file</v-icon>
                    <span class="uploaded-documents-table__filename--text">{{ item.fileName }}</span>
                </td>
            </template>
            <template #[`item.documentType`]="{ item }">
                <td class="caption-regular uploaded-documents-table__item text-no-wrap">
                    {{ documentTypeText(item) }}
                </td>
            </template>
            <template #[`item.titleNumber`]="{ item }">
                <td :class="{clickable: Boolean(item.titleNumber)}"
                    class="caption-regular uploaded-documents-table__item"
                    data-test="uploaded-documents-table-title-number"
                    @click="onTitleNumberCellClick(item)">
                    {{ item.titleNumber }}
                </td>
            </template>
            <template #[`item.updatedOn`]="{ item }">
                <td class="caption-regular uploaded-documents-table__item">
                    {{ formatDateShort(item.updatedOn) }}
                </td>
            </template>
            <template #[`item.userName`]="{ item }">
                <td :title="item.userName"
                    class="caption-regular uploaded-documents-table__item body-regular uploaded-documents-table__user">
                    {{ item.userName }}
                </td>
            </template>
            <template #[`item.status`]="{ item }">
                <td class="uploaded-documents-table__item text-no-wrap">
                    <ow-colored-label :theme="statusLabelColour(item)"
                                      is-extra-small>
                        {{ labelText(item) }}
                    </ow-colored-label>
                    <span v-if="!isItemUploadSuccessful(item)">{{ statusText(item) }}</span>
                </td>
            </template>
            <template #[`item.actions`]="{ item }">
                <td class="d-flex justify-space-between align-center"
                    :class="['caption-regular', 'uploaded-documents-table__item',
                             'uploaded-documents-table__actions',
                             'text-no-wrap',
                             isItemUploadSuccessful(item) ? '' : 'show-only-remove-button']">
                    <span v-if="isItemUploadSuccessful(item)">
                        <ow-loading-text v-if="!isDocumentAvailable(item)"
                                         :is-loading="true"
                                         :text="getDocumentStatusText(item)" />
                        <a v-if="isDocumentAvailable(item)"
                           :data-track-document-id="item.id"
                           :data-track-matter-id="currentMatterId"
                           class="body-regular uploaded-documents-table__item--link"
                           data-test="uploaded-documents-table-view-doc-btn"
                           data-track="DOCUMENTS - view an uploaded file"
                           href="#"
                           @click.prevent="view(item)">
                            {{ $t('action.view') }}
                        </a>
                        <v-icon :data-track-document-id="item.id"
                                :data-track-matter-id="currentMatterId"
                                :disable="isDownloading"
                                class="body-regular uploaded-documents-table__item--icon"
                                data-test="uploaded-documents-table-download-doc-btn"
                                data-track="DOCUMENTS - download an uploaded file"
                                @click="download(item)">
                            $download
                        </v-icon>
                    </span>
                    <remove-document-button :document="item" />
                </td>
            </template>
        </ow-data-grid>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from "vue-i18n"

    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import OwLoadingText from "@/components/core/ow-loading-text.vue"
    import RemoveDocumentButton from '@/components/documents/remove-document-button.vue'
    import useDates from '@/composables/use-dates'
    import useWindowResize from '@/composables/use-window-resize'
    import { uploadDocumentFailureResponses,
             UploadedDocumentStatus } from '@/enums/uploaded-document-status.enum'
    import { UploadedDocumentType } from '@/enums/uploaded-document-type.enum'
    import { IUploadedDocument } from '@/interfaces/uploaded-document.interface'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const props = defineProps<{
        documents: Array<IUploadedDocument>
        currentMatterId: number
        isDownloading?: boolean
    }>()

    const emit = defineEmits<{
        (e: 'download', document: IUploadedDocument): void
        (e: 'view', document: IUploadedDocument): void
        (e: 'title-number-selected', titleNumber: string): void
    }>()

    const gridHeight = ref('0')
    const gridRef = ref(null)
    const selected = defineModel<Array<IUploadedDocument>>({
        default: [],
    })
    const sortBy = ref([{ key: 'fileName', order: 'asc' }])
    const { t } = useI18n()

    useWindowResize({
        onResize({ clientHeight }) {
            const copilotHeight = document.querySelector('.cta-copilot')?.clientHeight ?? 80
            gridHeight.value = ((clientHeight - gridRef.value.getBoundingClientRect().top) - copilotHeight - 60).toString()
        },
    })

    const headers = computed(() => {
        return [
            {
                title: t('documents.upload.table.headings.document'),
                align: 'left',
                key: 'fileName',
            },
            {
                title: t('table.label.type'),
                align: 'left',
                key: 'documentType',
            },
            {
                title: t('table.label.title'),
                align: 'left',
                key: 'titleNumber',
            },
            {
                title: t('documents.upload.table.headings.date'),
                align: 'left',
                key: 'updatedOn',
            },
            {
                title: t('documents.upload.table.headings.user'),
                align: 'left',
                key: 'userName',
            },
            {
                title: t('documents.upload.table.headings.status'),
                align: 'left',
                key: 'status',
            },
            {
                title: t('documents.upload.table.headings.actions'),
                align: 'center',
                key: 'actions',
                sortable: false,
            },
        ]
    })

    const statusText = (item: IUploadedDocument): string | null => {
        switch (item.status) {
            case UploadedDocumentStatus.duplicate: {
                return t('documents.upload.status.duplicate')
            }
            case UploadedDocumentStatus.failedAntiVirusCheck: {
                return t('documents.upload.status.virus')
            }
            case UploadedDocumentStatus.invalidFileContent: {
                return t('documents.upload.status.corrupted')
            }
            case UploadedDocumentStatus.invalidFileType: {
                return t('documents.upload.status.invalid')
            }
            case UploadedDocumentStatus.pending: {
                return t('documents.upload.status.pending')
            }
            case UploadedDocumentStatus.scanningFailed: {
                return t('documents.upload.status.virus')
            }
            case UploadedDocumentStatus.complete: {
                return t('documents.upload.status.success')
            }
            default: {
                // User doesn't care about scanning, extracting/parsing/...
                return t('documents.upload.status.processing')
            }
        }
    }

    const labelText = (item: IUploadedDocument): string => {
        return isItemUploadSuccessful(item)
            ? t('documents.upload.status.success')
            : t('documents.upload.status.failed')
    }

    const documentTypeText = (item: IUploadedDocument): string => {
        if (uploadDocumentFailureResponses.includes(item.status)) {
            return '-'
        }
        switch (item.type) {
            case UploadedDocumentType.TitleRegister:
                return t('documents.upload.documentType.titleRegister')
            case UploadedDocumentType.TitlePlan:
                return t('documents.upload.documentType.titlePlan')
            case UploadedDocumentType.OfficialCopy:
                return t('documents.upload.documentType.officialCopy')
            case UploadedDocumentType.Other:
                return t('documents.upload.documentType.other')
            case UploadedDocumentType.Detecting:
                return t('documents.upload.documentType.detecting')
            case UploadedDocumentType.CautionTitle:
                return t('documents.upload.documentType.cautionTitle')
            case UploadedDocumentType.ScotLISTitleSheet:
                return t('documents.upload.documentType.scotLISTitleSheet')
        }
    }

    const isItemUploadSuccessful = (item: IUploadedDocument): boolean => {
        return !uploadDocumentFailureResponses.includes(item.status)
    }

    const isDocumentAvailable = (item: IUploadedDocument): boolean => {
        return (item.type !== UploadedDocumentType.OfficialCopy) || (item.status === UploadedDocumentStatus.uploaded && (item.externalId === null || !isNullOrWhitespace(item.augmentedFilename)))
    }

    const getDocumentStatusText = (item: IUploadedDocument): string => {
        if (uploadDocumentFailureResponses.includes(item.status)) {
            return t('documents.upload.status.error')
        }
        if (item.status !== UploadedDocumentStatus.uploaded) {
            return t('documents.upload.status.processing')
        }
        return t('documents.upload.status.enhancing')
    }

    const statusLabelColour = (item: IUploadedDocument): string => {
        return isItemUploadSuccessful(item) ? 'success' : 'warning'
    }

    const download = async (item: IUploadedDocument): Promise<void> => {
        emit('download', item)
    }

    const view = async (item: IUploadedDocument): Promise<void> => {
        emit('view', item)
    }

    const onTitleNumberCellClick = (item: IUploadedDocument) => {
        if (item.titleNumber) {
            emit('title-number-selected', item.titleNumber)
        }
    }

    const formatDateShort = (value: Date): string => {
        const { formatDateShort } = useDates()
        return formatDateShort(value)
    }

    watch(() => props.documents, (val) => {
        selected.value = selected.value.filter((document) => val.some((newDocument) => newDocument.id === document.id))
    })
</script>

<style lang="scss">
    @import './uploaded-documents-table';
</style>
