<template>
    <matter-side-panel-add-titles-option class="draw-on-map-option"
                                         import-method="draw"
                                         :current-selected="modelValue"
                                         :title="$t('matter.addTitlesPanel.drawOnMap.heading')"
                                         data-test="matter-add-titles-draw"
                                         @select="$emit('update:modelValue', 'draw')">
        <span v-t="'matter.addTitlesPanel.drawOnMap.prompt'"
              class="caption-regular" />
        <span v-t="'matter.addTitlesPanel.drawOnMap.tip'"
              class="caption-regular" />

        <div v-if="drawnAreaFormatted != null"
             class="caption-regular">
            {{ $t('matter.addTitlesPanel.drawOnMap.area') }}: <span v-dompurify-html="drawnAreaFormatted" />
            <span v-if="drawnArea > maxImportAreaSqM"
                  style="color:red">&nbsp; Area too large!
            </span>
        </div>

        <div v-if="drawnAreaFormatted != null"
             class="caption-regular">
            Click 'Add Titles' to finish.
        </div>

        <div class="draw-on-map-option__controls">
            <ow-button v-if="hasDrawnSomething"
                       v-t="'matter.addTitlesPanel.drawOnMap.reset'"
                       is-secondary
                       small
                       @click="resetDrawing" />
            <div class="spacer" />
            <ow-button v-if="isDrawing"
                       v-t="'matter.addTitlesPanel.drawOnMap.stopDrawing'"
                       is-secondary
                       small
                       @click="stopDrawing" />
            <ow-button v-if="!isDrawing && !hasDrawnSomething"
                       v-t="'matter.addTitlesPanel.drawOnMap.startDrawing'"
                       is-secondary
                       small
                       @click="startDrawing" />
        </div>

        <ow-checkbox id="add-title-store-sketch"
                     v-model="saveAsSketch"
                     :label="$t('matter.addTitlesPanel.drawOnMap.saveAsSketch')"
                     data-test="matter-add-titles-store-sketch-checkbox" />

        <div>
            <ow-checkbox id="import-only-within"
                         v-model="importOnlyWithinTitles"
                         :label="$t('matter.addTitlesPanel.uploadShapeFile.onlyWithin')"
                         data-test="matter-add-titles-within-checkbox"
                         style="margin-bottom: 10px" />
            <ow-tooltip activator="parent"
                        :position="OwTooltipPosition.Right">
                {{ $t('matter.addTitlesPanel.uploadShapeFile.onlyWithinTooltip') }}
            </ow-tooltip>
        </div>

        <add-to-group v-model="selectedGroup" />

        <ow-button v-t="'matter.addTitlesPanel.drawOnMap.addTitles'"
                   is-primary
                   small
                   :disabled="!isValid"
                   data-track="MAT-TITLE-LIST - Add titles to the matter"
                   data-test="draw-on-map-add-titles-button"
                   @click="importTitles" />
    </matter-side-panel-add-titles-option>
</template>

<script setup lang="ts">
    import { getArea } from 'ol/sphere'
    import {
        computed,
        onBeforeUnmount,
        ref,
        watch,
    } from 'vue'
    import { useStore } from "vuex"

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import OwTooltip from "@/components/core/ow-tooltip.vue"
    import {OwTooltipPosition} from "@/enums/ow-tooltip-position"
    import {
        SketchSource,
    } from '@/enums/sketches-enums'
    import i18n from '@/plugins/i18n'
    import {
        MATTER_IMPORT_FROM_WKT_AREA,
        MATTER_REQUEST_SHOW_TITLE_LIST,
    } from '@/store/modules/matter/types'
    import { TitlePanels } from '@/store/modules/title-panels'
    import { TITLE_MUTATE_ACTIVE_TITLE_PANEL } from '@/store/modules/titles/types'
    import {
        LOGGING_LOG_FEATURE_USAGE,
        MATTER_DRAW_ANNOTATION_CLEAR_FEATURES,
        MATTER_DRAW_ANNOTATION_ENABLE,
        MATTER_DRAW_ANNOTATION_END,
        MATTER_DRAW_ANNOTATION_SET_MODE,
        MATTER_DRAW_GET_WKT,
    } from '@/store/mutation-types'
    import { format } from '@/utils/date-utils'

    import AddToGroup, { GroupItem } from './add-to-group.vue'
    import MatterSidePanelAddTitlesOption from './matter-side-panel-add-titles-option.vue'
    import { useStoreSketch } from './use-store-sketch'

    const store = useStore()

    const props = defineProps<{modelValue: string}>()
    defineEmits<{
        (e: 'update:modelValue', importMethod: string)
    }>()

    const saveAsSketch = ref(true)
    const importOnlyWithinTitles = ref(false)

    const selectedGroup = ref<GroupItem | undefined>(undefined)

    const isDrawing = computed(() => store.state.matterDraw.interactions.draw?.getActive() ?? false)
    const drawLayerSource = computed(() => store.state.matterDraw.source)
    const drawnArea = computed(() => {
        let value = 0
        if (store.state.matterDraw.source != null) {
            store.state.matterDraw.source.forEachFeature((feature) => {
                const area = getArea(feature.getGeometry())
                value = value + area
            })
            return Math.round(value)
        }
        return 0
    })
    const drawnAreaFormatted = computed(() => {
        let output
        let value
        if (drawnArea.value !== undefined) {
            if (drawnArea.value > 10000) {
                value = (Math.round(drawnArea.value / 1000000 * 100) / 100)
                output = `${ value.toLocaleString() } km<sup>2</sup>`
            } else if (drawnArea.value > 0) {
                value = (Math.round(drawnArea.value * 100) / 100)
                output = `${ value.toLocaleString() } m<sup>2</sup>`
            }
            return output
        }
        return null
    })

    const stopDrawing = () => {
        store.dispatch(MATTER_DRAW_ANNOTATION_ENABLE, false)
        store.dispatch(MATTER_DRAW_ANNOTATION_END)
    }

    const startDrawing = () => {
        store.dispatch(MATTER_DRAW_ANNOTATION_ENABLE, true)
        store.dispatch(MATTER_DRAW_ANNOTATION_SET_MODE, 'polygon')
    }

    const resetDrawing = async () => {
        await store.dispatch(MATTER_DRAW_ANNOTATION_CLEAR_FEATURES)
    }

    watch(() => props.modelValue, () => {
        if (props.modelValue === 'draw') {
            startDrawing()
        } else {
            stopDrawing()
            resetDrawing()
        }
    })

    const hasDrawnSomething = computed(() => {
        return (store.state.matterDraw.source?.getFeatures().length ?? false) > 0
    })

    const maxImportAreaSqM = 20000000
    const isValid = computed(() => {
        return hasDrawnSomething.value && drawnArea.value <= maxImportAreaSqM
    })

    const { storeSketchRequest } = useStoreSketch()

    const importTitles = async () => {
        await store.dispatch(MATTER_DRAW_ANNOTATION_END)
        const geometryWkt = await store.dispatch(MATTER_DRAW_GET_WKT)
        const features = drawLayerSource.value.getFeatures()
        const request = {
            geometries: geometryWkt,
            matterGroupId: selectedGroup.value?.id ?? null,
            matterId: store.state.matter.currentMatter.id,
            onlyWithin: importOnlyWithinTitles.value,
        }

        const titlesAdded = await store.dispatch(MATTER_IMPORT_FROM_WKT_AREA, request)
        await store.dispatch(LOGGING_LOG_FEATURE_USAGE, {
            type: 'search-map-draw-added-titles',
            description: titlesAdded,
        })

        if (saveAsSketch.value) {
            // save as a sketch
            const sketchName = i18n.global.t('matter.drawOnMapImportSketchName', { timestamp: format(new Date(), 'dd-MM-yyyy') }) as string
            await storeSketchRequest(sketchName, features, SketchSource.DrawOnMapTitleSearchImport)
        }

        stopDrawing()
        await resetDrawing()

        await store.dispatch(MATTER_REQUEST_SHOW_TITLE_LIST)
        store.commit(TITLE_MUTATE_ACTIVE_TITLE_PANEL, TitlePanels.LIST)
    }

    onBeforeUnmount(() => {
        stopDrawing()
        resetDrawing()
    })

    defineExpose({
        importTitles,
        saveAsSketch,
    })

</script>

<style lang="scss">
    @import 'draw-on-map';
</style>
