<template>
    <div class="base-row-item-title">
        <div class="base-row-item-title__info d-flex align-center gc-2">
            <h3 class="base-row-item-title__info--type d-flex align-center gc-1"
                :class="{
                    'body-highlight': !item.isRead,
                    'body-regular': item.isRead
                }">
                <span v-if="itemPriority"
                      class="priority__container d-flex align-center justify-center"
                      :class="{
                          'priority--high': itemPriority === NotificationPriorityType.High,
                          'priority--low': itemPriority === NotificationPriorityType.Low,
                          'priority--standard': itemPriority === NotificationPriorityType.Standard
                      }">
                    <v-icon class="priority__icon">
                        $alert
                    </v-icon>
                    <ow-tooltip :position="OwTooltipPosition.Top"
                                activator="parent"
                                nudge-right="4px"
                                nudge-top="5px">
                        <span>{{ `${$t(`assetMonitoring.filters.priority.${itemPriority?.toLowerCase()}`)}` }}</span>
                    </ow-tooltip>
                </span>
                {{ `${ model.title }:` }}
            </h3>
        </div>
    </div>
</template>

<script setup lang="ts">

    import { computed } from 'vue'

    import { IAssetMonitoringNotification } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import { BaseRowItemModel } from '@/components/asset-monitoring/base-row-item-model'
    import { NotificationPriorityType } from '@/components/asset-monitoring/notification-priority-type.enum'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'

    const props = defineProps<{
        model: BaseRowItemModel,
        item: IAssetMonitoringNotification,
    }>()

    const itemPriority = computed<string>(() => {
        return props.item.notificationDetails?.[0]?.priority
    })
</script>

<style lang="scss"
       scoped>
@import './base-row-item-title';
</style>