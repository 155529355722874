<template>
    <base-row-item v-bind="props"
                   v-model="isShowingDetailsPanel"
                   :model="rowModel"
                   @mark-as-read="emit('mark-as-read', $event)"
                   @view-title="buttonClickHandler">
        <template #actions>
            <ow-button is-secondary
                       is-small
                       :disabled="!item?.titleNumber && isNullOrEmpty(item?.companyNumbers)"
                       :data-track="dataTrack"
                       :data-test="dataTest"
                       @click.stop="buttonClickHandler">
                {{ viewTitleText }}
            </ow-button>
        </template>
        <template v-if="hasExtraInfo"
                  #extra-info>
            <slot v-bind="{ numberOfUpdates }" />
        </template>
    </base-row-item>
</template>

<script lang="ts"
        setup>
    import {
        computed,
        ref,
    } from 'vue'

    import { IAssetMonitoringNotification } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import { BaseRowItemModel } from "@/components/asset-monitoring/base-row-item-model"
    import BaseRowItem from "@/components/asset-monitoring/grid/row-items/base-row-item.vue"
    import { NotificationSubType } from '@/components/asset-monitoring/notification-sub-type.enum'
    import { NotificationType } from "@/components/asset-monitoring/notification-type.enum"
    import OwButton from '@/components/core/ow-button-ds.vue'
    import { isNullOrEmpty } from "@/utils/array-utils"

    const numberOfUpdates = ref<number>(0)
    const isShowingDetailsPanel = defineModel<boolean>()

    const props = defineProps<{
        item: IAssetMonitoringNotification,
        matterId: number,

        viewTitleText: string,
        hasExtraInfo: boolean,
        rowModel: BaseRowItemModel,
    }>()

    const emit = defineEmits<{
        (e: 'view-title', {
            titleNumber,
            notificationType,
            notificationSubType,
        }: {
            titleNumber: string
            notificationType: NotificationType
            notificationSubType: NotificationSubType
        }),
        (e: 'view-company', {
            companyNumbers,
            notificationType,
            notificationSubType,
        }: {
            companyNumbers: string[]
            notificationType: NotificationType
            notificationSubType: NotificationSubType
        }),
        (e: 'mark-as-read', payload: { notificationId: string, isRead: boolean }),
    }>()

    const buttonClickHandler = async (): Promise<void> => {
        if (context.value === 'Company') {
            emit('view-company', {
                companyNumbers: props.item?.companyNumbers,
                notificationType: props.item.notificationType,
                notificationSubType: props.item.notificationDetails?.[0].notificationSubType,
            })
            return
        }
        emit('view-title', {
            titleNumber: props.item?.titleNumber,
            notificationType: props.item.notificationType,
            notificationSubType: props.item.notificationDetails?.[0].notificationSubType,
        })
    }

    const context = computed(() => !isNullOrEmpty(props.item?.companyNumbers) ? 'Company' : 'title')

    const dataTrack = computed(() => {
        const value = props.item?.titleNumber ?? props.item?.companyNumbers.join(', ')
        return `View ${ context.value } - ${ value }`
    })

    const dataTest = computed(() => {
        const value = props.item?.titleNumber ?? props.item?.companyNumbers.join(', ')
        return `view-title-${ context.value }-${ value }`
    })
</script>
