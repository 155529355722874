/**
 * Represents the sub-type of notification.
 *
 * @enum {string}
 */
export enum NotificationPriorityType {
    Standard = "standard",
    High = "high",
    Low = "low",
}
