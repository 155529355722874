// Getters
export const MATTER_GET_LINK_SHARE_URL = 'MATTER_GET_LINK_SHARE_URL'
export const MATTER_HAS_TITLE_NUMBER = 'MATTER_HAS_TITLE_NUMBER'
export const MATTER_GET_TYPES = 'matter/GET_TYPES'
export const MATTER_IS_LINK_SHARE_DIRTY = 'MATTER_IS_LINK_SHARE_DIRTY'
export const MATTER_GET_IS_CURRENT_MATTER_CLOSED = 'MATTER_GET_IS_CURRENT_MATTER_CLOSED'
export const MATTER_GET_CURRENT_MATTER_ID = 'MATTER_GET_CURRENT_MATTER_ID'
export const MATTER_GET_MATTER_CONTENTS = 'MATTER_GET_MATTER_CONTENTS'
export const MATTER_GET_AVAILABLE_SERVICES = 'MATTER_GET_AVAILABLE_SERVICES'
export const MATTER_GET_IS_SKETCHES_VISIBLE_MAP = 'MATTER_GET_IS_SKETCHES_VISIBLE_MAP'
export const MATTER_GET_SKETCHES_UNIT = 'MATTER_GET_SKETCHES_UNIT'
export const MATTER_IS_SHARED_LINK_VIEW = 'MATTER_IS_SHARED_LINK_VIEW'
export const MATTER_GET_LINK_SHARE_GUID = 'MATTER_GET_LINK_SHARE_GUID'
export const MATTER_GET_BY_TITLE_NUMBER = 'MATTER_GET_BY_TITLE_NUMBER'
export const MATTER_GET_ASSET_MONITORING_TITLES = 'MATTER_GET_ASSET_MONITORING_TITLES'

/**
 * Matter Actions
 */
export const MATTER_ADD_MULTIPLE_TITLES = 'MATTER_ADD_MULTIPLE_TITLES'
export const MATTER_ADD_TITLE = 'MATTER_ADD_TITLE'
export const MATTER_ADD_TITLES_TO_GROUP = 'MATTER_ADD_TITLES_TO_GROUP'
export const MATTER_CREATE = 'MATTER_CREATE'
export const MATTER_CREATE_GROUP = 'MATTER_CREATE_GROUP'
export const MATTER_CREATE_LINK_SHARE = 'MATTER_CREATE_LINK_SHARE'
export const MATTER_DELETE = 'MATTER_DELETE'
export const MATTER_ENABLE_LINK_SHARE = 'MATTER_ENABLE_LINK_SHARE'
export const MATTER_EXPORT_OFFICIAL_COPIES = 'MATTER_EXPORT_OFFICIAL_COPIES'
export const MATTER_EXPORT_SPREADSHEET_DAYLIST = 'MATTER_EXPORT_SPREADSHEET_DAYLIST'
export const MATTER_FETCH_ADDRESSES_BY_MATTER_ID = 'MATTER_FETCH_ADDRESSES_BY_MATTER_ID'
export const MATTER_GET_ADDRESSES_FOR_TITLES = 'MATTER_GET_ADDRESSES_FOR_TITLES'
export const MATTER_UPDATE_MATTER_READ_STATUS = 'MATTER_UPDATE_MATTER_READ_STATUS'
export const MATTER_HIGHLIGHT_BOUNDARY = 'MATTER_HIGHLIGHT_BOUNDARY' // pass title number
export const MATTER_IMPORT_FROM_WKT_AREA = 'MATTER_IMPORT_FROM_WKT_AREA'
export const MATTER_IMPORT_FROM_SPREADSHEET = 'MATTER_IMPORT_FROM_SPREADSHEET'
export const MATTER_PROMPT_CREATE = 'MATTER_PROMPT_CREATE'
export const MATTER_REFRESH_PERMISSIONS = 'MATTER_REFRESH_PERMISSIONS'
export const MATTER_REMOVE_GROUP = 'MATTER_REMOVE_GROUP'
export const MATTER_REMOVE_HIGHLIGHT_BOUNDARY = 'MATTER_REMOVE_HIGHLIGHT_BOUNDARY' // no param required, assumes only one is ever highlighted
export const MATTER_REMOVE_TITLES = 'MATTER_REMOVE_TITLES'
export const MATTER_REMOVE_TITLES_FROM_GROUP = 'MATTER_REMOVE_TITLES_FROM_GROUP'
export const MATTER_ADD_TITLES_TO_MATTER_AND_GROUP = 'MATTER_ADD_TITLES_TO_MATTER_AND_GROUP'
export const MATTER_RESET_CURRENT = 'MATTER_RESET_CURRENT'
export const MATTER_SELECT_TITLENUMBER = 'MATTER_SELECT_TITLENUMBER'
export const MATTER_SET_CURRENT_BY_ID = 'MATTER_SET_CURRENT_BY_ID'
export const MATTER_SET_CURRENT_MATTER = 'MATTER_SET_CURRENT_MATTER'
export const MATTER_SET_TITLES_STYLE = 'MATTER_SET_TITLES_STYLE'
export const MATTER_SHOW_BOUNDARIES = 'MATTER_SHOW_BOUNDARIES' // {title: object, show: bool}
export const MATTER_UPDATE = 'MATTER_UPDATE'
export const MATTER_UPDATE_CURRENT_MATTER = 'MATTER_UPDATE_CURRENT_MATTER'
export const MATTER_UPDATE_CURRENT_MATTER_CHARGES = 'MATTER_UPDATE_CURRENT_MATTER_CHARGES'
export const MATTER_INITIALISE_BOUNDARY_LAYER = 'MATTER_INITIALISE_BOUNDARY_LAYER'
export const MATTER_UPDATE_GROUP = 'MATTER_UPDATE_GROUP'
export const MATTER_UPDATE_GROUP_SORT = 'MATTER_UPDATE_GROUP_SORT'
export const MATTER_UPDATE_LINK_SHARE = 'MATTER_UPDATE_LINK_SHARE'
export const MATTER_UPDATE_PERMISSIONS = 'MATTER_UPDATE_PERMISSIONS'
export const MATTER_UPDATE_TITLE_SORT = 'MATTER_UPDATE_TITLE_SORT'
export const MATTER_ZOOM_TO_CURRENT_MATTER_EXTENT = 'MATTER_ZOOM_TO_CURRENT_MATTER_EXTENT'
export const MATTER_FETCH_DIGITAL_REGISTER_AVAILABILITY_FOR_TITLE_NUMBERS = 'MATTER_FETCH_DIGITAL_REGISTER_AVAILABILITY_FOR_TITLE_NUMBERS'
export const MATTER_UPDATE_MATTER_STATE = 'MATTER_UPDATE_MATTER_STATE'
export const MATTER_UPDATE_BOUNDARY_LAYER = 'MATTER_UPDATE_BOUNDARY_LAYER'
export const MATTER_UPDATE_BOUNDARY_LAYER_TITLES = 'MATTER_UPDATE_BOUNDARY_LAYER_TITLES'
export const MATTER_GROUP_SET_EXPANDED = 'MATTER_GROUP_SET_EXPANDED'
export const MATTER_AVAILABLE_SERVICES_INIT = 'MATTER_AVAILABLE_SERVICES_INIT'
export const MATTER_UPDATE_SKETCHES_VISIBLE_MAP = 'MATTER_UPDATE_SKETCHES_VISIBLE_MAP'
export const MATTER_CLEAR_CURRENT_MATTER = 'MATTER_CLEAR_CURRENT_MATTER'
export const MATTER_UPDATE_SKETCHES_SETTINGS = 'MATTER_UPDATE_SKETCHES_SETTINGS'
export const MATTER_SET_BOUNDARY_HIGHLIGHT_VISIBLE = 'MATTER_SET_BOUNDARY_HIGHLIGHT_VISIBLE'

export const MATTER_HANDLE_SET_CURRENT_MATTER_ERROR = 'MATTER_HANDLE_SET_CURRENT_MATTER_ERROR'
export const MATTER_EXPORT_TITLE_ANALYSIS_REPORT = 'MATTER_EXPORT_TITLE_ANALYSIS_REPORT'

export const MATTER_DUPLICATE = 'MATTER_DUPLICATE'

/**
 * Matter - title import types
 */
export const MATTER_REQUEST_SHOW_TITLE_LIST = 'MATTER_REQUEST_SHOW_TITLE_LIST'

// Mutations
export const MATTER_MUTATE_CREATE_GROUP_PROMPT = 'MATTER_MUTATE_CREATE_GROUP_PROMPT'
export const MATTER_MUTATE_CREATE_GROUP_TITLES = 'MATTER_MUTATE_CREATE_GROUP_TITLES'
export const MATTER_MUTATE_CURRENT_MATTER = 'MATTER_MUTATE_CURRENT_MATTER'
export const MATTER_MUTATE_CURRENT_MATTER_ADDRESSES = 'MATTER_MUTATE_CURRENT_MATTER_ADDRESSES'
export const MATTER_MUTATE_LINK_SHARING_DETAILS = 'MATTER_MUTATE_LINK_SHARING_DETAILS'
export const MATTER_MUTATE_LINK_SHARING_ENABLED = 'MATTER_MUTATE_LINK_SHARING_ENABLED'
export const MATTER_MUTATE_LINK_SHARING_LOADING = 'MATTER_MUTATE_LINK_SHARING_LOADING'
export const MATTER_MUTATE_MATTER_READ_STATUS = 'MATTER_MUTATE_MATTER_READ_STATUS'
export const MATTER_MUTATE_TITLES_TO_ADD_AFTER_MATTER_CREATE = 'MATTER_MUTATE_TITLES_TO_ADD_AFTER_MATTER_CREATE'
export const MATTER_MUTATE_LOADING = 'MATTER_MUTATE_LOADING'
export const MATTER_MUTATE_LOADING_STATE = 'MATTER_MUTATE_LOADING_STATE'
export const MATTER_MUTATE_LOADING_LIST = 'MATTER_MUTATE_LOADING_LIST'
export const MATTER_MUTATE_UPDATE_STATE_ERROR = 'MATTER_MUTATE_UPDATE_STATE_ERROR'
export const MATTER_MUTATE_LINK_SHARE_DIRTY = 'MATTER_MUTATE_LINK_SHARE_DIRTY'
export const MATTER_MUTATE_MATTER_LIST_FROM_CURRENT_MATTER = 'MATTER_MUTATE_MATTER_LIST_FROM_CURRENT_MATTER'
export const MATTER_MUTATE_MATTERS = 'MATTER_MUTATE_MATTERS'
export const MATTER_MUTATE_PENDING_LOADING_MATTER_ID = 'MATTER_MUTATE_PENDING_LOADING_MATTER_ID'
export const MATTER_MUTATE_RESET_LINK_SHARE = 'MATTER_MUTATE_RESET_LINK_SHARE'
export const MATTER_MUTATE_PENDING_REQUEST_TO_SHOW_TITLE_LIST = 'MATTER_MUTATE_PENDING_REQUEST_TO_SHOW_TITLE_LIST'
export const MATTER_MUTATE_RESET_CURRENT_MATTER = 'MATTER_MUTATE_RESET_CURRENT_MATTER'
export const MATTER_MUTATE_SHOW_LINK_SHARE_SETTINGS_DIALOG = 'MATTER_MUTATE_SHOW_LINK_SHARE_SETTINGS_DIALOG'
export const MATTER_MUTATE_SORT_ORDER_FOR_ALL_GROUPS = 'MATTER_MUTATE_SORT_ORDER_FOR_ALL_GROUPS'
export const MATTER_MUTATE_STYLE_PROMPT = 'MATTER_MUTATE_STYLE_PROMPT'
export const MATTER_MUTATE_TITLE = 'MATTER_MUTATE_TITLE'
export const MATTER_MUTATE_WALKTHROUGH_ID = 'MATTER_MUTATE_WALKTHROUGH_ID'
export const MATTER_MUTATE_ZOOM_TO_EXTENT_ON_TITLE_BOUNDARIES_LOADED = 'MATTER_MUTATE_ZOOM_TO_EXTENT_ON_TITLE_BOUNDARIES_LOADED'
export const MATTER_MUTATE_PENDING_CHANGES_TO_LINK_SHARE_SETTINGS = 'MATTER_MUTATE_PENDING_CHANGES_TO_LINK_SHARE_SETTINGS'
export const MATTER_MUTATE_BULK_ORDER_LOADING = 'MATTER_MUTATE_BULK_ORDER_LOADING'
export const MATTER_MUTATE_BULK_ORDER_RESPONSE = 'MATTER_MUTATE_BULK_ORDER_RESPONSE'
export const MATTER_MUTATE_CURRENT_MATTER_STATE = 'MATTER_MUTATE_CURRENT_MATTER_STATE'
export const MATTER_MUTATE_LIST_ITEM_MATTER_STATE_BY_MATTER_ID = 'MATTER_MUTATE_LIST_ITEM_MATTER_STATE_BY_MATTER_ID'

export const MATTER_MUTATE_SHOW_EXPORT_DIALOG = 'MATTER_MUTATE_SHOW_EXPORT_DIALOG'
export const MATTER_MUTATE_HIDE_EXPORT_DIALOG = 'MATTER_MUTATE_HIDE_EXPORT_DIALOG'

export const MATTER_MUTATE_TITLE_ORGANISER_ENABLED = 'MATTER_MUTATE_TITLE_ORGANISER_ENABLE'
export const MATTER_MUTATE_TITLE_ORGANISER_SET_SERVICE = 'MATTER_MUTATE_TITLE_ORGANISER_SET_SERVICE'
export const MATTER_MUTATE_TITLE_ORGANISER_MATTER_CONTENT = 'MATTER_MUTATATE_TITLE_ORGANISER_MATTER_CONTENT'
export const MATTER_MUTATE_TITLE_ORGANISER_QUICK_STYLING = 'MATTER_MUTATE_TITLE_ORGANISER_QUICK_STYLING'
export const MATTER_MUTATE_TITLE_ORGANISER_SELECTED_FIELD = 'MATTER_MUTATE_TITLE_ORGANISER_SELECTED_FIELD'
export const MATTER_MUTATE_SELECTED_TITLES = 'MATTER_MUTATE_SELECTED_TITLES'
export const MATTER_MUTATE_GROUPS = 'MATTER_MUTATE_GROUPS'
export const MATTER_MUTATE_TITLE_ORGANISER_LOADING = 'MATTER_MUTATE_TITLE_ORGANISER_LOADING'
export const MATTER_MUTATE_GROUP_SET_EXPANDED = 'MATTER_MUTATE_GROUP_SET_EXPANDED'
export const MATTER_MUTATE_SKETCHES_VISIBLE_MAP = 'MATTER_MUTATE_SKETCHES_VISIBLE_MAP'
export const MATTER_MUTATE_SKETCHES_SETTINGS = 'MATTER_MUTATE_SKETCHES_SETTINGS'
export const MATTER_MUTATE_SHOW_DUPLICATE_MATTER_DIALOG = 'MATTER_MUTATE_SHOW_DUPLICATE_MATTER_DIALOG'
export const MATTER_MUTATE_DUPLICATE_MATTER_ID = 'MATTER_MUTATE_DUPLICATE_MATTER_ID'
export const MATTER_MUTATE_REMOVE_DOCUMENTS_PROMPT = 'MATTER_MUTATE_REMOVE_DOCUMENTS_PROMPT'
export const MATTER_GET_REMOVE_DOCUMENTS_PROMPT_STATE = 'MATTER_GET_REMOVE_DOCUMENTS_PROMPT_STATE'
export const MATTER_MUTATE_SET_FULLSCREEN_TITLE_PANEL = 'MATTER_MUTATE_SET_FULLSCREEN_TITLE_PANEL'
export const MATTER_GET_FULLSCREEN_TITLE_PANEL = 'MATTER_GET_FULLSCREEN_TITLE_PANEL'
