// Getters
export const TITLE_GET_ALL_TITLE_DATA_SORTED_BY_NUMBER = 'TITLE_GET_ALL_TITLE_DATA_SORTED_BY_NUMBER'
export const TITLE_GET_INFORMATION_BY_TITLE_NUMBERS = 'TITLE_GET_INFORMATION_BY_TITLE_NUMBERS'
export const TITLE_GET_SELECTED_TITLE = 'title/GET_SELECTED_TITLE'
export const TITLE_GET_SELECTED_TITLE_NUMBER = 'title/GET_SELECTED_TITLE_NUMBER'
export const TITLE_GET_SHOW_MULTI_TITLE_PANEL = 'title/GET_SHOW_MULTI_TITLE_PANEL'
export const TITLE_GET_TITLES_LOADING = 'titles/GET_TITLES_LOADING'
export const TITLE_GET_IS_REGISTER_PURCHASED = 'TITLE_GET_IS_REGISTER_PURCHASED'
export const TITLE_GET_IS_PLAN_PURCHASED = 'TITLE_GET_IS_PLAN_PURCHASED'
export const TITLE_GET_OWNS_OC2_DOCUMENTS = 'TITLE_GET_OWNS_OC2_DOCUMENTS'
export const TITLE_GET_OWNS_COPILOT_OC2_DOCS = 'TITLE_GET_OWNS_COPILOT_OC2_DOCS'

// Mutations
export const TITLE_MUTATE_ACTIVE_TITLE_PANEL = 'TITLE_MUTATE_ACTIVE_TITLE_PANEL'
export const TITLE_MUTATE_ADDRESSES = 'TITLE_MUTATE_ADDRESSES'
export const TITLE_MUTATE_INFORMATION = 'TITLE_MUTATE_INFORMATION'
export const TITLE_MUTATE_LOADING_STATE = 'TITLE_MUTATE_LOADING_STATE'
export const TITLE_MUTATE_CLEAR_TITLES = 'titles/MUTATE_CLEAR_TITLES'
export const TITLE_MUTATE_COLLAPSE_PANEL = 'TITLE_MUTATE_COLLAPSE_PANEL'
export const TITLE_MUTATE_DAYLIST = 'TITLE_MUTATE_DAYLIST'
export const TITLE_MUTATE_DAYLIST_LOADING = 'TITLE_MUTATE_DAYLIST_LOADING'
export const TITLE_MUTATE_END_LOOKUP_TITLE = 'TITLE_MUTATE_END_LOOKUP_TITLE'
export const TITLE_MUTATE_ERROR_MESSAGE = 'TITLE_MUTATE_ERROR_MESSAGE'
export const TITLE_MUTATE_LOADING = 'TITLE_MUTATE_LOADING'
export const TITLE_MUTATE_LOOKUP_TITLE_ERROR = 'TITLE_MUTATE_LOOKUP_TITLE_ERROR'
export const TITLE_MUTATE_MULTI_TITLE_SELECTION_PANEL = 'titles/MUTATE_MULTI_TITLE_SELECTION_PANEL'
export const TITLE_MUTATE_OWNER_DATA_SOURCE_ON_SUMMARY = 'TITLE_MUTATE_OWNER_DATA_SOURCE_ON_SUMMARY'
export const TITLE_MUTATE_REGISTER_INDICATOR_SELECTION = 'TITLE_MUTATE_REGISTER_INDICATOR_SELECTION'
export const TITLE_MUTATE_REGISTER_INDICATOR_TEXT_SELECTION_SPAN = 'TITLE_MUTATE_REGISTER_INDICATOR_TEXT_SELECTION_SPAN'
export const TITLE_MUTATE_ENTRY_TYPES_SELECTION = 'TITLE_MUTATE_ENTRY_TYPES_SELECTION'
export const TITLE_MUTATE_SELECTED_TITLE = 'titles/MUTATE_SET_SELECTED_TITLE'
export const TITLE_MUTATE_SELECTED_TITLE_LOADING = 'titles/MUTATE_SELECTED_TITLE_LOADING'
export const TITLE_MUTATE_SELECTED_TITLE_NUMBER = 'titles/MUTATE_SELECTED_TITLE_NUMBER'
export const TITLE_MUTATE_SELECTED_TITLE_RECORD = 'TITLE_MUTATE_SELECTED_TITLE_RECORD'
export const TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_BACKDATED_TITLE_PLAN = 'TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_BACKDATED_TITLE_PLAN'
export const TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_REGISTER = 'TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_REGISTER'
export const TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_TITLE_PLAN = 'TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_TITLE_PLAN'
export const TITLE_MUTATE_SELECTED_TITLE_OFFICIAL_COPIES_AVAILABILITY = 'TITLE_MUTATE_SELECTED_TITLE_DOCUMENT_AVAILABILITY'
export const TITLE_MUTATE_SET_TITLES = 'TITLE_MUTATE_SET_TITLES'
export const TITLE_MUTATE_START_LOOKUP_DAYLIST = 'TITLE_MUTATE_START_LOOKUP_DAYLIST'

export const TITLE_MUTATE_DAYLIST_ERROR = 'TITLE_MUTATE_DAYLIST_ERROR'
export const TITLE_MUTATE_START_LOOKUP_TITLE = 'TITLE_MUTATE_START_LOOKUP_TITLE'
export const TITLE_MUTATE_UPDATED_TITLE_DOCUMENTS = 'TITLE_MUTATE_UPDATED_TITLE_DOCUMENTS'
export const TITLE_MUTATE_SELECTED_SUMMARY_TITLE = 'TITLE_MUTATE_SELECTED_SUMMARY_TITLE'
export const TITLE_MUTATE_LOADING_SUMMARY_TITLE = 'TITLE_MUTATE_LOADING_SUMMARY_TITLE'
export const TITLE_MUTATE_LOADING_OFFICIAL_COPIES_AVAILABILITY = 'TITLE_MUTATE_LOADING_OFFICIAL_COPIES_AVAILABILITY'
export const TITLE_MUTATE_OFFICIAL_COPY_PROPERTIES = 'TITLE_MUTATE_OFFICIAL_COPY_PROPERTIES'
export const TITLE_MUTATE_REGISTER_DATA_FROM_ORDER_MESSAGE = 'TITLE_MUTATE_REGISTER_DATA_FROM_ORDER_MESSAGE'
export const TITLE_MUTATE_REGISTER_ORDER_LOADING = 'TITLE_MUTATE_REGISTER_ORDER_LOADING'
export const TITLE_MUTATE_OC2_DATA_FROM_ORDER_MESSAGE = 'TITLE_MUTATE_OC2_DATA_FROM_ORDER_MESSAGE'
export const TITLE_MUTATE_TITLE_PLAN_DATA_FROM_ORDER_MESSAGE = 'TITLE_MUTATE_TITLE_PLAN_DATA_FROM_ORDER_MESSAGE'
export const TITLE_MUTATE_OC2_DOCUMENT_STATUS = 'TITLE_MUTATE_OC2_DOCUMENT_STATUS'
export const TITLE_MUTATE_TITLE_PLAN_ORDER_LOADING = 'TITLE_MUTATE_TITLE_PLAN_ORDER_LOADING'
export const TITLE_MUTATE_EXPANDED_TITLE_NUMBER = 'TITLE_MUTATE_EXPANDED_TITLE_NUMBER'

// Actions
export const TITLE_CLEAR = 'TITLE_CLEAR'
export const TITLE_FETCH_BACKDATED_REGISTER = 'TITLE_FETCH_BACKDATED_REGISTER'
export const TITLE_FETCH_BACKDATED_TITLE_PLAN = 'TITLE_FETCH_BACKDATED_TITLE_PLAN'
export const TITLE_FETCH_NEW_REGISTER_STRING = 'TITLE_FETCH_NEW_REGISTER_STRING'
export const TITLE_FETCH_NEW_TITLE_PLAN_STRING = 'TITLE_FETCH_NEW_TITLE_PLAN_STRING'
export const TITLE_FETCH_ORGANISATION_TITLES = 'TITLE_FETCH_ORGANISATION_TITLES' // provide a title number param array
export const TITLE_FETCH_REGISTER_STRING = 'TITLE_FETCH_REGISTER_STRING'
export const TITLE_FETCH_TITLE_PLAN_STRING = 'TITLE_FETCH_TITLE_PLAN_STRING'
export const TITLE_FETCH_TITLE_ADDRESSES = 'TITLE_FETCH_TITLE_ADDRESSES'
export const TITLE_HIDE_MULTI_TITLE_SELECTION_PANEL = 'titles/TITLE_HIDE_MULTI_TITLE_SELECTION_PANEL'
export const TITLE_LOOKUP_AND_ZOOM_TO_TITLE = 'TITLE_LOOKUP_AND_ZOOM_TO_TITLE'
export const TITLE_LOOKUP_TITLE = 'TITLE_LOOKUP_TITLE' // provide a title number param
export const TITLE_LOOKUP_TITLE_BG = 'TITLE_LOOKUP_TITLE_BG' // provide a title number param
export const TITLE_LOOKUP_TITLE_DAYLIST = 'TITLE_LOOKUP_TITLE_DAYLIST' // provide a title number param
export const TITLE_LOOKUP_TITLE_LEASEHIERARCHY = 'TITLE_LOOKUP_TITLE_LEASEHIERARCHY' // provide a title number param
export const TITLE_PANEL_COLLAPSE = 'TITLE_PANEL_COLLAPSE' // with boolean param
export const TITLE_SELECT_BY_UPRN = 'TITLE_SELECT_BY_UPRN' // with string uprn param
export const TITLE_SET_SELECTED_TITLE_NUMBER = 'titles/SET_SELECTED_TITLE_NUMBER'
export const TITLE_SET_TITLES = 'TITLE_SET_TITLES' // provide a title number param array
export const TITLE_SHOW_MULTI_TITLE_SELECTION_PANEL = 'titles/TITLE_SHOW_MULTI_TITLE_SELECTION_PANEL'
export const TITLE_SHOW_TITLE_BOUNDARY = 'TITLE_SHOW_TITLE_BOUNDARY'
export const TITLE_SHOW_USER_POPUP_AFTER_BG_FETCH = 'TITLE_SHOW_USER_POPUP_AFTER_BG_FETCH'
export const TITLE_SET_SELECTED_TITLE_RECORD = 'TITLE_SET_SELECTED_TITLE_RECORD'
export const TITLE_FETCH_TITLE_SUMMARY_BY_NUMBER = 'TITLE_FETCH_TITLE_SUMMARY_BY_NUMBER'
export const TITLE_FETCH_OFFICIAL_COPIES_AVAILABILITY = 'TITLE_FETCH_OFFICIAL_COPIES_AVAILABILITY'
export const TITLE_ZOOM_TO_TITLE_AND_SHOW_BOUNDARY = 'TITLE_ZOOM_TO_TITLE_AND_SHOW_BOUNDARY'
export const TITLE_ZOOM_TO_TITLE_USING_UPRN = 'TITLE_ZOOM_TO_TITLE_USING_UPRN'
export const TITLE_SHOW_SELECTION_LAYER = 'TITLE_SHOW_SELECTION_LAYER'
export const TITLE_DAYLIST_EXPORT_CSV = 'TITLE_DAYLIST_EXPORT_CSV'
