<template>
    <am-alerts ref="base"
               v-model:items="items"
               v-model:selected-items="selectedItems"
               :matter-id="matterId"
               :splash-page="!isActive"
               @splash-button-click="onEnableFeature"
               @clear="onClear"
               @item-check="onAlertFilterCheck"
               @refresh="refreshNotificationsHandler"
               @sort-by="sortItemsByHandler" />
</template>

<script lang="ts" setup>
    import { debounce } from "lodash"
    import {
        computed,
        onMounted,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import {
        useRoute,
        useRouter,
    } from 'vue-router'
    import { useStore } from 'vuex'

    import CompanyGroupsApi from "@/api/company-groups.api"
    import { NotificationSorting } from '@/api/notifications.api'
    import AmAlerts from "@/components/asset-monitoring/am-alerts.vue"
    import { IAssetMonitoringNotification } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import { NotificationFilterType } from "@/components/asset-monitoring/monitoring-preferences/notification-filter-type.enum"
    import { NotificationPriorityType } from "@/components/asset-monitoring/notification-priority-type.enum"
    import { NotificationSubType } from "@/components/asset-monitoring/notification-sub-type.enum"
    import { Route } from '@/enums/route.enum'
    import { checkFlag } from "@/feature-flags"
    import { IOptionLinkItem } from "@/interfaces/option-item.interface"
    import { useAssetMonitoringStore } from '@/stores/asset-monitoring'
    import {
        dynamicSort,
        isNullOrEmpty,
    } from '@/utils/array-utils'

    const assetMonitoringStore = useAssetMonitoringStore()

    const { t } = useI18n()
    const route = useRoute()
    const store = useStore()

    const items = ref<IOptionLinkItem[]>([])
    const selectedItems = ref<IOptionLinkItem[]>([])
    const companyGroups = ref<any[]>()
    const base = ref<typeof AmAlerts>()

    const matterId = computed<number>(() => parseInt(route.params?.matterId?.toString()))
    const isActive = computed(() => assetMonitoringStore.titleNotificationSettings.isActive || assetMonitoringStore.companyNotificationSettings.isActive)

    const userNotifications = computed<IAssetMonitoringNotification[]>(() => assetMonitoringStore.userNotifications)
    const availableTitleNumbers = computed<string[]>(() => assetMonitoringStore.availableTitleNumbers)
    const availableCompanies = computed<string[]>(() => assetMonitoringStore.availableCompanies)
    const router = useRouter()

    // Need to add addresses from the VueX store into the notifications
    const matterAddresses = computed((): Record<string, Array<any>> => store.state?.matter?.currentMatter?.addresses)

    const setAddresses = () => {
        if (matterAddresses.value &&
            Object.keys(matterAddresses.value).length > 0 &&
            !isNullOrEmpty(userNotifications.value)) {
            userNotifications.value.forEach((item) => {
                item.address = matterAddresses.value[item.titleNumber]?.[0]?.address ?? null
            })
        }
    }
    watch(() => userNotifications.value, (): void => {
        setAddresses()
    }, { immediate: true })

    watch(() => matterAddresses.value, (): void => {
        setAddresses()
    }, { immediate: true })

    const sortItemsByHandler = (value: string) => {
        switch (value) {
            case 'asc':
                assetMonitoringStore.updateSortBy(NotificationSorting.dateAscending, matterId.value)
                break
            default:
                assetMonitoringStore.updateSortBy(NotificationSorting.dateDescending, matterId.value)
                break
        }
    }

    const populateFilterItems = () => {
        let result: IOptionLinkItem[] = []

        if (checkFlag('asset-monitoring-priorities', false)) {
            result.push(
                {
                    title: t('assetMonitoring.filters.priority.heading'),
                    value: NotificationFilterType.Priority,
                    disableCheck: true,
                    items: [
                        {
                            title: t('assetMonitoring.filters.priority.standard'),
                            value: NotificationPriorityType.Standard,
                            checked: false,
                            tag: 'priority',
                            parentId: NotificationFilterType.Priority,
                        },
                        {
                            title: t('assetMonitoring.filters.priority.high'),
                            value: NotificationPriorityType.High,
                            checked: false,
                            tag: 'priority',
                            parentId: NotificationFilterType.Priority,
                        },
                    ].filter(item => assetMonitoringStore.availablePriorities.includes(item.value)),
                },
            )
        }

        // add types
        result.push(
            {
                title: t('assetMonitoring.filters.alertTypes'),
                value: NotificationFilterType.Type,
                disableCheck: true,
                items: [{
                            title: t('assetMonitoring.subType.boundaryUpdated.title'),
                            value: NotificationSubType.BoundaryChange,
                            checked: false,
                            tag: 'type',
                            parentId: NotificationFilterType.Type,
                        },
                        {
                            title: t('assetMonitoring.subType.editionDateDoesntMatchOcdaResponse.title'),
                            value: NotificationSubType.EditionDateDoesntMatchOcdaResponse,
                            checked: false,
                            tag: 'type',
                            parentId: NotificationFilterType.Type,
                        },
                        {
                            title: t('assetMonitoring.subType.oc2DocumentUpdated.title'),
                            value: NotificationSubType.NewDocumentInOCDA,
                            checked: false,
                            tag: 'type',
                            parentId: NotificationFilterType.Type,
                        },
                        {
                            title: t('assetMonitoring.subType.ownershipUpdated.title'),
                            value: NotificationSubType.OwnershipChange,
                            checked: false,
                            tag: 'type',
                            parentId: NotificationFilterType.Type,
                        },
                        {
                            title: t('assetMonitoring.subType.pendingApplicationsUpdated.title'),
                            value: NotificationSubType.CompletedPendingApplication,
                            checked: false,
                            tag: 'type',
                            parentId: NotificationFilterType.Type,
                        },
                        {
                            title: t('assetMonitoring.subType.companiesHouseUpdated.title'),
                            value: NotificationSubType.CompaniesHouse,
                            checked: false,
                            tag: 'type',
                            parentId: NotificationFilterType.Type,
                        },
                        {
                            title: t('assetMonitoring.subType.epc.title'),
                            value: NotificationSubType.Epc,
                            checked: false,
                            tag: 'type',
                            parentId: NotificationFilterType.Type,
                        },
                ].filter(item => assetMonitoringStore.availableNotificationSubTypes.includes(item.value)).sort(dynamicSort('title'))})

        // add title numbers
        result.push(
            {
                title: t('assetMonitoring.filters.titles'),
                value: NotificationFilterType.TitleNumber,
                disableCheck: true,
                items: availableTitleNumbers.value.map((title) => {
                    return {
                        tag: 'title',
                        title,
                        value: title,
                        checked: selectedTitles.value.some((t) => t.value === title),
                        parentId: NotificationFilterType.TitleNumber,
                    } as IOptionLinkItem
                }).sort(dynamicSort('title')),
            },
        )

        // add companies
        result.push(
            {
                title: t('assetMonitoring.filters.companies'),
                value: NotificationFilterType.Company,
                disableCheck: true,
                items: availableCompanies.value.map((availableCompany) => {
                    // find company group
                    const group = companyGroups.value.find((group) => group.companies.some((c) => c.companyNumber === availableCompany))
                    if (!group?.isEnabled) {
                        return null
                    }

                    // find the company in the group
                    const company = group.companies.find((c) => c.companyNumber === availableCompany)
                    if (!company) {
                        return null
                    }

                    return {
                        title: company.name,
                        value: company.companyNumber,
                        checked: false,
                        parentId: NotificationFilterType.Company,
                        tag: 'company',
                        linkedIds: [group.id],
                        linkedParentId: NotificationFilterType.Group,
                    } as IOptionLinkItem
                }).filter(c => c).sort(dynamicSort('title')),
            } as IOptionLinkItem)

        // add groups
        result.push(
            {
                title: t('assetMonitoring.filters.groups'),
                value: NotificationFilterType.Group,
                disableFilter: true,
                disableCheck: true,
                items: availableCompanies.value.map((availableCompany) => {
                    // find company group
                    const group = companyGroups.value.find((group) => group.companies.some((c) => c.companyNumber === availableCompany))
                    if (!group) {
                        return null
                    }
                    if (group?.isEnabled) {
                        return {
                            title: group.groupName,
                            value: group.id,
                            checked: false,
                            parentId: NotificationFilterType.Group,
                            linkedIds: group.companies.map((c) => c.companyNumber),
                            linkedParentId: NotificationFilterType.Company,
                        } as IOptionLinkItem
                    }
                }).filter(g => g).sort(dynamicSort('title')),
            },
        )

        // filter out empty groups
        result = result.filter((item) => item.items.length > 0)

        // update items
        items.value = result
    }

    const fetch = async () => {
        await base.value?.fetch()

        // check for company groups
        try {
            const resp = await CompanyGroupsApi.getCompanyGroups(matterId.value)
            companyGroups.value = resp.data?.companyGroups
        } catch (e) {
            console.error('Failed to fetch company groups', e)
            companyGroups.value = []
        }
    }

    const onEnableFeature = async () => {
        await router.push({
            name: Route.AssetMonitoringSettings,
            params: {
                matterId: matterId.value,
                tabId: 'titles',
            },
        })
    }

    const onAlertFilterCheck = async (item: IOptionLinkItem) => {
        let id: number = item.parentId
        if (isNaN(id)) {
            id = parseInt(item.value.toString())
        }
        switch (id) {
            case NotificationFilterType.Type:
                await debounceUpdateSelectedNotificationSubTypes()
                break
            case NotificationFilterType.TitleNumber:
                await debounceUpdateTitleNumbers()
                break
            case NotificationFilterType.Group:
            case NotificationFilterType.Company:
                await debounceUpdateCompanies()
                break
            case NotificationFilterType.Priority:
                await debounceUpdateSelectedPriorities()
                break

        }
    }

    const companies = computed<IOptionLinkItem[]>(() => {
        return items.value.find((item) => item.value === NotificationFilterType.Company)?.items.filter((item) => item.checked) || []
    })

    const priorities = computed<IOptionLinkItem[]>(() => {
        return items.value.find((item) => item.value === NotificationFilterType.Priority)?.items.filter((item) => item.checked) || []
    })

    const updateCompanies = async (): Promise<void> => {
        const companyNumbers = companies.value.map((item) => item.value.toString())
        await assetMonitoringStore.updateSelectedCompanies(matterId.value, companyNumbers)
    }

    const updatedSelectedPriorities = async (): Promise<void> => {
        await assetMonitoringStore.updateSelectedPriorities(matterId.value, priorities.value.map((item) => item.value.toString()))
    }

    const initialise = async () => {
        await fetch()
    }

    onMounted(async () => {
        await initialise()
        populateFilterItems()
    })

    const onClear = async () => {
        assetMonitoringStore.isLoading = true
        try {
            assetMonitoringStore.clear()
            selectedItems.value = []
            items.value = []

            await initialise()
            populateFilterItems()
        } finally {
            assetMonitoringStore.isLoading = false
        }
    }

    const selectedTitles = computed<IOptionLinkItem[]>(() => {
        return items.value.find((item) => item.value === NotificationFilterType.TitleNumber)?.items.filter((item) => item.checked) || []
    })

    const enabledFilters = computed<IOptionLinkItem[]>(() => {
        return items.value.find((item) => item.value === NotificationFilterType.Type)?.items.filter((item) => item.checked) || []
    })

    const updateTitleNumbers = async (): Promise<void> => {
        await assetMonitoringStore.updateSelectedTitles(matterId.value, selectedTitles.value.map(t => t.value.toString()))
    }

    const updateSelectedNotificationSubTypes = async (): Promise<void> => {
        await assetMonitoringStore.updateSelectedNotificationSubTypes(matterId.value, enabledFilters.value.map(t => t.value.toString()))
    }

    const debounceUpdateTitleNumbers = debounce(updateTitleNumbers, 1000)
    const debounceUpdateSelectedNotificationSubTypes = debounce(updateSelectedNotificationSubTypes, 1000)
    const debounceUpdateCompanies = debounce(updateCompanies, 1000)
    const debounceUpdateSelectedPriorities = debounce(updatedSelectedPriorities, 1000)


    const refreshNotificationsHandler = async () => {
        await onClear()
    }

    watch(() => assetMonitoringStore.reloadNotifications, async (val) => {
        if (val) {
            await refreshNotificationsHandler()
            assetMonitoringStore.reloadNotifications = false
        }
    }, {
        immediate: true,
    })

    watch(() => assetMonitoringStore.reloadFilters, (val) => {
        if (val) {
            selectedItems.value = []

            populateFilterItems()
            assetMonitoringStore.reloadFilters = false
        }
    })
</script>
