<template>
    <ow-splash-page :title="title"
                    :subtitle="subtitle"
                    icon="ow-icon-asset-monitoring"
                    data-track-prefix="Asset Monitoring"
                    data-test-prefix="asset-monitoring"
                    :button-title="buttonTitle"
                    @button-click="onButtonClick">
        <template #footer>
            <div v-if="t(`assetMonitoring.splashPage.monitorOptions.${ type }`)">
                <p class="body-regular">
                    {{ t('assetMonitoring.splashPage.canMonitor') }}
                </p>
                <ul class="body-regular">
                    <li v-for="translation in tm(`assetMonitoring.splashPage.monitorOptions.${ type }`)"
                        :key="translation">
                        {{ translation }}
                    </li>
                </ul>
            </div>
        </template>
    </ow-splash-page>
</template>

<script lang="ts" setup>
    import {
        computed,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import OwSplashPage from "@/components/core/ow-splash-page.vue"
    import { useAssetMonitoringStore } from '@/stores/asset-monitoring'

    const props = withDefaults(defineProps<{
        buttonTitle: string,
        title: string
        type: 'all' | 'company'
    }>(), {
        buttonTitle: 'Enable monitoring',
        type: 'all',
    })

    const emit = defineEmits(['button-click'])

    const assetMonitoringStore = useAssetMonitoringStore()
    const { t, tm } = useI18n()
    const isMonitoringActive = computed(() => assetMonitoringStore.titleNotificationSettings.isActive)
    const canEnableMonitoring = computed(() => !isMonitoringActive.value)

    watch(isMonitoringActive, (newValue) => {
        console.info('isMonitoringActive', newValue)
    })
    watch(canEnableMonitoring, (newValue) => {
        console.info('canEnableMonitoring', newValue)
    })

    const subtitle = computed(() => {
        if (props.type === 'company') {
            return t('assetMonitoring.splashPage.descriptionCompany')
        }
        return t('assetMonitoring.splashPage.descriptionAll')
    })

    const onButtonClick = async () => {
        emit('button-click')
    }
</script>
