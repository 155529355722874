<template>
    <base-sub-type-table v-model:update-count="updateCount"
                         :headings="headings"
                         :notification="item"
                         data-track="Epc Updated Item"
                         data-test="epc-updated-item" />
</template>

<script setup lang="ts">
    import {
        computed,
    } from "vue"
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotificationDetail } from "@/components/asset-monitoring/asset-monitoring-notification.interface"
    import { EpcDatapoint } from "@/components/asset-monitoring/datapoint-interfaces"
    import BaseSubTypeTable
        from "@/components/asset-monitoring/grid/sub-type-items/base-sub-type-table/base-sub-type-table.vue"
    import { IBaseSubTypeTableColumn } from "@/components/asset-monitoring/grid/sub-type-items/types"
    import { EnergyBand } from "@/enums/epc.enum"
    import { getThemeForEnergyBand } from "@/models/epc.model"
    import { format } from "@/utils/date-utils"

    const { t } = useI18n()
    const updateCount = defineModel<number>('update-count')

    const headings = computed<IBaseSubTypeTableColumn<EpcDatapoint>[]>(() => ([
        {
            field: 'energyBand',
            title: t('label.energyBand'),
            dataTest: 'energyBand',
            customRender: (row: EpcDatapoint, value: string) => {
                if (!value) {
                    return '-'
                }

                // draw the energy band as an arrow
                const theme = getThemeForEnergyBand(value as EnergyBand)
                return `
                        <div style="max-height: 16px" class="ow-coloured-chevron-label ${ theme } is-small">
                            <div class="ow-coloured-chevron-label__triangle"></div>
                            <div class="ow-coloured-chevron-label__label pl-0"><span class="body-regular-xx-small font-weight-bold">${ value }${ row.energyRating ? (`|${ row.energyRating }`) : '' }</span></div>
                        </div>`
            },
        },
        {
            field: 'energyRating',
            title: t('label.energyRating'),
            dataTest: 'energyRating',
        },
        {
            field: 'address',
            title: t('label.address'),
            dataTest: 'address',
        },
        {
            field: 'postcode',
            title: t('label.postcode'),
            dataTest: 'postcode',
        },
        {
            field: 'potentialEnergyBand',
            title: t('label.potentialEnergyBand'),
            dataTest: 'potentialEnergyBand',
            customRender: (row: EpcDatapoint, value: string) => {
                if (!value) {
                    return '-'
                }

                // draw the energy band as an arrow
                const theme = getThemeForEnergyBand(value as EnergyBand)
                return `
                        <div style="max-height: 16px" class="ow-coloured-chevron-label ${ theme } is-small">
                            <div class="ow-coloured-chevron-label__triangle"></div>
                            <div class="ow-coloured-chevron-label__label pl-0"><span class="body-regular-xx-small font-weight-bold">${ value }${ row.potentialEnergyRating ? (`|${ row.potentialEnergyRating }`) : '' }</span></div>
                        </div>`
            },
        },
        {
            field: 'expiryDate',
            title: t('label.expiryDate'),
            dataTest: 'expiryDate',
            customRender: (_row: EpcDatapoint, value: string) => {
                return format(new Date(value))
            },
        },
        {
            field: 'expired',
            title: t('label.expired'),
            dataTest: 'expired',
            customRender: (_row: EpcDatapoint, value: string) => {
                return value ? t('label.yes') : t('label.no')
            },
        },
    ]))

    defineProps<{
        item: IAssetMonitoringNotificationDetail,
    }>()

    defineExpose({
        updateCount,
    })
</script>