import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import {
    NavAction,
    NavItem,
} from '@/components/top-nav/types'
import { Route } from '@/enums/route.enum'
import { checkFlag } from '@/feature-flags'
import { useAssetMonitoringStore } from '@/stores/asset-monitoring'

const useAssetMonitoringTopNav = () => {

    const { t } = useI18n()

    const assetMonitoringStore = useAssetMonitoringStore()
    const unreadCompanyNotifications = computed(() => assetMonitoringStore.unreadCompanyNotifications)
    const unreadTitleNotifications = computed(() => assetMonitoringStore.unreadTitleNotifications)
    const pendingNotifications = computed(() => assetMonitoringStore.pendingNotifications?.length ?? 0)
    const unreadNotifications = computed(() => unreadCompanyNotifications.value + unreadTitleNotifications.value + pendingNotifications.value)
    const loading = computed(() => assetMonitoringStore.isLoading)

    const isActive = computed(() => assetMonitoringStore.titleNotificationSettings.isActive)
    const topNavItems = computed<NavItem[]>(() => {
        return [
            {
                title: t('assetMonitoring.navigationHeadings.alerts').toString(),
                route: Route.AssetMonitoringAlerts,
                childNavName: 'alerts',
                dataTrackId: 'AM Top Nav - Alerts clicked',
                dataTestId: 'am-top-nav-alerts',
                alertCount: !isActive.value ? undefined : unreadNotifications.value,
                loading: loading.value,
            },
            {
                title: t('assetMonitoring.navigationHeadings.settings').toString(),
                route: Route.AssetMonitoringSettings,
                routeParams: { tabId: 'titles' },
                childNavName: 'settings',
                dataTrackId: 'AM Top Nav - Settings clicked',
                dataTestId: 'am-top-nav-settings',
            },
        ]
    })

    const topNavActions = computed<NavAction[]>(() => {
        return [ {
            title: t('assetMonitoring.navigationActions.addCompany'),
            dataTrackId: 'AM Top Nav - Add Company clicked',
            dataTestId: 'am-top-nav-add-company',
        } as NavAction ]
    })

    const isSummaryTabEnabled = computed(() => checkFlag('asset-monitoring-summary', false))

    const subNavItems = computed<NavItem[]>(() => {
        return [
            {
                title: t('label.feed').toString(),
                route: Route.AssetMonitoringAlerts,
                childNavName: 'feed',
                subChildNavName: 'feed',
                dataTrackId: 'AM Sub Nav - feed clicked',
                dataTestId: 'am-sub-nav-titles',
                isEnabled: true,
            },
            {
                title: t('label.summary').toString(),
                route: Route.AssetMonitoringSummary,
                childNavName: 'summary',
                subChildNavName: 'summary',
                dataTrackId: 'AM Sub Nav - Summary clicked',
                dataTestId: 'am-sub-nav-titles',
                isEnabled: isSummaryTabEnabled.value,
            },
        ]
    })

    return {
        topNavItems,
        subNavItems,
        topNavActions,
    }
}

export default useAssetMonitoringTopNav
