import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState('config', {
            flags: state => state.featureFlags,
            shouldAllowDebugMode: state => state.featureFlags?.allowDebugMode,
            shouldForceLoadLatestVersion: state => state.featureFlags?.forceLoadLatestVersion,
            promptToLoadLatestVersion: state => state.featureFlags?.promptLoadLatestVersion,
            isShutDownEverythingEnabled: state => state.featureFlags?.shutDownEverything,
            isFindNearbyCorporateOwnersEnabled: state => state.featureFlags?.findNearbyCorporateOwners,
            isMlTagsEnabled: state => state.featureFlags?.mlFlags,
            isDescriptiveDownloadSitePlanButtonEnabled: state => state.featureFlags?.descriptiveDownloadSitePlanButton,
            isUploadDocumentsEnabled: state => state.featureFlags?.uploadDocuments,
            isUploadTitleRegisterParsingEnabled: state => state.featureFlags?.uploadTitleRegisterParsing,
            isOrderSearchEnabled: state => state.featureFlags?.orderSearch,
            isUnregisteredLandIntersectionEnabled: state => state.featureFlags?.unregisteredLandIntersection,
            isNewOwnershipCardUIEnabled: state => state.featureFlags?.newOwnershipCardUi,
            isHighQExportEnabled: state => state.featureFlags?.unmergedTitleAnalysisExcelReport,
            isSketchesImportTitlesEnabled: state => state.featureFlags?.sketchesImportTitles,
            isScotlandLayerEnabled: state => state.featureFlags?.scottishSpike,
            isDuplicateMattersEnabled: state => state.featureFlags?.duplicateMatters,
            useIncreasedPointRenderingLimit: state => state.featureFlags?.increasedPointRenderingLimit,
            isTitlePanelV2Enabled: state => state.featureFlags?.titlePanelV2,
            isTitlePanelV2ToggleEnabled: state => state.featureFlags?.showTitlePanelToggle,
        }),

        isFeatureFlagsLoaded() {
            return Boolean(this.flags)
        },
    },
}
