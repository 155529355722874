import {
    onMounted,
} from "vue"

import { useToaster } from "./use-toaster"


const usePermissionsNotification = () => {
    const { warning } = useToaster()

    onMounted(async () => {
        window.addEventListener('permissions-changed',
            () => warning(
                'Your organisation admin has updated your account permissions. Please refresh the page to ensure you have the correct access to all features.',
                null,
                600_000, // 10 minutes in milliseconds
            ))
    })
}

export default usePermissionsNotification
