<template>
    <base-monitoring-preference-card :card-type="t('assetMonitoring.text.titles')"
                                     :card-type-description="t('assetMonitoring.text.titleAlerts')">
        <ow-textfield v-model="searchText"
                      :placeholder="t('assetMonitoring.filters.searchMatterTitles')"
                      clearable>
            <template #iconPrefix>
                <v-icon>$search</v-icon>
            </template>
        </ow-textfield>

        <p v-if="filteredTitles.length === 0"
           v-t="'label.noTitlesFound'"
           class="body-copy title-group-card__no-results" />

        <ul v-else
            class="title-group-card__options">
            <li v-for="title in filteredTitles"
                :data-test="`title-group-card-${ title.titleNumber }`">
                <ow-toggle-card v-model="title.isWhitelisted"
                                :title="title.titleNumber"
                                no-border
                                theme="preferences">
                    <ul>
                        <li v-if="title.label"
                            class="caption-regular"
                            data-test="title-group-card-label">
                            <span class="title-group-card__label">{{ t('label.label') }}:</span> {{ title.label }}
                        </li>
                        <li v-if="title.groupName"
                            class="caption-regular"
                            data-test="title-group-card-group-name">
                            <span v-if="title.groupName"
                                  class="title-group-card__label">{{ t('label.group') }}:</span> {{ title.groupName }}
                        </li>
                        <li v-if="title.address"
                            class="caption-regular"
                            data-test="title-group-card-address">
                            <span class="title-group-card__label">{{ t('label.address') }}:</span> {{ title.address }}
                        </li>
                    </ul>
                </ow-toggle-card>
            </li>
        </ul>
    </base-monitoring-preference-card>
</template>

<script lang="ts" setup>
    import { debounce } from 'lodash'
    import {
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { ITitleNotificationSettings } from '@/api/notifications.api'
    import BaseMonitoringPreferenceCard from '@/components/asset-monitoring/monitoring-preferences/base-monitoring-preference-card.vue'
    import { ITitleGroup } from '@/components/asset-monitoring/title-groups/title-group.model'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import OwToggleCard from '@/components/core/ow-toggle-card.vue'
    import {
        dynamicSortMultipleFields,
        isNullOrEmpty,
    } from '@/utils/array-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    // Translation setup
    const { t } = useI18n()

    // Props and Emits
    const props = defineProps<{
        titles: ITitleGroup[],
        settings: ITitleNotificationSettings,
    }>()

    const emit = defineEmits<{
        (event: 'update', value: ITitleNotificationSettings): void;
    }>()

    // Reactive state
    const searchText = ref<string>(null)
    const titleGroups = ref<ITitleGroup[]>(null)
    const filteredTitles = ref<ITitleGroup[]>(props.titles)
    const lastUpdates = ref<string>([])

    // Constants to improve readability
    const SORT_FIELDS = [ '-isWhitelisted', 'titleNumber' ]

    // Function to sort titles by default order
    function sortTitlesByDefaultOrder(titles: ITitleGroup[]): ITitleGroup[] {
        return titles.sort(dynamicSortMultipleFields(SORT_FIELDS))
    }

    // Debounced search logic
    const performSearch = debounce(() => {
        if (isNullOrWhitespace(searchText.value)) {
            filteredTitles.value = sortTitlesByDefaultOrder(titleGroups.value)
        } else {
            const filtered = titleGroups.value
                .filter((title: ITitleGroup) => title.searchString?.includes(searchText.value?.trim().toLowerCase()))
            filteredTitles.value = sortTitlesByDefaultOrder(filtered)
        }
    }, 500)

    // Function to emit updated settings
    const prepareTitleWhitelist = (): void => {
        const whitelistedTitles = titleGroups.value.filter(title => title.isWhitelisted).map(title => title.titleNumber)
        const newSettings = {
            ...props.settings,
            titleWhitelist: whitelistedTitles,
            useTitleWhitelist: !isNullOrEmpty(whitelistedTitles),
        }

        emit('update', newSettings)
    }

    // Watchers
    watch(searchText, () => {
        performSearch()
    })

    watch(filteredTitles, (newValue: ITitleGroup[]) => {
        sortTitlesByDefaultOrder(filteredTitles.value)

        // Only emit an update if the whitelisted titles has changed
        const compareNew = newValue.filter(title => title.isWhitelisted).map(title => title.titleNumber).join(',')
        if (compareNew !== lastUpdates.value) {
            // Keep track of last updates so don't emit if no changes detected
            lastUpdates.value = compareNew
            prepareTitleWhitelist()
        }
    }, { deep: true })

    watch(() => props.titles, (newValue: ITitleGroup[]) => {
        titleGroups.value = newValue.map((titleGroup: ITitleGroup) => ({
            ...titleGroup,
            isWhitelisted: props.settings.titleWhitelist?.includes(titleGroup.titleNumber),
        }))
        performSearch()
    }, { immediate: true, deep: true })
</script>

<style lang="scss" scoped>
    @import './title-group-card';
</style>
