<template>
    <title-panel-card :subtitle="content.main"
                      :title="$t('titlePanel.tabs.dayList')">
        <template #titleSuffix
                  class="d-flex flex-column">
            <div class="d-flex flex-row day-list__disclaimer">
                <div class="d-flex flex-row align-center justify-space-between w-100">
                    <div class="d-flex gc-1">
                        <p class="hide-in-percy day-list__accuracy-info-text">
                            {{ dateText }}
                        </p>
                        <ow-button data-track="TITLE-DETAILS-PANEL - Export daylist from summary panel"
                                   data-test="export-daylist-button"
                                   :disabled="refreshing || isNullOrEmpty(daylist?.data)"
                                   class="boundary__content--matter-button"
                                   is-flat
                                   small
                                   is-text
                                   @click="exportDayList()">
                            <template #iconPrefix>
                                <v-icon>$export</v-icon>
                            </template>
                            {{ $t('action.export') }}
                        </ow-button>

                        <ow-button data-track="TITLE-DETAILS-PANEL - Refresh daylist from summary panel"
                                   data-test="refresh-daylist-button"
                                   :disabled="refreshing"
                                   class="boundary__content--matter-button mr-1"
                                   is-flat
                                   is-text
                                   small
                                   @click="refreshDayList()">
                            <template #iconPrefix>
                                <v-icon>$refresh</v-icon>
                            </template>
                            {{ $t('action.refresh') }}
                        </ow-button>

                        <!-- View daylist button -->
                        <ow-button v-if="showViewButton"
                                   is-borderless
                                   is-primary
                                   is-secondary
                                   is-text
                                   data-track="TITLE-DETAILS-PANEL - View daylist from summary panel"
                                   @click="showDayList()">
                            <template #iconSuffix>
                                <v-icon>$chevron-right</v-icon>
                            </template>
                            {{ $t('action.view') }}
                        </ow-button>
                    </div>
                </div>
            </div>
        </template>
        <slot />
    </title-panel-card>
</template>

<script setup lang="ts">
    import { parse } from 'date-fns'
    import formatDistance from 'date-fns/formatDistance'
    import {
        computed,
        onMounted,
        ref,
        watch,
    } from "vue"
    import { useI18n } from "vue-i18n"
    import { useStore } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import TitlePanelCard from "@/components/title-panel/v2/cards/title-panel-card.vue"
    import { useSelectedTitle } from "@/composables/use-selected-title"
    import { ColorClass } from '@/enums/color-class.enum'
    import { TitlePanelTabName } from "@/enums/title-panel-tab-name"
    import { TITLE_LOOKUP_TITLE_DAYLIST } from '@/store/modules/titles/types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const store = useStore()
    const { t } = useI18n()
    const emit = defineEmits(['set-selected-tab', 'export-daylist'])

    interface IDayListText {
        main: string,
        flag?: {
            text: string,
            colorClass: ColorClass | null,
            icon: string,
        },
    }
    const props = defineProps({
        showViewLink: {
            type: Boolean,
            required: false,
            default: true,
        },
    })
    const dateText = ref('')
    const responseDate = ref<Date | null>(null)
    const currentTimeUpdateInterval = 60000
    const refreshing = ref(false)
    let timeUpdateInterval: ReturnType<typeof setInterval> | null = null
    const { daylist, daylistError } = useSelectedTitle()

    const showViewButton = computed(() => {
        return props.showViewLink &&
            daylist.value.data?.length &&
            !daylist.value.outsideOfLROperatingHours
    })
    const content = computed(() => {
        if (daylist.value.outsideOfLROperatingHours) {
            return {
                main: t('titlePanel.cards.daylist.noApplications'),
                flag: {
                    text: t('hmlr.outOfHours'),
                    colorClass: ColorClass.Danger,
                    icon: '$warning',
                },
            }
        }

        if (daylist.value.didHMLRTimeout) {
            return {
                main: t('titlePanel.cards.daylist.hmlrTimeout'),
                flag: {
                    text: null,
                    colorClass: null,
                    icon: null,
                },
            }
        }

        if (daylistError.value) {
            return {
                main: t('titlePanel.cards.daylist.noApplicationsError'),
                flag: {
                    text: null,
                    colorClass: null,
                    icon: null,
                },
            }
        }

        if (!daylist?.value?.data?.length) {
            return {
                main: t('titlePanel.cards.daylist.noApplications'),
                flag: {
                    text: null,
                    colorClass: null,
                    icon: null,
                },
            }
        }

        return daylist.value.data.length === 1
            ? {
                main: t('titlePanel.cards.daylist.singleApplication'),
                flag: {
                    text: t('titlePanel.cards.daylist.singleApplicationFlag'),
                    colorClass: ColorClass.Warning,
                    icon: '$flag',
                },
            }
            : {
                main: t('titlePanel.cards.daylist.multipleApplications', {
                    total: daylist.value.data.length,
                }),
                flag: {
                    text: t('titlePanel.cards.daylist.multipleApplicationsFlag', {
                        total: daylist.value.data.length,
                    }),
                    colorClass: ColorClass.Warning,
                    icon: '$flag',
                },
            }
    })

    const refreshDayList = async (): Promise<void> => {
        refreshing.value = true
        try {
            await store.dispatch(TITLE_LOOKUP_TITLE_DAYLIST)
        } finally {
            refreshing.value = false
        }
    }

    const exportDayList = (): void => {
        emit('export-daylist')
    }

    const showDayList = (): void => {
        emit('set-selected-tab', TitlePanelTabName.DayList)
    }


    const resetInterval = (): void => {
        if (timeUpdateInterval) {
            clearInterval(timeUpdateInterval)
        }

        timeUpdateInterval = setInterval(updateTimeString, currentTimeUpdateInterval)
    }

    const updateTimeString = (): void => {
        if (!responseDate.value || isNaN(responseDate.value.getTime())) {
            return
        }
        const time = formatDistance(responseDate.value, new Date())
        dateText.value = t('titlePanel.cards.daylist.accuracy', {
            time,
        })
    }

    const initialiseTimeString = (responseTime: string): void => {
        responseDate.value = parse(responseTime, 'dd-MM-yyyy HH:mm', new Date())
        updateTimeString()
        resetInterval()
    }

    watch(() => daylist.value.responseDate, (newVal: string) => {
        if (!isNullOrWhitespace(newVal)) {
            initialiseTimeString(newVal)
        }
    })

    onMounted(() => {
        if (!isNullOrWhitespace(daylist.value.responseDate)) {
            initialiseTimeString(daylist.value.responseDate)
        }
    })
</script>

<style lang="scss"
       scoped>
    @import './day-list-card';
</style>
