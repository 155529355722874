<template>
    <div class="uploaded-documents">
        <div class="uploaded-documents--label">
            <ow-colored-label class="accents-small"
                              is-beta-label
                              is-extra-small />
            <span v-t="'documents.upload.betaLabel'"
                  class="accents-italic" />
        </div>
        <uploaded-documents-summary :filtered-documents-count="0"
                                    :is-loading="isLoading"
                                    :documents="documents"
                                    :selected-documents-count="selectedDocumentIds?.length"
                                    @delete-documents="handleDeleteDocuments"
                                    @refresh-documents="$emit('refresh-documents')"
                                    @see-reporting-options-clicked="handleReportClicked" />

        <uploaded-documents-table v-model="selectedDocumentIds"
                                  :current-matter-id="currentMatterId"
                                  :documents="documents"
                                  :is-downloading="isDownloading"
                                  @download="$emit('download', $event)"
                                  @view="$emit('view', $event)"
                                  @title-number-selected="redirectToTitleNumber" />

        <title-panel-v2 v-if="selectedTitleNumber && isTitlePanelV2"
                        :is-pinned="isPinned"
                        :refresh-tam-on-register-order="true"
                        :selected-title-number="selectedTitleNumber"
                        :show-add-to-matter-action="false"
                        :show-title-visibility-action="false"
                        :matter-id="currentMatter.id"
                        class="document-library__title-details-panel-v2"
                        @clear-titles="clearSelectedTitle"
                        @title-number-selected="redirectToTitleNumber" />
        <title-details-panel v-if="selectedTitleNumber && !isTitlePanelV2"
                             :is-pinned="isPinned"
                             :selected-title-number="selectedTitleNumber"
                             :show-add-to-matter-action="false"
                             :show-title-visibility-action="false"
                             class="document-library__title-details-panel"
                             @clear-titles="clearSelectedTitle"
                             @title-number-selected="redirectToTitleNumber" />
    </div>
</template>

<script setup lang="ts">
    import { computed,
             onMounted,
             ref,
             watch } from 'vue'
    import { useRoute,
             useRouter } from 'vue-router'
    import { useStore } from 'vuex'

    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import UploadedDocumentsSummary from '@/components/documents/library/uploaded-documents/uploaded-documents-summary.vue'
    import UploadedDocumentsTable from '@/components/documents/library/uploaded-documents/uploaded-documents-table.vue'
    import TitleDetailsPanel from '@/components/title-panel/title-panel.vue'
    import TitlePanelV2 from "@/components/title-panel/v2/title-panel.vue"
    import { Route } from '@/enums/route.enum'
    import { uploadDocumentSuccessResponses } from '@/enums/uploaded-document-status.enum'
    import { checkFlag } from "@/feature-flags"
    import { IUploadedDocument } from '@/interfaces/uploaded-document.interface'
    import {
        MATTER_GET_REMOVE_DOCUMENTS_PROMPT_STATE,
        MATTER_HAS_TITLE_NUMBER,
        MATTER_MUTATE_REMOVE_DOCUMENTS_PROMPT,
    } from '@/store/modules/matter/types'
    import { TITLE_CLEAR,
             TITLE_LOOKUP_TITLE } from '@/store/modules/titles/types'

    const EVENTS = {
        download: 'download',
        view: 'view',
        refresh: 'refresh-documents',
        selectDocuments: 'select-documents',
    }

    const props = defineProps<{
        currentMatterId: number
        documents: Array<IUploadedDocument>
        isLoading: boolean
        isDownloading: boolean
    }>()

    const emit = defineEmits<{
        (e: 'download', document: IUploadedDocument): void
        (e: 'view', document: IUploadedDocument): void
        (e: 'refresh-documents'): void
        (e: 'select-documents', documents: Array<IUploadedDocument>): void
    }>()

    const selectedDocumentIds = ref<Array<number>>([])
    const selectedTitleNumber = ref<string | null>(null)
    const tabHash = ref('#uploaded')

    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const currentMatter = computed(() => store.state.matter?.currentMatter)
    const matterHasTitle = computed(() => store.getters[MATTER_HAS_TITLE_NUMBER])

    const routeName = computed(() => route?.name)
    const isPinned = computed(() => matterHasTitle.value(selectedTitleNumber.value))
    const isTitlePanelV2 = computed(() => checkFlag('title-panel-v-2', false))

    const selectedReportTitleRegisters = computed(() => selectedDocuments.value.filter(document => document.type === 'TitleRegister' &&
        document.titleNumber != null &&
        uploadDocumentSuccessResponses.includes(document.status)))

    const selectedReportTitles = computed(() => {
        const reportTitles = new Set(selectedReportTitleRegisters.value.map(document => document.titleNumber))
        return Array.from(reportTitles)
    })

    const selectedDocuments = computed<IUploadedDocument[]>(() => {
        return props.documents.filter(document => selectedDocumentIds.value.includes(document.id))
    })

    watch(routeName, (newRouteName, oldRouteName) => {
        if (newRouteName !== oldRouteName || selectedTitleNumber.value !== route.params?.titleNumber) {
            const onThisTab = tabHash.value == route.hash
            if (!onThisTab) {
                selectedTitleNumber.value = null
                return
            }
            switch (newRouteName) {
                case Route.DocumentsLibraryTitleDetails:
                    showTitleDetailsPanel(route?.params?.titleNumber)
                    break
                case Route.DocumentsLibrary:
                    clearSelectedTitle()
                    break
            }
        }
    })

    onMounted(() => {
        showTitleDetailsPanel(route?.params?.titleNumber)
    })

    watch(() => selectedDocumentIds.value, (newVal) => {
        setSelectedDocuments(selectedDocuments.value)
    })

    const setSelectedDocuments = (val: IUploadedDocument[]) => {
        emit('select-documents', val)
    }

    const clearSelectedTitle = () => {
        store.dispatch(TITLE_CLEAR)
        selectedTitleNumber.value = null
        router.push({
            name: Route.DocumentsLibrary,
            params: {
                matterId: props.currentMatterId,
            },
            hash: tabHash.value,
        })
    }

    const redirectToTitleNumber = (titleNumber: string) => {
        if (titleNumber !== selectedTitleNumber.value) {
            router.push({
                name: Route.DocumentsLibraryTitleDetails,
                params: {
                    id: props.currentMatterId,
                    titleNumber,
                },
                hash: tabHash.value,
            })
        }
    }

    const showTitleDetailsPanel = (titleNumber: string) => {
        if (!titleNumber) {
            return
        }

        if (titleNumber !== selectedTitleNumber.value) {
            store.dispatch(TITLE_LOOKUP_TITLE, titleNumber)
        }

        selectedTitleNumber.value = titleNumber
    }

    const handleReportClicked = async () => {
        await router.push({
            name: Route.MatterReports,
            params: {
                matterId: props.currentMatterId,
            },
            query: {
                selectedTitleNumbers: selectedReportTitles.value,
                origin: 'DOCS',
            },
        })
    }

    const removeDocumentsFromMatterDialog = computed({
        get: () => store.getters[MATTER_GET_REMOVE_DOCUMENTS_PROMPT_STATE],
        set: (val) => store.commit(MATTER_MUTATE_REMOVE_DOCUMENTS_PROMPT, val),
    })

    const showRemoveDocumentConfirmation = () => {
        removeDocumentsFromMatterDialog.value = {
            show: true,
            matterId: props.currentMatterId,
            documentsToRemove: selectedDocuments.value,
        }
    }

    const handleDeleteDocuments = async () => {
        if (selectedDocuments.value.length === 0) {
            return
        }

        showRemoveDocumentConfirmation()
    }
</script>

<style lang="scss"
       scoped>
    @import './uploaded-documents';
</style>
