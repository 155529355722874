<template>
    <div :key="`row-item-${item.notificationId}-${isShowingDetailsPanel ? 'expanded' : 'collapsed'}-${isNotificationRead ? 'read' : 'unread'}`"
         class="base-row-item-expand-collapse"
         :class="{
             '--unread': !isNotificationRead
         }"
         :data-test="`${item.notificationType.toString().toLowerCase()}-toggle`"
         data-track="Asset Monitoring: expand/collapse"
         @click.stop="$emit('expand-collapse')">
        <ow-icon :icon="expandCollapseIcon" />
    </div>
</template>

<script setup lang="ts">

    import { computed } from 'vue'

    import { IAssetMonitoringNotification } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import OwIcon from '@/components/core/ow-icon.vue'

    const props = defineProps<{
        isShowingDetailsPanel: boolean,
        isNotificationRead?: boolean,
        item: IAssetMonitoringNotification,
    }>()

    defineEmits<{
        (e: 'expand-collapse'): void,
    }>()

    const expandCollapseIcon = computed(() => props.isShowingDetailsPanel ? 'ow-icon-card-collapse' : 'ow-icon-card-expand')

</script>

<style lang="scss"
       scoped>
@import './base-row-item-expand-collapse';
</style>