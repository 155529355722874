<template>
    <section class="base-sub-type-item">
        <div class="expansion-panel__content">
            <slot />
        </div>
    </section>
</template>

<script lang="ts" setup generic="Datapoint">
    import {
        onMounted,
    } from 'vue'

    import { IAssetMonitoringNotificationDetail } from "@/components/asset-monitoring/asset-monitoring-notification.interface"
    import { Difference } from "@/components/asset-monitoring/difference-generic"

    const props = defineProps<{
        notification?: IAssetMonitoringNotificationDetail
    }>()
    const diff = defineModel<Difference<Datapoint>>('diff')

    onMounted(() => {
        if (props.notification) {
            diff.value = JSON.parse(props.notification?.difference)
        }
    })
</script>

<style lang="scss" scoped>
    @import "base-sub-type-item";
</style>
